<template>
  <OModal
    :active="show"
    ref="modal"
    :can-cancel="canCancel"
    :props="{
      variant,
    }"
    :id="id"
    :close-icon="null"
    v-bind="{ ...$attrs }"
    @close="onClose"
  >
    <span v-if="canCancel" class="sr-only">{{ $t('clickEscapeToClose') }}</span>
    <div class="flex flex-col min-h-full">
      <div
        v-if="$slots.header || $slots['header-right']"
        class="px-4 md:px-6"
        :class="{
          'sticky top-0 py-3 md:py-0 md:h-14 bg-white shadow-sm border-b lg:px-8 z-20':
            variant !== 'dialog',
          'py-4 md:py-6': variant === 'dialog'
        }"
      >
        <div :class="headerClass" class="flex flex-wrap items-center h-full justify-between ">
          <div class="flex items-center flex-1">
            <div
              v-if="leftCloseButton"
              class="inline-flex items-center mr-1 md:-ml-2 lg:-ml-3 pr-2"
            >
              <button
                class="focus:outline-none rounded-full w-8 h-8 inline-flex
                justify-center items-center hover:bg-gray-200 transition duration-150
                text-gray-800 focus:ring-primary-2"
                @click="close"
              >
                <CIcon name="x" size="20" />
                <span class="sr-only">{{ $t('close') }}</span>
              </button>
              <div class="border-r w-px h-6 ml-1" />
            </div>
            <CIcon
              v-if="headerIcon"
              :name="headerIcon"
              size="32"
              class="mr-2"
              :class="{
                'text-gray-500': submitVariant !== 'danger',
                'text-red-600': submitVariant === 'danger',
              }"
            />
            <CTypo tstyle="title3">
              <slot name="header" />
            </CTypo>
          </div>
          <div
            v-if="$slots['header-center']"
            class="order-3 w-full md:w-auto md:flex md:flex-shrink md:order-2
            items-center mt-2 md:mt-0"
          >
            <slot name="header-center" />
          </div>
          <div
            v-if="$slots['header-right'] || $slots['header-center'] || closeButton"
            class="flex items-center justify-end order-2 md:order-3"
            :class="{
              'md:flex-1': $slots['header-center'],
              'flex-shrink': !$slots['header-center'],
            }"
          >
            <slot name="header-right" />
            <div v-if="closeButton" class="inline-flex ml-1 md:-mr-2 lg:-mr-3">
              <button
                class="focus:outline-none rounded-full w-8 h-8 inline-flex
                justify-center items-center hover:bg-gray-200 transition duration-150
                text-gray-800 focus:ring-primary-2"
                @click="close"
              >
                <CIcon name="x" size="20" />
                <span class="sr-only">{{ $t('close') }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="$slots.default"
        class="px-4 md:px-6"
        :class="[{
          'py-4 md:py-6 lg:py-8 lg:px-8 flex-grow': variant !== 'dialog',
          'py-0 pb-3 max-h-300px': variant === 'dialog',
        }, contentClass]"
      >
        <slot />
      </div>
      <div
        v-else-if="$scopedSlots.content"
        class="flex-1 flex-grow"
        :class="contentClass"
        ref="content"
      >
        <CResizeObserver only-inline @resize-inline="getContentHeight" />
        <slot name="content" v-bind="{ height: fullContentHeight }" />
      </div>
      <div
        v-if="$slots.footer || $slots['footer-right'] || !customFooter"
        class="flex items-center px-4 md:px-6 py-3"
        :class="[footerClass, {
          'sticky bottom-0 bg-white border-t lg:px-8 z-20': variant !== 'dialog',
          'py-4 md:py-6': variant === 'dialog',
          'justify-between': customFooter,
          'justify-start md:justify-end': !customFooter,
          'pb-safe-area-large': variant !== 'dialog' && variant !== 'default',
        }]"
      >
        <div v-if="customFooter">
          <slot name="footer" />
        </div>
        <div v-if="customFooter">
          <slot name="footer-right" />
        </div>
        <Buttons
          v-if="!customFooter || submitForm"
          :class="{
            'ml-2': customFooter,
          }"
          :variant="variant"
          :no-cancel="noCancel"
          :submit-form="submitForm"
          :submit-loading="submitLoading"
          :submit-variant="submitVariant"
          :submit-disabled="submitDisabled"
          :submit-countdown="submitCountdown"
          v-bind="{ ...buttonProps }"
          @cancel="onCancel"
          @submit="$emit('submit')"
        >
          <template v-if="$slots.submit" v-slot:submit>
            <slot name="submit" />
          </template>
          <template v-if="$slots.cancel" v-slot:cancel>
            <slot name="cancel" />
          </template>
        </Buttons>
      </div>
    </div>
  </OModal>
</template>

<script>
import { MODAL_VARIANTS } from '../../constants/sharedComponents';
import objectid from '../../utils/objectid';
import ModalService from './ModalService';
import Buttons from './Buttons/index.vue';

export default {
  components: {
    Buttons,
  },

  inheritAttrs: false,

  model: {
    prop: 'show',
    event: 'toggle',
  },

  props: {
    id: {
      type: String,
      default: () => objectid('modal'),
    },
    show: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'default',
      validator: (value) => MODAL_VARIANTS.includes(value),
    },
    noCancel: {
      type: Boolean,
      default: false,
    },
    customFooter: {
      type: Boolean,
      default: false,
    },
    buttonProps: {
      type: Object,
      default: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
    },
    submitForm: {
      type: String,
      default: null,
    },
    submitLoading: {
      type: Boolean,
      default: false,
    },
    submitDisabled: {
      type: Boolean,
      default: false,
    },
    submitVariant: {
      type: String,
      default: 'primary',
    },
    submitCountdown: {
      type: Number,
      default: null,
    },
    closeButton: {
      type: Boolean,
      default: false,
    },
    leftCloseButton: {
      type: Boolean,
      default: false,
    },
    headerIcon: {
      type: String,
      default: null,
    },
    noBackdropClose: {
      type: Boolean,
      default: false,
    },
    contentClass: {
      type: String,
      default: null,
    },
    headerClass: {
      type: [String, Array, Object],
      default: '',
    },
    footerClass: {
      type: [String, Array, Object],
      default: '',
    },
  },

  data: () => ({
    fullContentHeight: 0,
  }),

  computed: {
    element() {
      return this.$refs.modal;
    },
    canCancel() {
      if (!this.noCancel && !this.noBackdropClose) {
        return ['x', 'outside', 'button'];
      }
      return false;
    },
  },

  watch: {
    show: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          ModalService.onModalOpen(this.id);
          this.handleEsc();
          this.getContentHeight();
        } else {
          ModalService.onModalClose(this.id);
        }
      },
    },
  },

  methods: {
    open() {
      this.$emit('toggle', true);
      this.$emit('open');
      ModalService.onModalOpen(this.id);
      this.handleEsc();
    },
    handleEsc() {
      if (!this.handlingEsc) {
        this.handlingEsc = true;
        ModalService.$on('esc', (id) => {
          if (id === this.id && !this.noCancel && !this.noBackdropClose) {
            this.close();
          }
        });
      }
    },
    close() {
      this.onClose();
    },
    onClose() {
      if (!this.submitLoading) {
        this.$emit('toggle', false);
        this.$emit('close');
        ModalService.onModalClose(this.id);
      }
    },
    onCancel() {
      this.$emit('cancel');
      this.close();
    },
    getContentHeight() {
      this.$nextTick(() => {
        this.fullContentHeight = this.$refs.content?.offsetHeight || 0;
      });
    },
    scrollTo(el) {
      const contentScroller = this.$el.querySelector(`#${this.id} .modal-child`);
      const { offsetTop } = el;
      contentScroller.scrollTo({
        top: offsetTop,
        left: 0,
        behavior: 'smooth',
      });
    },
  },
};
</script>
