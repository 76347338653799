<template>
  <div class="relative" v-click-outside="close">
    <CTextField
      ref="textfield"
      v-model="valueModel"
      v-bind="{ ...$attrs }"
      readonly
      @focus="onFieldFocus"
      @blur="onFieldBlur"
      @keydown.esc.native="close"
    >
      <template v-slot:leading-addon>
        <div
          v-if="valueModel"
          class="w-4 h-4 shadow-sm rounded-full"
          :style="`background: ${valueModel}`"
        />
      </template>
      <template v-slot:custom>
        <div
          class="absolute top-full left-0 z-1 p-10 -m-10"
          role="dialog"
          :aria-hidden="!dialogOpen"
          tabindex="-1"
          @mouseenter="activeInDialog = true"
          @mouseleave="activeInDialog = false"
        >
          <ColorPicker
            v-show="dialogOpen"
            v-model="valueModelObj"
            class="!shadow-lg border"
            @input="onColorInput"
          />
        </div>
      </template>
    </CTextField>
  </div>
</template>

<script>
import { Sketch as ColorPicker } from 'vue-color';
import vClickOutside from 'v-click-outside';

export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },

  components: {
    ColorPicker,
  },

  inheritAttrs: false,

  props: {
    value: {
      type: String,
      default: null,
    },
    inputValueType: {
      type: String,
      default: 'hex',
    },
    emitValueType: {
      type: String,
      default: 'hex',
    },
  },

  data() {
    return {
      valueModel: null,
      valueModelObj: null,
      dialogOpen: false,
      activeInDialog: false,
    };
  },

  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        this.valueModel = newVal;
        this.valueModelObj = { [this.inputValueType]: newVal };
      },
    },
  },

  methods: {
    onColorInput(val) {
      this.$emit('input', val[this.emitValueType]);
      this.$nextTick(() => {
        if (!this.activeInDialog) {
          this.dialogOpen = false;
        }
      });
    },
    onFieldFocus() {
      this.dialogOpen = true;
    },
    onFieldBlur() {
      if (!this.activeInDialog) {
        this.dialogOpen = false;
      }
    },
    close() {
      this.$refs.textfield.blur();
      this.activeInDialog = false;
      this.dialogOpen = false;
    },
  },
};
</script>
