<template>
  <CSvgImage
    :src="url"
    svg-class="w-20 h-20"
  />
</template>

<script>
import CSvgImage from '../SvgImage/index.vue';
import { getPlaceholderAssetUrl } from '../../utils/assets';

export default {
  components: {
    CSvgImage,
  },
  props: {
    type: {
      type: String,
    },
    flap: {
      type: Boolean,
    },
    cover: {
      type: Boolean,
    },
    doors: {
      type: Boolean,
    },
    size: {
      type: Number,
    },
    showDefault: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    url() {
      const url = getPlaceholderAssetUrl('/placeholder/');

      if (this.type === 'dropoff') {
        if (this.showDefault) {
          return `${url}absetzcontainer.svg`;
        }

        if (this.flap && this.cover && !this.doors) {
          return `${url}absetzcontainer-deckel-klappe.svg`;
        }
        if (!this.flap && this.cover && this.doors) {
          return `${url}absetzcontainer-tueren-deckel.svg`;
        }
        if (this.flap && !this.cover && !this.doors) {
          return `${url}absetzcontainer-klappe.svg`;
        }
        if (!this.flap && this.cover && !this.doors) {
          return `${url}absetzcontainer-deckel.svg`;
        }
        if (!this.flap && !this.cover && this.doors) {
          return `${url}absetzcontainer-tueren.svg`;
        }

        return `${url}absetzcontainer.svg`;
      }

      if (this.type === 'rolloff') {
        if (this.showDefault) {
          return `${url}abrollcontainer.svg`;
        }
        if (this.size > 22) {
          return `${url}abrollcontainer-large.svg`;
        }
        if (this.cover) {
          return `${url}abrollcontainer-deckel.svg`;
        }
        return `${url}abrollcontainer.svg`;
      }

      if (this.type === 'bigbag') {
        return `${url}bigbag.svg`;
      }

      return url;
    },
  },
};
</script>
