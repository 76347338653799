<template>
  <div
    v-if="!loading && !resultIsImage"
    v-html="result"
  />
  <img
    v-else-if="!loading"
    :src="src"
    :class="svgClass"
  >
  <CLoadingDots v-else class="text-gray-500 w-4" />
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      required: true,
    },
    imgClass: {
      type: String,
      default: null,
    },
    svgClass: {
      type: String,
      default: null,
    },
  },

  data: () => ({
    result: null,
    resultIsImage: false,
    loading: true,
  }),

  watch: {
    src: {
      immediate: true,
      handler(src) {
        this.getSourceCode(src);
      },
    },
  },

  methods: {
    async getSourceCode(src) {
      if (/.svg/.test(src)) {
        this.loading = true;
        fetch(src)
          .then((resp) => {
            if (resp.status === 200) {
              return resp.text();
            }
            return '';
          })
          .then((text) => {
            this.result = text;
            this.loading = false;
            this.$nextTick(() => {
              if (this.svgClass) {
                this.$el.querySelector('svg').classList.add(...this.svgClass.split(' '));
              }
            });
          });
      } else {
        this.loading = false;
        this.resultIsImage = true;
      }
    },
  },
};
</script>
