import { AxiosPromise } from 'axios';
import buildPaginationQuery from '../utils/buildPaginationQuery';
import { TApiPaginationQuery, TApiPaginationResult } from '@contimo/types/src/Api';
import type { TPostalCode } from './postalCodes';
import client from './client';

export interface IDeliveryArea {
  id: number;
  postalCodeId: string;
  additionalPrice: number;
  ignoreDeliveryDistancePrice: boolean;
  location?: TPostalCode;
}

export type TDeliveryAreaPatchBody = {
  postalCodeId?: string;
  ignoreDeliveryDistancePrice?: boolean;
  additionalPrice?: number;
};

export type TDeliveryAreaCreateBody = {
  postalCodeId: string;
  ignoreDeliveryDistancePrice: boolean;
  additionalPrice: number;
};

const buildUrl = (path?: string | number) => (path ? `/delivery-areas/${path}` : '/delivery-areas');

export default {
  index: (
    pagination: TApiPaginationQuery = {},
  ): AxiosPromise<TApiPaginationResult<IDeliveryArea[]>> => {
    return client.get(`${buildUrl()}${buildPaginationQuery(pagination)}`);
  },
  show: (id: number): AxiosPromise<IDeliveryArea> => client.get(buildUrl(id)),
  update: (id: number, updates: TDeliveryAreaPatchBody): AxiosPromise<IDeliveryArea> => {
    return client.patch(buildUrl(id), updates);
  },
  store: (body: TDeliveryAreaCreateBody): AxiosPromise<IDeliveryArea> => {
    return client.post(buildUrl(), body);
  },
  destroy: (id: number): AxiosPromise => client.delete(buildUrl(id)),
};
