<template>
  <OField
    :variant="currentVariant"
    :label-for="id"
    :label-size="size"
    :label="label ? `${label}${required ? '*' : ''}` : null"
    :class="{
      'has-rounded-field': rounded,
    }"
  >
    <OSelect
      :id="id"
      v-bind="{ ...$attrs }"
      ref="input"
      :value="value"
      :use-html5-validation="false"
      :required="required"
      :placeholder="placeholder ? `${placeholder}${required && !label ? '*' : ''}` : null"
      :size="size"
      :rounded="rounded"
      :disabled="disabled"
      novalidate
      @input="onInput"
      @blur="onBlur"
      @focus="onFocus"
    >
      <slot />
    </OSelect>
    <template v-slot:message>
      <template v-if="currentMessage">
        {{ currentMessage }}
      </template>
      <slot v-else name="message" />
    </template>
  </OField>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import { FIELD_VARIANTS, FIELD_SIZES } from '../../../constants/sharedComponents';
import objectid from '../../../utils/objectid';

export default defineComponent({
  name: 'CSelect',
  inheritAttrs: false,

  props: {
    variant: {
      type: String,
      default: 'default',
      validator: (value) => FIELD_VARIANTS.includes(value),
    },
    id: {
      type: String,
      default: () => objectid('field-'),
    },
    label: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    message: {
      type: String,
      default: null,
    },
    value: {
      type: [String, Number],
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dontValidate: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'medium',
      validator: (value) => FIELD_SIZES.includes(value),
    },
  },

  data: () => ({
    validationError: false,
    newValidity: null,
    oldValidity: null,
  }),

  computed: {
    currentVariant() {
      if (this.validationError) return 'danger';
      return this.variant;
    },
    currentMessage() {
      if (this.validationError) return this.validationError;
      return this.message;
    },
    inputEl() {
      return this.$el.querySelector(`#${this.id}`);
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.inputEl.addEventListener('invalid', (e) => {
        e.preventDefault();
        this.setValidationMessage();
      });
    });
  },

  beforeDestroy() {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    this.inputEl.removeEventListener('invalid', () => {});
  },

  methods: {
    onInput(value) {
      if (this.validationError) {
        this.checkValidity();
      }
      this.$emit('input', value);
    },
    onBlur(e) {
      this.checkValidity();
      this.$emit('blur', e);
    },
    onFocus(e) {
      this.$emit('focus', e);
    },
    checkValidity() {
      if (!this.dontValidate) {
        const fieldValidity = this.inputEl.checkValidity();
        if (!fieldValidity) {
          this.setValidationMessage();
        } else {
          this.validationError = false;
        }
      }
    },
    setValidationMessage() {
      const { validationMessage } = this.inputEl;
      this.validationError = validationMessage;
    },
  },
});
</script>
