import { AxiosPromise } from 'axios';
import client from './client';

export type TPage = {
  id: number;
  shopId: number;
  title: string;
  slug: string;
  content: Record<string, any>;
  htmlContent: string;
  published: boolean;
};

export type TPageCreateBody = {
  slug: string | null;
  title: string;
  content: string | null;
  published: boolean;
};

export type TPageUpdateBody = {
  slug?: string | null;
  title?: string;
  content?: string | null;
  published?: boolean;
};

const buildUrl = (path?: string) => (path ? `/pages/${path}` : '/pages');

export default {
  index: (): AxiosPromise<TPage[]> => {
    return client.get(buildUrl());
  },
  get: (id: number): AxiosPromise<TPage> => {
    return client.get(buildUrl(id.toString()));
  },
  store: (payload: TPageCreateBody): AxiosPromise<TPage> => {
    return client.post(buildUrl(), payload);
  },
  update: (id: number, payload: TPageUpdateBody): AxiosPromise<TPage> => {
    return client.patch(buildUrl(id.toString()), payload);
  },
  delete: (id: number): AxiosPromise => {
    return client.delete(buildUrl(id.toString()));
  },
};
