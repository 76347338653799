<template>
  <div>
    <CTextField
      v-model="password"
      :label="$t('password')"
      :minlength="minLength"
      type="password"
      required
      :custom-error="strengthError"
      @input="checkPasswordStrength"
      @blur="showStrengthError"
    />
    <CTextField
      v-model="passwordRepeat"
      :label="$t('repeatPassword')"
      :minlength="minLength"
      type="password"
      required
      :custom-error="passwordRepeatError"
      @change="checkPasswordRepeat"
      @blur="checkPasswordRepeat"
    />
  </div>
</template>

<script>
import { MIN_PASSWORD_STRENGTH, MIN_PASSWORD_LENGTH } from '../../constants/ui';

export default {
  props: {
    value: {
      type: Object,
    },
  },

  data() {
    return {
      passwordRepeatError: null,
      strengthError: null,
      showStrength: false,
    };
  },

  computed: {
    minLength() {
      return MIN_PASSWORD_LENGTH;
    },
    password: {
      get() {
        return this.value.password;
      },
      set(value) {
        this.$emit('input', {
          ...this.value,
          password: value,
        });
      },
    },
    passwordRepeat: {
      get() {
        return this.value.passwordRepeat;
      },
      set(value) {
        this.$emit('input', {
          ...this.value,
          passwordRepeat: value,
        });
      },
    },
  },

  created() {
    import(/* webpackChunkName: "zxcvbn" */ 'zxcvbn').then((zxcvbn) => {
      this.zxcvbn = zxcvbn.default;
      this.showStrength = true;
    });
  },

  methods: {
    checkPasswordStrength(value) {
      if (value && value.length >= 8) {
        this.strength = this.zxcvbn(value).score || 0;
      }
      if (this.strengthError) {
        this.showStrengthError();
      }
    },
    showStrengthError() {
      if (this.strength > -1 && this.strength <= MIN_PASSWORD_STRENGTH) {
        this.strengthError = this.$t('fieldErrors.passwordWeak');
      } else {
        this.strengthError = false;
      }
    },
    checkPasswordRepeat() {
      if (this.passwordRepeat !== this.password) {
        this.passwordRepeatError = this.$t('fieldErrors.passwordsAreNotTheSame');
      } else {
        this.passwordRepeatError = null;
      }
    },
    validate() {
      this.checkPasswordStrength();
      this.checkPasswordRepeat();

      return (
        !this.passwordRepeatError &&
        (process.env.NODE_ENV === 'development' || this.strength >= MIN_PASSWORD_STRENGTH)
      );
    },
  },
};
</script>

<style></style>