import type { DirectiveFunction } from 'vue';

const autofocus: DirectiveFunction = (el, binding) => {
  if (binding.value !== undefined && !binding.value) {
    return;
  }

  const input = el.querySelector('input');
  const select = el.querySelector('select');
  const button = el.querySelector('button');
  setTimeout(() => {
    if (input) {
      input.focus();
    } else if (select) {
      select.focus();
    } else if (button) {
      button.focus();
    } else if (el.focus) {
      el.focus();
    }
  }, binding.value || 50);
};

export default {
  bind: autofocus,
};
