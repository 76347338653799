<template>
  <OSwitch
    :value="value"
    role="switch"
    v-bind="{ ...$attrs }"
    @input="onInput"
  >
    <slot />
  </OSwitch>
</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    onInput(val) {
      this.$emit('input', val);
    },
  },
};
</script>
