import { AxiosPromise } from 'axios';
import client from './client';
import buildPaginationQuery from '../utils/buildPaginationQuery';
import type { TApiPaginationQuery, TApiPaginationResult } from '@contimo/types/src/Api';

export interface IAddress {
  id: number;
  customerId: number | null;
  companyName: string | null;
  street: string;
  number: string;
  firstName: string;
  lastName: string;
  postalCodeId: string;
  city: string | null;
  state: string | null;
  vat: string | null;
  type: 'delivery' | 'billing' | null;
  lng: number;
  lat: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface ICreateAddressBody {
  companyName?: string | null;
  street: string;
  number: string;
  firstName?: string | null;
  lastName?: string | null;
  postalCodeId: string;
  city?: string | null;
  state?: string | null;
  vat?: string | null;
  type: 'delivery' | 'billing' | null;
}

export interface IUpdateAddressBody {
  companyName?: string | null;
  street?: string;
  number?: string;
  firstName?: string | null;
  lastName?: string | null;
  postalCodeId?: string;
  city?: string | null;
  state?: string | null;
  vat: string | null;
  type: 'delivery' | 'billing' | null;
}

const buildUrl = (path?: string | number) => (path ? `/addresses/${path}` : '/addresses');

export default {
  index: (pagination: TApiPaginationQuery = {}): AxiosPromise<TApiPaginationResult<IAddress[]>> => {
    return client.get(`${buildUrl()}${buildPaginationQuery(pagination)}`);
  },
  get: (id: number): AxiosPromise<TApiPaginationResult<IAddress>> => {
    return client.get(buildUrl(id));
  },
  store: (body: ICreateAddressBody): AxiosPromise<IAddress> => {
    return client.post(buildUrl(), body);
  },
  show: (id: number): AxiosPromise<IAddress> => client.get(buildUrl(id)),
  update: (id: number, body: IUpdateAddressBody) => client.patch(buildUrl(id), body),
  remove: (id: number): AxiosPromise => {
    return client.delete(buildUrl(id.toString()));
  },
};
