import type { Module } from 'vuex';
import { TPaginationMeta } from '@contimo/types/src/Api';
import users, { TUser } from '@contimo/api/src/api/users';
import type { TRootStore } from '@/store';
import { updateOrPushInArray } from '@/utils/store';
import { ALL_USERS } from '../gettersTypes';
import {
  SET_SINGLE_USER,
  SET_USERS,
  SET_USER_LOADING,
  SET_USERS_LOADING,
  SET_USERS_PAGINATION,
} from '../mutationsTypes';
import { GET_SINGLE_USER, GET_USERS } from '../actionTypes';

export interface IUsersStore {
  loading: boolean;
  singleLoading: boolean;
  users: TUser[];
  pagination: TPaginationMeta | null;
}

type TUsersStore = Module<IUsersStore, TRootStore>;

const usersStore: TUsersStore = {
  state: () => ({
    loading: false,
    singleLoading: false,
    createLoading: false,
    submitLoading: false,
    users: [],
    pagination: null,
  }),

  getters: {
    [ALL_USERS]: (state) => state.users,
  },

  mutations: {
    [SET_SINGLE_USER](state, user: TUser) {
      updateOrPushInArray(state.users, user);
    },
    [SET_USERS](state, users: TUser[]) {
      users.forEach((user) => {
        updateOrPushInArray(state.users, user);
      });
    },
    [SET_USER_LOADING](state, loading: boolean) {
      state.singleLoading = loading;
    },
    [SET_USERS_LOADING](state, loading: boolean) {
      state.loading = loading;
    },
    [SET_USERS_PAGINATION](state, meta: TPaginationMeta | null) {
      state.pagination = meta;
    },
  },

  actions: {
    async [GET_SINGLE_USER]({ commit }, id: number) {
      commit(SET_USER_LOADING, true);
      try {
        const { data } = await users.show(id);
        commit(SET_SINGLE_USER, data);

        return data;
      } finally {
        commit(SET_USER_LOADING, false);
      }
    },
    async [GET_USERS]({ commit, state }, page = 1) {
      commit(SET_USERS_LOADING, true);
      try {
        const { data } = await users.index({ page, limit: state.pagination?.perPage || 50 });
        commit(SET_USERS, data.data);
        commit(SET_USERS_PAGINATION, data.meta, {
          root: false,
        });

        return data;
      } finally {
        commit(SET_USERS_LOADING, false);
      }
    },
  },
};

export default usersStore;
