/* eslint-disable max-len */
import type { VueConstructor } from 'vue';
import {
  Config,
  Field,
  Input,
  Button,
  Select,
  Modal,
  Autocomplete,
  Tabs,
  Slider,
  Tooltip,
  Upload,
  Dropdown,
  Table,
  Pagination,
  Switch,
  Checkbox,
  Datepicker,
  Collapse,
} from '@oruga-ui/oruga';

import CTextField from './form/TextField/index.vue';
import CSelect from './form/Select/index.vue';
import CColorPicker from './form/ColorPicker/index.vue';
import CAddressField from './form/AddressField/index.vue';
import CSelectBox from './form/SelectBox/index.vue';
import CSlider from './form/Slider/index.vue';
import CUpload from './form/Upload/index.vue';
import CImageUpload from './form/ImageUpload/index.vue';
import CSwitch from './form/Switch/index.vue';
import CButton from './Button/index.vue';
import CTypo from './Typo/index.vue';
import CMessage from './Message/index.vue';
import CLink from './Link/index.vue';
import CDivider from './Divider/index.vue';
import CLoading from './Loading/index.vue';
import CLoadingSpinner from './Loading/LoadingSpinner/index.vue';
import CLoadingDots from './Loading/LoadingDots/index.vue';
import CInfoCard from './InfoCard/index.vue';
import CInfoCardGroup from './InfoCardGroup/index.vue';
import CIcon from './Icon/index.vue';
import CNavSidebar from './Nav/Sidebar/index.vue';
import CNavSidebarItem from './Nav/SidebarItem/index.vue';
import CStackRouter from './StackRouter/index.vue';
import CStackRouterChild from './StackRouterChild/index.vue';
import CSlideOver from './SlideOver/index.vue';
import CModal from './Modal/index.vue';
import CSegmentControl from './SegmentControl/index.vue';
import CSegmentControlItem from './SegmentControl/Item/index.vue';
import CMap from './Map/index.vue';
import CMapMarker from './Map/MapMarker/index.vue';
import CMapCircle from './Map/MapCircle/index.vue';
import CContainer from './Container/index.vue';
import CNavBar from './Nav/Bar/index.vue';
import CNavBarItem from './Nav/BarItem/index.vue';
import CNavSub from './Nav/Sub/index.vue';
import CNavSubItem from './Nav/SubItem/index.vue';
import CDropdownMenu from './DropdownMenu/index.vue';
import CDropdownMenuItem from './DropdownMenuItem/index.vue';
import CTable from './Table/index.vue';
import CBadge from './Badge/index.vue';
import CResizeObserver from './ResizeObserver/index.vue';
import CAlert from './Alert/index.vue';
import CCard from './Card/index.vue';
import CEditor from './form/Editor/index.vue';
import CValidationFailure from './ValidationFailure/index.vue';
import CCheckbox from './form/Checkbox/index.vue';
import CCheckboxList from './form/CheckboxList/index.vue';
import CDatepicker from './form/Datepicker/index.vue';
import CPasswordInputs from './PasswordInputs/index.vue';
import CDescriptionList from './DescriptionList/index.vue';
import CDescriptionListItem from './DescriptionListItem/index.vue';
import CSvgImage from './SvgImage/index.vue';
import CCollapse from './Collapse/index.vue';
import CNotification from './Notification/index.vue';
import CErrorNotification from './ErrorNotification/index.vue';
import CForm from './form/index.vue';
import CContainerImage from './ContainerImage/index.vue';
import CContainerOpeningType from './ContainerOpeningType/index.vue';

const defaultFieldClasses = (type: string) => ({
  rootClass: '',
  [`${type}Class`]: () => {
    return `shadow-sm focus:ring-3 focus:ring-opacity-50 focus:ring-primary block rounded-md z-2
    w-full border border-gray-300 transition-colors duration-150 leading-5 placeholder-gray-400 disabled:bg-gray-100
    disabled:text-gray-600 disabled:cursor-not-allowed focus:border-primary`;
  },
  roundedClass: (_: any, context: any) => {
    if (context.props.size === 'large') return 'px-6 rounded-full';
    return 'px-4 rounded-full';
  },
  sizeClass: (size: string) => {
    if (size === 'small') return 'px-2 py-1 sm:text-sm';
    if (size === 'large') return 'px-3 py-3 text-lg';
    return 'sm:text-sm';
  },
  variantClass: (variant: string) => {
    if (variant === 'danger')
      return 'bg-red-100 border-red-500 focus:bg-gray-50 focus:border-primary';
    if (variant === 'success')
      return 'bg-green-100 border-green-500 focus:bg-gray-50 focus:border-primary';
    if (variant === 'warning')
      return 'bg-yellow-100 border-yellow-500 focus:bg-gray-50 focus:border-primary';
    return `bg-white border-gray-300 focus:bg-gray-50 focus:border-primary`;
  },
  placeholderClass: 'text-gray-400',
  iconRightClass: 'absolute w-5 h-full top-0 right-0 mx-2 inline-flex items-center',
  iconRightSpaceClass: 'pr-9',
  iconLeftClass: 'absolute w-5 h-full top-0 left-0 mx-2 inline-flex items-center',
  iconLeftSpaceClass: 'pl-9',
  expandedClass: 'flex flex-grow',
});

export default {
  install(Vue: VueConstructor): void {
    Vue.use(Field);
    Vue.use(Input);
    Vue.use(Button);
    Vue.use(Select);
    Vue.use(Modal);
    Vue.use(Autocomplete);
    Vue.use(Tabs);
    Vue.use(Slider);
    Vue.use(Tooltip);
    Vue.use(Upload);
    Vue.use(Dropdown);
    Vue.use(Table);
    Vue.use(Pagination);
    Vue.use(Switch);
    Vue.use(Checkbox);
    Vue.use(Datepicker);
    Vue.use(Collapse);
    Vue.use(Config, {
      iconComponent: 'CIcon',
      iconPack: 'hero',
      button: {
        rootClass: `focus:outline-none border text-center relative
        leading-5 transition-colors duration-150 rounded-md font-semibold
        focus:ring-primary-3`,
        variantClass: (variant: string, context: any) => {
          const { inverted, disabled } = context.props;
          if (disabled) {
            return 'shadow-sm bg-gray-200 text-gray-500 cursor-not-allowed shadow-none';
          }
          if (inverted) {
            if (variant === 'primary')
              return 'shadow-none border-transparent bg-transparent hover:opacity-75 text-primary';
            if (variant === 'secondary')
              return 'shadow-none border-transparent bg-transparent hover:opacity-75 text-black';
            if (variant === 'danger')
              return 'shadow-none border-transparent bg-red-100 hover:bg-red-200 text-red-700';
            return 'shadow-none bg-transparent border-transparent hover:bg-gray-200 text-black';
          }
          if (variant === 'primary')
            return 'shadow-sm bg-primary border-transparent hover:bg-opacity-75 text-on-primary';
          if (variant === 'secondary')
            return 'shadow-sm bg-gray-300 border-transparent hover:bg-opacity-75 text-black';
          if (variant === 'danger')
            return 'shadow-sm bg-red-500 border-transparent hover:bg-opacity-75 text-white';
          return 'shadow-sm bg-white border-gray-300 hover:bg-gray-100 text-black';
        },
        sizeClass: (size: string) => {
          if (size === 'small') return 'px-2 py-1 text-sm';
          if (size === 'large') return 'px-4 py-3 text-lg';
          if (size === 'icon') return 'px-2 py-2 text-sm';
          if (size === 'icon-small') return 'px-1 py-1 text-sm';
          return 'px-4 py-2 text-sm';
        },
        iconClass: () => {
          return 'inline-flex mx-1 items-center';
        },
        roundedClass: 'rounded-full',
      },
      field: {
        rootClass: 'mb-4',
        bodyClass: 'relative',
        labelClass: (_: any, context: any) => {
          if (context.props.rounded) {
            return 'font-medium text-gray-800 px-px ml-8';
          }
          return 'font-medium text-gray-800 px-px';
        },
        labelSizeClass: (size: string) => {
          if (size === 'small') return 'text-xs';
          if (size === 'large') return 'text-lg';
          return 'text-sm';
        },
        messageClass: (_: any, context: any) => {
          if (context.props.labelSize !== 'large') {
            if (context.props.variant === 'success') return 'text-xs p-px mt-px text-green-600';
            if (context.props.variant === 'danger') return 'text-xs p-px mt-px text-red-600';
            if (context.props.variant === 'warning') return 'text-xs p-px mt-px text-yellow-600';
            return 'text-xs p-px mt-px text-gray-500';
          }
          if (context.props.variant === 'success') return 'text-sm p-px mt-1 text-green-600';
          if (context.props.variant === 'danger') return 'text-sm p-px mt-1 text-red-600';
          if (context.props.variant === 'warning') return 'text-sm p-px mt-1 text-yellow-600';
          return 'text-sm p-px mt-1 text-gray-500';
        },
        addonsClass: 'flex items-center',
      },
      modal: {
        // closeIcon: 'x',
        animation: 'fade',
        width: null,
        rootClass: (_: any, context: any) => {
          const baseClass =
            'fixed top-0 left-0 w-full h-stretch flex justify-center items-end md:items-center z-20';
          if (['dialog', 'default'].includes(context.props.props.variant))
            return `${baseClass} pb-safe-area`;
          return baseClass;
        },
        overlayClass: () => {
          return 'bg-gray-900 bg-opacity-50 w-full h-full absolute top-0 left-0';
        },
        contentClass: (_: any, context: any) => {
          const baseClass =
            'modal-child bg-white relative z-10 shadow flex-grow max-h-stretch shadow-md transition duration-300';
          const {
            variant,
          }: { variant: 'fullpagesheet' | 'pagesheet' | 'formsheet' | 'dialog' | 'default' } =
            context.props.props;
          if (variant === 'fullpagesheet') return `${baseClass} max-w-screen h-full overflow-auto`;
          if (variant === 'pagesheet')
            return `${baseClass} max-w-7xl h-full xl:max-h-[90vh] 2xl:max-h-[87.5vh] xl:rounded-lg overflow-auto`;
          if (variant === 'formsheet')
            return `${baseClass} max-w-full md:w-full md:max-w-xl lg:max-w-2xl rounded-t-lg
            md:rounded-b-lg h-98/100 md:h-auto overflow-auto md:max-h-80vh`;
          if (variant === 'dialog') return `${baseClass} max-w-sm rounded-lg m-4 md:m-6 lg:m-8`;
          return `${baseClass} max-w-md m-4 md:m-6 lg:m-8 rounded-lg overflow-auto max-h-80vh`;
        },
        closeClass: (_: any, context: any) => {
          if (context.props.closeIcon) return 'absolute top-0 right-0 p-2';
          return 'hidden';
        },
      },
      autocomplete: {
        menuClass:
          'absolute bg-white bg-opacity-90 w-full backdrop-filter backdrop-blur-md z-10 mt-1 rounded-lg shadow-sm border broder-gray-300 overflow-y-auto md:text-sm',
        itemClass: 'py-2 px-4 border-b last:border-b-0 select-none cursor-pointer',
        itemHoverClass: 'bg-gray-200',
      },
      tabs: {
        navTabsClass: (_: any, context: any) => {
          const { type } = context.props;
          if (type === 'segment')
            return 'flex p-1 bg-gray-200 bg-opacity-75 border backdrop-filter backdrop-blur-md rounded-lg overflow-hidden';
          return '';
        },
        itemHeaderClass: (_: any, context: any) => {
          const { itemClass } = context.props;
          if (itemClass === 'segment')
            return 'inline-flex items-center justify-center md:min-w-16 px-3 w-full transition duration-300 border border-transparent rounded-lg hover:bg-white hover:shadow-md focus:outline-none focus:ring-primary-2 text-gray-700 hover:cursor-pointer';
          return '';
        },
        itemHeaderIconClass: (_: any, context: any) => {
          const { itemClass } = context.props;
          if (itemClass === 'segment') return 'inline-flex mr-1';
          return '';
        },
        itemHeaderTextClass: (_: any, context: any) => {
          const { itemClass } = context.props;
          if (itemClass === 'segment')
            return 'text-sm font-semibold leading-5 py-1 sm:py-0 sm:leading-7';
          return '';
        },
        itemHeaderActiveClass: (_: any, context: any) => {
          const { itemClass } = context.props;
          if (itemClass === 'segment') return 'bg-white shadow-md border-gray-300 text-gray-900';
          return '';
        },
        tabItemWrapperClass: (_: any, context: any) => {
          const { type } = context.props;
          if (type === 'segment') return 'mr-1 last:mr-0 inline-flex flex-1 md:flex-auto';
          return '';
        },
      },
      slider: {
        rootClass: 'block w-full',
        thumbClass:
          'flex items-center justify-center text-[0.625rem] text-gray-600 font-bold bg-white shadow-sm rounded-full border h-6 w-6 transform cursor-pointer focus:ring-primary-2',
        thumbDraggingClass: 'scale-105',
        thumbWrapperClass: 'absolute top-1/2 transform -translate-y-1/2 -translate-x-1/2',
        fillClass: 'bg-primary absolute h-full top-0 rounded-full',
        trackClass: 'relative w-full h-1 bg-gray-300 rounded-full',
        tickClass:
          'absolute backdrop-filter backdrop-invert w-1 h-1 opacity-50 rounded-full filter hue-rotate-180',
      },
      tooltip: {
        rootClass: '',
        contentClass: (_: any, context: any) => {
          const baseClass =
            'bg-gray-900 text-gray-50 absolute transform rounded-md shadow-sm px-1 py-px bg-opacity-90 backdrop-filter backdrop-blur-md';
          if (context.props.position === 'top')
            return `${baseClass} -translate-y-full left-1/2 -translate-x-1/2`;
          return baseClass;
        },
      },
      input: defaultFieldClasses('input'),
      select: defaultFieldClasses('select'),
      upload: {
        rootClass:
          'flex w-full focus-within:ring focus-within:ring-primary focus-within:ring-opacity-50 rounded-lg',
        draggableClass:
          'flex w-full rounded-lg border border-2 p-4 md:p-6 lg:p-8 border-dashed transition duration-300',
        hoveredClass: 'border-gray-400 bg-gray-100',
      },
      dropdown: {
        rootClass: 'relative',
        menuClass:
          'min-w-[14rem] py-1 block absolute bg-white bg-opacity-95 backdrop-filter backdrop-blur-md z-10 mt-1 rounded-lg shadow-lg border overflow-y-auto',
        itemClass:
          'group flex items-center px-4 py-2 text-sm select-none cursor-pointer text-gray-700 block hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 whitespace-nowrap',
        menuPositionClass: (position: string) => {
          if (position === 'bottom-left') {
            return 'right-0';
          }
          if (position === 'top-left') {
            return 'bottom-full right-0';
          }
          if (position === 'top-right') {
            return 'bottom-full left-0';
          }
          return 'left-0';
        },
        triggerClass: 'focus:outline-none rounded-md',
      },
      table: {
        tableClass: 'min-w-full w-full table-fixed',
        thClass: (_: any, ctx: any) => {
          if (ctx.props.narrowed) return 'px-3 py-3 text-xs font-medium text-gray-500 text-left';
          return 'uppercase px-6 py-3 text-xs font-medium text-gray-500 text-left tracking-wider';
        },
        thCurrentSortClass: '!text-gray-600',
        thPositionClass: (position: 'centered' | 'right' | 'left') => {
          if (position === 'centered') return 'text-center';
          if (position === 'right') return 'text-right';
          return null;
        },
        thSortableClass: 'hover:bg-gray-100 cursor-pointer transition duration-150',
        tdClass: (_: any, ctx: any) => {
          if (ctx.props.narrowed) return 'px-3 py-3 text-xsm font-medium text-gray-900';
          return 'px-6 py-4 text-sm font-medium text-gray-900';
        },
      },
      pagination: {
        iconNext: 'arrow-sm-right',
        iconPrev: 'arrow-sm-left',
        rootClass: 'flex items-center px-4 md:px-5 py-3 md:py-4',
        linkClass:
          'mx-1 bg-white border rounded-full text-sm font-medium shadow-sm focus:ring-primary-2 items-center justify-center w-8 h-8 inline-flex transition duration-150 hover:bg-gray-100',
        linkCurrentClass: 'bg-primary text-white pointer-events-none border-primary',
        linkDisabledClass: 'bg-gray-200 text-gray-600 pointer-events-none',
        listClass: (_: any, ctx: any) => {
          let baseClass = 'inline-flex items-center mx-1';
          if (ctx.props.size === 'large') {
            baseClass += ' flex-grow';
          }
          if (ctx.props.order === 'left') {
            return `${baseClass} order-1 justify-start`;
          }
          if (ctx.props.order === 'right') {
            return `${baseClass} order-5 justify-end`;
          }
          return `${baseClass} order-3 justify-center`;
        },
        prevBtnClass: 'order-2',
        nextBtnClass: 'order-4',
        orderClass: (order: string) => {
          if (order === 'left') return 'justify-start';
          if (order === 'right') return 'justify-end';
          return 'justify-center';
        },
        ellipsisClass: 'px-2 text-gray-700',
        infoClass: (_: any, ctx: any) => {
          let baseClass = 'text-gray-700 text-sm';
          if (ctx.props.size === 'large') {
            baseClass += ' flex-grow';
          }
          if (ctx.props.order === 'left') return `${baseClass} ml-3 order-5 text-right`;
          return `${baseClass} mr-3 order-1 text-left`;
        },
      },
      switch: {
        rootClass: 'c-switch group inline-flex',
        elementsWrapperClass: 'inline-flex',
        checkClass:
          'bg-gray-300 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
        checkCheckedClass: 'bg-primary',
        checkSwitchClass: (_: any, ctx: any) => {
          const baseClass =
            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200';
          if (ctx.computed.computedValue) {
            return `${baseClass} translate-x-5`;
          }
          return `${baseClass} translate-x-0 `;
        },
        disabledClass: 'opacity-50',
      },
      checkbox: {
        rootClass: 'flex items-start mb-4',
        checkClass: 'focus:ring-primary-3 h-4 w-4 text-primary border-gray-300 rounded',
        labelClass: 'font-medium text-gray-700 ml-3 leading-4 text-sm',
      },
      datepicker: {
        firstDayOfWeek: 1,
        nearbySelectableMonthDays: true,
      },
    });
    Vue.component('CTextField', CTextField);
    Vue.component('CSelect', CSelect);
    Vue.component('CButton', CButton);
    Vue.component('CTypo', CTypo);
    Vue.component('CAddressField', CAddressField);
    Vue.component('CMessage', CMessage);
    Vue.component('CLink', CLink);
    Vue.component('CDivider', CDivider);
    Vue.component('CLoading', CLoading);
    Vue.component('CLoadingSpinner', CLoadingSpinner);
    Vue.component('CLoadingDots', CLoadingDots);
    Vue.component('CInfoCard', CInfoCard);
    Vue.component('CInfoCardGroup', CInfoCardGroup);
    Vue.component('CIcon', CIcon);
    Vue.component('CNavSidebarItem', CNavSidebarItem);
    Vue.component('CStackRouter', CStackRouter);
    Vue.component('CStackRouterChild', CStackRouterChild);
    Vue.component('CSlideOver', CSlideOver);
    Vue.component('CModal', CModal);
    Vue.component('CSegmentControl', CSegmentControl);
    Vue.component('CSegmentControlItem', CSegmentControlItem);
    Vue.component('CMap', CMap);
    Vue.component('CMapMarker', CMapMarker);
    Vue.component('CMapCircle', CMapCircle);
    Vue.component('CSelectBox', CSelectBox);
    Vue.component('CSlider', CSlider);
    Vue.component('CUpload', CUpload);
    Vue.component('CImageUpload', CImageUpload);
    Vue.component('CContainer', CContainer);
    Vue.component('CNavBar', CNavBar);
    Vue.component('CNavBarItem', CNavBarItem);
    Vue.component('CDropdownMenu', CDropdownMenu);
    Vue.component('CDropdownMenuItem', CDropdownMenuItem);
    Vue.component('CTable', CTable);
    Vue.component('CBadge', CBadge);
    Vue.component('CResizeObserver', CResizeObserver);
    Vue.component('CAlert', CAlert);
    Vue.component('CNavSub', CNavSub);
    Vue.component('CNavSubItem', CNavSubItem);
    Vue.component('CCard', CCard);
    Vue.component('CNavSidebar', CNavSidebar);
    Vue.component('CColorPicker', CColorPicker);
    Vue.component('CSwitch', CSwitch);
    Vue.component('CEditor', CEditor);
    Vue.component('CValidationFailure', CValidationFailure);
    Vue.component('CCheckbox', CCheckbox);
    Vue.component('CCheckboxList', CCheckboxList);
    Vue.component('CDatepicker', CDatepicker);
    Vue.component('CPasswordInputs', CPasswordInputs);
    Vue.component('CDescriptionListItem', CDescriptionListItem);
    Vue.component('CDescriptionList', CDescriptionList);
    Vue.component('CSvgImage', CSvgImage);
    Vue.component('CCollapse', CCollapse);
    Vue.component('CNotification', CNotification);
    Vue.component('CErrorNotification', CErrorNotification);
    Vue.component('CForm', CForm);
    Vue.component('CContainerImage', CContainerImage);
    Vue.component('CContainerOpeningType', CContainerOpeningType);
  },
};
