<template>
  <div
    class="c-nav-sub bg-white sticky top-16 z-5"
    :class="{
      'shadow-sm': !divider || scrolled,
    }"
  >
    <CContainer class="px-6 md:px-8 overflow-x-auto" no-spacing>
      <div
        v-if="$slots.default"
        class="border-b flex items-center flex-nowrap"
        :class="{
          'border-transparent': !divider,
        }"
      >
        <slot />
      </div>
    </CContainer>
  </div>
</template>

<script>
export default {
  props: {
    divider: {
      type: Boolean,
      default: false,
    },
    scrolled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
