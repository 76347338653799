<template>
  <div class="w-full min-h-stretch">
    <router-view v-if="!isAuthLoading" />
    <PortalTarget name="overlays" multiple :disabled="isAuthLoading" />
    <transition
      enter-class="opacity-0"
      enter-to-class="opacity-100"
      leave-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-show="!authIsReady || appIsLoading"
        class="fixed top-0 left-0 w-full min-h-stretch max-h-screen
        flex justify-center items-center transition-opacity duration-300
        ease-in-out bg-white z-50"
      >
        <CLoading />
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { APP_IS_LOADING, AUTH_IS_READY, IS_AUTH_LOADING } from './store/gettersTypes';
import { SET_WINDOW_TITLE, SET_WINDOW_SCROLL } from './store/mutationsTypes';

export default {
  data: () => ({}),

  computed: {
    ...mapGetters([AUTH_IS_READY, IS_AUTH_LOADING, APP_IS_LOADING]),
  },

  watch: {
    $route: {
      immediate: true,
      handler(newVal) {
        this.setWindowTitle(newVal.meta.title ? this.$t(newVal.meta.title) : null);
        this.$nextTick(() => {
          this.setWindowScroll({
            x: window.scrollX,
            y: window.scrollY,
          });
        });
      },
    },
  },

  methods: {
    ...mapMutations([SET_WINDOW_TITLE, SET_WINDOW_SCROLL]),
  },
};
</script>
