<template>
  <OTabItem
    :label="label"
    :value="value"
    item-class="segment"
    :icon="icon"
    :tag="'a'"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
  },

  computed: {
    type() {
      return this.$parent.type;
    },
  },
};
</script>
