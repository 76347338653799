import type { Module } from 'vuex';
import type { TManagerInvite, TManagerInviteCreateBody } from '@contimo/api/src/api/managerInvites';
import { TPaginationMeta } from '@contimo/types/src/Api';
import managerInvites from '@contimo/api/src/api/managerInvites';
import { removeFromArrayByKey, updateOrPushInArray } from '@/utils/store';
import type { TRootStore } from '@/store';
import { ALL_MANAGER_INVITES } from '../gettersTypes';
import {
  SET_MANAGER_INVITE,
  SET_MANAGER_INVITES,
  SET_MANAGER_INVITES_LOADING,
  SET_MANAGER_INVITE_LOADING,
  SET_MANAGER_INVITES_PAGINATION,
  SET_MANAGER_INVITES_SUBMIT_LOADING,
  REMOVE_MANAGER_INVITE,
} from '../mutationsTypes';
import {
  CREATE_MANAGER_INVITE,
  DELETE_MANAGER_INVITE,
  GET_MANAGER_INVITE,
  GET_MANAGER_INVITES,
  RESEND_MANAGER_INVITE,
} from '../actionTypes';

export interface IManagerInvitesStore {
  loading: boolean;
  singleLoading: boolean;
  createLoading: boolean;
  submitLoading: boolean;
  managerInvites: TManagerInvite[];
  pagination: TPaginationMeta | null;
}

type TManagerInvitesStore = Module<IManagerInvitesStore, TRootStore>;

const managerInvitesStore: TManagerInvitesStore = {
  state: () => ({
    loading: false,
    singleLoading: false,
    createLoading: false,
    submitLoading: false,
    managerInvites: [],
    pagination: null,
  }),

  getters: {
    [ALL_MANAGER_INVITES]: (state) => state.managerInvites,
  },

  mutations: {
    [SET_MANAGER_INVITE](state, managerInvite: TManagerInvite) {
      updateOrPushInArray(state.managerInvites, managerInvite);
    },
    [SET_MANAGER_INVITES](state, managerInvites: TManagerInvite[]) {
      managerInvites.forEach((managerInvite) => {
        updateOrPushInArray(state.managerInvites, managerInvite);
      });
    },
    [REMOVE_MANAGER_INVITE](state, id: number) {
      removeFromArrayByKey(state.managerInvites, id);
    },
    [SET_MANAGER_INVITE_LOADING](state, loading: boolean) {
      state.singleLoading = loading;
    },
    [SET_MANAGER_INVITES_LOADING](state, loading: boolean) {
      state.loading = loading;
    },
    [SET_MANAGER_INVITES_PAGINATION](state, meta: TPaginationMeta | null) {
      state.pagination = meta;
    },
    [SET_MANAGER_INVITES_SUBMIT_LOADING](state, loading: boolean) {
      state.submitLoading = loading;
    },
  },

  actions: {
    async [GET_MANAGER_INVITE]({ commit }, id: number) {
      commit(SET_MANAGER_INVITE_LOADING, true);
      try {
        const { data } = await managerInvites.show(id);
        commit(SET_MANAGER_INVITE, data);
        return data;
      } finally {
        commit(SET_MANAGER_INVITE_LOADING, false);
      }
    },
    async [GET_MANAGER_INVITES]({ commit, state }, page = 1) {
      commit(SET_MANAGER_INVITES_LOADING, true);
      try {
        const { data } = await managerInvites.index({
          page,
          limit: state.pagination?.perPage || 50,
        });
        commit(SET_MANAGER_INVITES, data.data);
        commit(SET_MANAGER_INVITES_PAGINATION, data.meta, {
          root: false,
        });
        return data;
      } finally {
        commit(SET_MANAGER_INVITES_LOADING, false);
      }
    },
    async [CREATE_MANAGER_INVITE]({ commit }, body: TManagerInviteCreateBody) {
      commit(SET_MANAGER_INVITES_SUBMIT_LOADING, true);
      try {
        const { data } = await managerInvites.store(body);
        commit(SET_MANAGER_INVITE, data);
        return data;
      } finally {
        commit(SET_MANAGER_INVITES_SUBMIT_LOADING, false);
      }
    },
    async [RESEND_MANAGER_INVITE](_, id: number) {
      await managerInvites.resendInvite(id);
    },
    async [DELETE_MANAGER_INVITE]({ commit }, id: number) {
      await managerInvites.destroy(id);
      commit(REMOVE_MANAGER_INVITE, id);
    },
  },
};

export default managerInvitesStore;
