import { germanDecimalToNumber } from './germanDecimalParser';

function fixCalculationError(number: number | string) {
  return Number(Number(number).toFixed(0));
}

export function decimalPriceToInteger(currentPrice: number | string) {
  const withCommaRadix = currentPrice.toString().replace('.', ',');
  let price = germanDecimalToNumber(withCommaRadix);
  price *= 100;
  price = fixCalculationError(price);
  return price;
}
