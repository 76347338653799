<template>
  <div :class="[sizeClass, rootClass, colorClass]">
    <slot />
  </div>
</template>

<script>
const SIZES = ['large', 'default'];
const COLORS = ['gray', 'primary', 'red', 'yellow', 'green'];

export default {
  props: {
    size: {
      type: String,
      default: 'default',
      validator: (val) => SIZES.includes(val),
    },
    color: {
      type: String,
      default: 'gray',
      validator: (val) => COLORS.includes(val),
    },
  },

  computed: {
    sizeClass() {
      if (this.size === 'large') return 'px-3 py-0.5 text-sm';
      return 'px-2.5 py-0.5 text-xs';
    },
    rootClass() {
      return 'rounded-full inline-flex items-center font-medium';
    },
    colorClass() {
      if (this.color === 'primary') return 'bg-primary text-on-primary';
      if (this.color === 'red') return 'bg-red-100 text-red-800';
      if (this.color === 'yellow') return 'bg-yellow-100 text-yellow-800';
      if (this.color === 'green') return 'bg-green-100 text-green-800';
      return 'bg-gray-100 text-gray-800';
    },
  },
};
</script>
