<!-- eslint-disable max-len -->
<template>
  <div
    class="rounded-md p-4 md:p-5"
    :class="{
      'bg-red-100': variant === 'danger',
      'bg-gray-100': variant === 'info',
      'bg-green-100': variant === 'success',
      'bg-yellow-100': variant === 'warning',
    }"
  >
    <div class="flex">
      <div class="flex-shrink-0">
        <!-- Heroicon name: solid/x-circle -->
        <svg
          class="h-5 w-5"
          :class="{
            'text-red-500': variant === 'danger',
            'text-gray-500': variant === 'info',
            'text-green-500': variant === 'success',
            'text-yellow-500': variant === 'warning',
          }"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            v-if="variant === 'danger'"
            fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
            clip-rule="evenodd"
          />
          <path
            v-else-if="variant === 'success'"
            fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
            clip-rule="evenodd"
          />
          <path
            v-else-if="variant === 'info'"
            fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
            clip-rule="evenodd"
          />
          <path
            v-else-if="variant === 'warning'"
            fill-rule="evenodd"
            d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
      <div class="ml-3">
        <CTypo
          el="h3"
          tstyle="body"
          class="font-semibold"
          :class="{
            'text-red-800': variant === 'danger',
            'text-gray-800': variant === 'info',
            'text-green-800': variant === 'success',
            'text-yellow-800': variant === 'warning',
          }"
        >
          <slot />
        </CTypo>
        <CTypo
          v-if="$slots.copy"
          el="p"
          tstyle="body"
          class="mt-2"
          :class="{
            'text-red-700': variant === 'danger',
            'text-gray-700': variant === 'info',
            'text-green-700': variant === 'success',
            'text-yellow-700': variant === 'warning',
          }"
        >
          <slot name="copy" />
        </CTypo>
        <div
          v-if="$slots.action"
          class="mt-3 mb-2 uppercase text-sm font-medium tracking-wide"
          :class="{
            'text-red-800': variant === 'danger',
            'text-gray-800': variant === 'info',
            'text-green-800': variant === 'success',
            'text-yellow-800': variant === 'warning',
          }"
        >
          <slot name="action" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    variant: {
      type: String,
      default: 'info',
      validator: (value) => ['danger', 'info', 'warning', 'success'].includes(value),
    },
  },
};
</script>
