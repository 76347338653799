import { AxiosPromise } from 'axios';
import buildPaginationQuery from '../utils/buildPaginationQuery';
import type { TApiPaginationQuery, TApiPaginationResult } from '@contimo/types/src/Api';
import client from './client';
import type { TManager } from './managers';

const buildUrl = (path?: string | number) => (path ? `/users/${path}` : '/users');

export type TCustomer = {
  id: number;
  paymentProviderId: string | null;
  phone: string | null;
  createdAt: string;
  updatedAt: string;
};

export type TUser = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  verified: boolean;
  termsVersion: string;
  customerId: number | null;
  managerId: number | null;
  isAdmin: boolean;
  createdAt: string;
  updatedAt: string;
  customer?: TCustomer;
  manager?: TManager;
};

export default {
  index: (pagination: TApiPaginationQuery = {}): AxiosPromise<TApiPaginationResult<TUser[]>> => {
    return client.get(`${buildUrl()}${buildPaginationQuery(pagination)}`);
  },
  show: (id: number): AxiosPromise<TUser> => client.get(buildUrl(id)),
  update: (id: number, updates: Record<string, any>): AxiosPromise<TUser> =>
    client.patch(buildUrl(id), updates),
};
