import type { Module } from 'vuex';
import { TPaginationMeta } from '@contimo/types/src/Api';
import managers, { TManager, TManagerUpdateBody } from '@contimo/api/src/api/managers';
import type { TRootStore } from '@/store';
import { updateOrPushInArray } from '@/utils/store';
import { ALL_MANAGERS, CURRENT_MANAGER } from '../gettersTypes';
import {
  SET_SINGLE_MANAGER,
  SET_MANAGERS,
  SET_MANAGER_LOADING,
  SET_MANAGERS_LOADING,
  SET_MANAGERS_PAGINATION,
  OPEN_MANAGER,
  SET_MANAGER_UPDATE_LOADING,
} from '../mutationsTypes';
import { GET_MANAGERS, GET_SINGLE_MANAGER, UPDATE_MANAGER } from '../actionTypes';

export interface IManagersStore {
  loading: boolean;
  singleLoading: boolean;
  updateLoading: boolean;
  managers: TManager[];
  selectedManager: number | null;
  pagination: TPaginationMeta | null;
}

type TManagersStore = Module<IManagersStore, TRootStore>;

const usersStore: TManagersStore = {
  state: () => ({
    loading: false,
    singleLoading: false,
    updateLoading: false,
    managers: [],
    selectedManager: null,
    pagination: null,
  }),

  getters: {
    [ALL_MANAGERS]: (state) => state.managers,
    [CURRENT_MANAGER]: (state) =>
      state.managers.find((manager) => manager.id === state.selectedManager) || null,
  },

  mutations: {
    [SET_SINGLE_MANAGER](state, manager: TManager) {
      updateOrPushInArray(state.managers, manager);
    },
    [SET_MANAGERS](state, managers: TManager[]) {
      managers.forEach((manager) => {
        updateOrPushInArray(state.managers, manager);
      });
    },
    [SET_MANAGER_LOADING](state, loading: boolean) {
      state.singleLoading = loading;
    },
    [SET_MANAGERS_LOADING](state, loading: boolean) {
      state.loading = loading;
    },
    [SET_MANAGERS_PAGINATION](state, meta: TPaginationMeta | null) {
      state.pagination = meta;
    },
    [OPEN_MANAGER](state, id: number) {
      state.selectedManager = id;
    },
    [SET_MANAGER_UPDATE_LOADING](state, loading: boolean) {
      state.updateLoading = loading;
    },
  },

  actions: {
    async [GET_SINGLE_MANAGER]({ commit }, id: number) {
      commit(SET_MANAGER_LOADING, true);
      try {
        const { data } = await managers.show(id);
        commit(SET_SINGLE_MANAGER, data);
        return data;
      } finally {
        commit(SET_MANAGER_LOADING, false);
      }
    },
    async [GET_MANAGERS]({ commit, state }, page = 1) {
      commit(SET_MANAGERS_LOADING, true);
      try {
        const { data } = await managers.index({ page, limit: state.pagination?.perPage || 50 });
        commit(SET_MANAGERS, data.data);
        commit(SET_MANAGERS_PAGINATION, data.meta, {
          root: false,
        });
        return data;
      } finally {
        commit(SET_MANAGERS_LOADING, false);
      }
    },
    async [UPDATE_MANAGER]({ commit }, { id, body }: { id: number; body: TManagerUpdateBody }) {
      commit(SET_MANAGER_UPDATE_LOADING, true);
      try {
        const { data } = await managers.update(id, body);
        commit(SET_SINGLE_MANAGER, data);
        return data;
      } finally {
        commit(SET_MANAGER_UPDATE_LOADING, false);
      }
    },
  },
};

export default usersStore;
