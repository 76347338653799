import { AxiosPromise } from 'axios';
import client from './client';

const buildUrl = (path: string) => `/auth/${path}`;

export type TSignupType = 'merchant' | 'customer' | 'manager';
export type TSignupData = {
  firstName: string;
  lastName: string;
  email?: string;
  password: string;
  type: TSignupType;
  termsVersion: string;
  phone?: string;
  merchantName?: string;
  address?: {
    street: string;
    number: string;
    postalCodeId: string;
    city?: string | null;
    countryShort: string;
  };
};

export default {
  login: (email: string, password: string): AxiosPromise => {
    return client.post(buildUrl('login'), {
      email,
      password,
    });
  },
  relogin: (): AxiosPromise => client.post(buildUrl('relogin')),
  signup: (data: TSignupData): AxiosPromise => client.post(buildUrl('signup'), data),
  logout: (): AxiosPromise => client.post(buildUrl('logout')),
  verifyEmail: (email: string, signature: string): AxiosPromise => {
    return client.get(buildUrl(`verify/${email}?signature=${signature}`));
  },
  resendVerificationMail: (email: string): AxiosPromise => {
    return client.post(buildUrl('resend-verification'), {
      email,
    });
  },
  acceptInvite: (data: TSignupData, email: string, signature: string): AxiosPromise => {
    return client.post(buildUrl(`signup/${email}?signature=${signature}`), data);
  },
  requestPasswordReset: (email: string): AxiosPromise => {
    return client.post(buildUrl('request-password-reset'), {
      email,
    });
  },
  resetPassword: (email: string, signature: string, password: string): AxiosPromise => {
    return client.post(buildUrl(`reset-password/${email}?signature=${signature}`), { password });
  },
};
