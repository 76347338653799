var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-4 md:px-6 lg:px-8 border",class:{
    'py-4 md:py-5': _vm.large,
    'py-3 md:py-4': !_vm.large,
    'bg-white': ['default', 'success'].includes(_vm.variant),
    'bg-gray-50': ['silent', 'warning'].includes(_vm.variant),
    'text-gray-900': _vm.variant !== 'disabled',
    'bg-gray-100 text-gray-400': _vm.variant === 'disabled',
    'first:mt-0 first:rounded-t-lg last:rounded-b-lg -mt-px': _vm.hasGroupParent,
    'rounded-lg mb-4 shadow-sm': !_vm.hasGroupParent,
  }},[_c('div',{staticClass:"justify-between items-center md:flex"},[_c('div',{staticClass:"flex items-center mr-2 md:mr-3"},[(_vm.icon)?_c('div',{staticClass:"mr-2 md:mr-3 lg:mr-4",class:{
          'text-green-600': _vm.variant === 'success',
          'text-yellow-600': _vm.variant === 'warning',
          'text-gray-300': ['default', 'disabled'].includes(_vm.variant),
        }},[_c('CIcon',{attrs:{"name":_vm.icon}})],1):_vm._e(),(_vm.large)?_c('CTypo',{attrs:{"el":"h2","tstyle":"title2"}},[_vm._t("default")],2):(_vm.small)?_c('CTypo',{staticClass:"font-medium",attrs:{"el":"div","tstyle":"body"}},[_vm._t("default")],2):_c('CTypo',{attrs:{"el":"h3","tstyle":"subheadline"}},[_vm._t("default")],2),(_vm.loading)?_c('CLoadingDots',{staticClass:"ml-4 h-4 text-gray-300"}):_vm._e()],1),(_vm.$slots.right)?_c('div',{staticClass:"hidden md:inline-flex flex-shrink-0"},[_vm._t("right")],2):_vm._e()]),(_vm.$slots.description)?_c('div',{staticClass:"mt-2 max-w-3xl"},[_c('CTypo',{staticClass:"text-gray-700",attrs:{"tstyle":_vm.small ? 'footnote2' : 'body'}},[_vm._t("description")],2)],1):_vm._e(),(_vm.$slots.right)?_c('div',{staticClass:"flex md:hidden mt-2 justify-end"},[_vm._t("right")],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }