<template>
  <ODropdownItem aria-role="listitem" @click="$emit('click')">
    <div class="inline-flex items-center">
      <CIcon
        v-if="icon"
        :name="icon"
        class="mr-3 text-gray-400 group-hover:text-gray-500"
        size="20"
      />
      <span class="leading-5">
        <slot />
      </span>
    </div>
  </ODropdownItem>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: null,
    },
  },
};
</script>
