import type { Module } from 'vuex';
import type { TApiPaginationQuery, TPaginationMeta } from '@contimo/types/src/Api';
import { shopCertificates } from '@contimo/api/src/api';
import {
  IShopCertificate,
  IShopCertificateCreateBody,
  IShopCertificateUpdateBody,
} from '@contimo/api/src/api/shopCertificates';
import { removeFromArrayByKey, updateOrPushInArray } from '@/utils/store';
import type { TRootStore } from '@/store';
import {
  CREATE_SHOP_CERTIFICATE,
  DELETE_SHOP_CERTIFICATE,
  GET_SHOP_CERTIFICATES,
  UPDATE_SHOP_CERTIFICATE,
} from '../actionTypes';
import {
  OPEN_SHOP_CERTIFICATE,
  REMOVE_SHOP_CERTIFICATE,
  SET_SHOP_CERTIFICATE,
  SET_SHOP_CERTIFICATES,
} from '../mutationsTypes';
import { ALL_SHOP_CERTIFICATES, CURRENT_SHOP_CERTIFICATE } from '../gettersTypes';

export interface IShopCertificatesStoreState {
  loading: boolean;
  createLoading: boolean;
  updateLoading: boolean;
  shopCertificates: IShopCertificate[];
  selectedShopCertificate: number | null;
  pagination: TPaginationMeta | null;
}

type TShopCertificatesStore = Module<IShopCertificatesStoreState, TRootStore>;

const merchantWastesStore: TShopCertificatesStore = {
  state: () => ({
    loading: false,
    createLoading: false,
    updateLoading: false,
    shopCertificates: [],
    selectedShopCertificate: null,
    pagination: null,
  }),

  getters: {
    [ALL_SHOP_CERTIFICATES]: (state) => {
      return state.shopCertificates;
    },
    [CURRENT_SHOP_CERTIFICATE]: (state) => {
      return state.shopCertificates.find(
        (shopCertificate) => shopCertificate.id === state.selectedShopCertificate,
      );
    },
  },

  mutations: {
    [SET_SHOP_CERTIFICATES](state, data: IShopCertificate[]) {
      data.forEach((value) => {
        updateOrPushInArray(state.shopCertificates, value);
      });
    },
    [SET_SHOP_CERTIFICATE](state, data: IShopCertificate) {
      updateOrPushInArray(state.shopCertificates, data);
    },
    [OPEN_SHOP_CERTIFICATE](state, id: number) {
      state.selectedShopCertificate = id;
    },
    [REMOVE_SHOP_CERTIFICATE](state, id: number) {
      removeFromArrayByKey(state.shopCertificates, id);
    },
    setShopCertificatesLoading(state, loading: boolean) {
      state.loading = loading;
    },
    setCreateLoading(state, loading: boolean) {
      state.createLoading = loading;
    },
    setUpdateLoading(state, loading: boolean) {
      state.updateLoading = loading;
    },
    setShopCertificatesPagination(state, pagination: TPaginationMeta | null) {
      state.pagination = pagination;
    },
  },

  actions: {
    async [GET_SHOP_CERTIFICATES]({ commit }, pagination: TApiPaginationQuery = {}) {
      const { data } = await shopCertificates.index(pagination);
      commit(SET_SHOP_CERTIFICATES, data.data);
      commit('setShopCertificatesPagination', data.meta, {
        root: false,
      });
    },
    async [CREATE_SHOP_CERTIFICATE]({ commit }, body: IShopCertificateCreateBody) {
      commit('setCreateLoading', true);
      try {
        const { data } = await shopCertificates.store(body);
        commit(SET_SHOP_CERTIFICATE, data);

        return body;
      } finally {
        commit('setCreateLoading', false);
      }
    },
    async [UPDATE_SHOP_CERTIFICATE](
      { commit },
      { id, body }: { id: number; body: IShopCertificateUpdateBody },
    ) {
      commit('setUpdateLoading', true);
      try {
        const { data } = await shopCertificates.update(id, body);
        commit(SET_SHOP_CERTIFICATE, data);

        return data;
      } finally {
        commit('setUpdateLoading', false);
      }
    },
    async [DELETE_SHOP_CERTIFICATE]({ commit }, id: number) {
      commit('setUpdateLoading', true);
      try {
        await shopCertificates.destroy(id);
        commit(REMOVE_SHOP_CERTIFICATE, id);
      } finally {
        commit('setUpdateLoading', false);
      }
    },
  },
};

export default merchantWastesStore;
