import type { TApiPaginationResult } from '@contimo/types/src/Api';
import { AxiosPromise } from 'axios';
import client from './client';

export type TPlatformWaste = {
  id: number;
  name: string;
  translationSlug: string;
  defaultMinSize: number;
  defaultMaxSize: number;
  defaultAccepted: string[];
  defaultNotAccepted: string[];
  image: string;
};

const buildUrl = (path?: string) => (path ? `/platform-wastes/${path}` : '/platform-wastes');

export default {
  index: (): AxiosPromise<TApiPaginationResult<TPlatformWaste[]>> => {
    return client.get(`${buildUrl()}`);
  },
  show: (id: string): AxiosPromise<TPlatformWaste | null> => client.get(buildUrl(id)),
};
