import type { Module } from 'vuex';
import type { TCountry } from '@contimo/api/src/api/countries';
import { countries, feedback } from '@contimo/api/src/api';
import type { TRootStore } from '@/store';
import { INIT_USER_INTERFACE, GET_COUNTRIES, INIT_EVENT_LISTENERS } from '../actionTypes';
import {
  SET_APP_LOADING,
  SET_COUNTRIES,
  SET_WINDOW_SIZE,
  SET_WINDOW_TITLE,
  SET_WINDOW_SCROLL,
  SET_SUCCESS_NOTIFICATION,
  SET_ERROR_NOTIFICATION,
  SET_VERSION,
} from '../mutationsTypes';
import {
  APP_IS_LOADING,
  WINDOW_SIZE,
  WINDOW_HEIGHT,
  WINDOW_WIDTH,
  WINDOW_SCROLL,
  SUCESS_NOTIFICATION,
  ERROR_NOTIFICATION,
  APP_VERSION,
} from '../gettersTypes';

export interface IUiStoreState {
  loading: boolean;
  countries: TCountry[];
  successNotification: {
    show: boolean;
    content: string | null;
  };
  errorNotification: {
    show: boolean;
    content: string | null;
  };
  window: {
    height: number;
    width: number;
    scroll: {
      x: number;
      y: number;
    };
    title: string;
  };
  version: string | null;
}

type TUiStore = Module<IUiStoreState, TRootStore>;

const uiStore: TUiStore = {
  state: () => ({
    loading: false,
    countries: [],
    successNotification: {
      show: false,
      content: null,
    },
    errorNotification: {
      show: false,
      content: null,
    },
    window: {
      height: 0,
      width: 0,
      scroll: {
        x: 0,
        y: 0,
      },
      title: 'Contimo',
    },
    version: null,
  }),

  getters: {
    [APP_IS_LOADING]: (state) => state.loading,
    [WINDOW_SIZE]: (state) => ({ width: state.window.width, height: state.window.height }),
    [WINDOW_WIDTH]: (state) => state.window.width,
    [WINDOW_HEIGHT]: (state) => state.window.height,
    [WINDOW_SCROLL]: (state) => state.window.scroll,
    [SUCESS_NOTIFICATION]: (state) => state.successNotification,
    [ERROR_NOTIFICATION]: (state) => state.errorNotification,
    [APP_VERSION]: (state) => state.version,
  },

  mutations: {
    [SET_COUNTRIES](state, countries: TCountry[]) {
      state.countries = countries;
    },
    [SET_APP_LOADING](state, loading: boolean) {
      state.loading = loading;
    },
    [SET_WINDOW_SIZE](state, { width, height }) {
      state.window.height = height;
      state.window.width = width;
      document.documentElement.style.setProperty('--window-height', `${height}px`);
    },
    [SET_WINDOW_TITLE](state, title?: string | null) {
      state.window.title = `Contimo${title ? `: ${title}` : ''}`;
      if (document) {
        document.title = state.window.title;
      }
    },
    [SET_WINDOW_SCROLL](state, { x, y }: { x: number; y: number }) {
      state.window.scroll = { x, y };
    },
    [SET_SUCCESS_NOTIFICATION](
      state,
      { show, content }: { show: boolean; content: string | null },
    ) {
      state.successNotification = { show, content };
    },
    [SET_ERROR_NOTIFICATION](state, { show, content }: { show: boolean; content: string | null }) {
      state.errorNotification = { show, content };
    },
    [SET_VERSION](state, version: string) {
      state.version = version;
    },
  },

  actions: {
    async [GET_COUNTRIES]({ commit }) {
      const { data } = await countries.index();
      commit(SET_COUNTRIES, data);
    },
    async [INIT_USER_INTERFACE]({ dispatch, commit }) {
      dispatch(INIT_EVENT_LISTENERS);
      await Promise.all([dispatch(GET_COUNTRIES)]);
      await feedback.getApiVersion().then((version) => {
        commit(SET_VERSION, version);
      });
    },
    [INIT_EVENT_LISTENERS]({ commit }) {
      commit(SET_WINDOW_SIZE, {
        width: window.innerWidth,
        height: window.innerHeight,
      });
      window.addEventListener('resize', () => {
        commit(SET_WINDOW_SIZE, {
          width: window.innerWidth,
          height: window.innerHeight,
        });
      });
      window.addEventListener('scroll', () => {
        commit(SET_WINDOW_SCROLL, {
          x: window.scrollX,
          y: window.scrollY,
        });
      });
    },
  },
};

export default uiStore;
