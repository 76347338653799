<template>
  <div>
    <template v-if="!resultFile && !previewResult">
      <CUpload
        v-model="rawFile"
        ref="upload"
        :label="label"
        :required="required"
        :multiple="false"
        accept="image/jpeg,image/png"
        @change="onChange"
      />
    </template>
    <div v-else class="relative inline-flex min-h-[4rem] border-2 border-dashed rounded">
      <img
        :class="previewImageClass"
        :src="previewResult"
      >
      <div class="absolute top-0 right-0 mt-2 mr-2">
        <CButton
          native-type="button"
          rounded
          size="small"
          :loading="resetLoading"
          @click="asyncReset ? runAsyncReset() : reset()"
        >
          {{ $t('chooseOtherImage') }}
        </CButton>
      </div>
    </div>
    <Portal to="overlays">
      <CModal
        v-model="cropModal"
        no-backdrop-close
        :submit-loading="submitLoading"
        :variant="modalVariant"
        @cancel="rawFile = null"
        @submit="onCropSubmit"
      >
        <Cropper
          v-if="!noCropper"
          ref="cropper"
          class="cropper"
          :class="previewImageClass"
          :src="cropFileURL"
          :stencil-props="{
            aspectRatio: 1/1,
            ...stencilProps,
          }"
        />
        <div v-else>
          <img
            :src="previewResult"
            class="w-full h-auto"
          >
        </div>
        <template v-slot:submit>
          {{ $t('save') }}
        </template>
      </CModal>
    </Portal>
  </div>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

export default {
  components: {
    Cropper,
  },

  model: {
    prop: 'value',
    event: 'change',
  },

  props: {
    value: {
      type: [File, String],
      default: null,
    },
    label: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    previewImageClass: {
      type: String,
      default: 'w-[400px]',
    },
    stencilProps: {
      type: Object,
      default: () => {},
    },
    modalVariant: {
      type: String,
      default: 'default',
    },
    noCropper: {
      type: Boolean,
      default: false,
    },
    asyncReset: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    rawFile: null,
    resultFile: null,
    previewResult: null,
    cropFileURL: null,
    cropModal: false,
    submitLoading: false,
    resetLoading: false,
  }),

  computed: {
    name() {
      return this.resultFile?.name || null;
    },
  },

  mounted() {
    this.$nextTick(() => {
      if (this.value && typeof this.value !== 'string') {
        this.previewResult = URL.createObjectURL(this.value);
        this.resultFile = this.value;
      } else if (this.value && typeof this.value === 'string') {
        this.previewResult = this.value;
      }
    });
  },

  methods: {
    onChange() {
      if (this.rawFile) {
        this.cropFileURL = URL.createObjectURL(this.rawFile);
        this.cropModal = true;
        if (this.noCropper) {
          this.previewResult = URL.createObjectURL(this.rawFile);
          this.$emit('change', this.resultFile);
        }
      }
    },
    emitFile() {
      this.$emit('change', this.resultFile);
    },
    onCropSubmit() {
      this.submitLoading = true;
      if (!this.noCropper) {
        const { canvas } = this.$refs.cropper.getResult();
        if (canvas) {
          canvas.toBlob(
            (blob) => {
              const resultAsFile = new File([blob], this.rawFile.name, {
                type: 'image/jpeg',
              });
              this.runResize(resultAsFile);
            },
            'image/jpeg',
            0.85,
          );
        }
      } else {
        this.resultFile = this.rawFile;
        this.cropModal = false;
        this.submitLoading = false;
        this.emitFile();
      }
    },
    runResize(file) {
      import(/* webpackChunkName: 'jimp' */ 'jimp').then((pack) => {
        const Jimp = pack.default;
        const url = URL.createObjectURL(file);
        Jimp.read(url).then(async (image) => {
          const resized = image.resize(600, 600).quality(100);
          const uint8 = await resized.getBufferAsync(resized._originalMime);
          const fileAsBlob = new Blob([uint8], { type: resized._originalMime });
          this.previewResult = URL.createObjectURL(fileAsBlob);
          this.resultFile = new File([fileAsBlob], this.rawFile.name, {
            type: 'image/jpeg',
          });
          this.cropModal = false;
          this.submitLoading = false;
          this.emitFile();
        });
      });
    },
    reset() {
      this.rawFile = null;
      this.resultFile = null;
      this.previewResult = null;
      this.cropFileURL = null;
      this.cropModal = false;
      this.submitLoading = false;
      this.$emit('reset');
    },
    runAsyncReset() {
      this.resetLoading = true;
      this.$emit('async-reset', {
        onSuccess: () => {
          this.resetLoading = false;
          this.reset();
        },
        onError: () => {
          this.resetLoading = false;
        },
      });
    },
  },
};
</script>
