export const toIntegerPrice = (price: number) => {
  return Number((price * 100).toFixed(0));
};

export const toDecimalPrice = (price: number) => {
  return Number((price / 100).toFixed(2));
};

export const toPriceInclTax = (priceInt: number, taxInt: number) => {
  return priceInt * (toDecimalPrice(taxInt) + 1);
};

export const toDecimalPriceInclTax = (priceInt: number, taxInt: number) => {
  return toDecimalPrice(toPriceInclTax(priceInt, taxInt));
};

export const getTaxSumFromInt = (priceInt: number, taxInt: number) => {
  return toPriceInclTax(priceInt, taxInt) - priceInt;
};
