<template>
  <div class="flex items-center" :class="{'my-4': !noSpacing}">
    <hr class="flex flex-1" :class="{'border-gray-300': !light}">
    <div v-if="$slots.default" class="flex flex-shrink-0 px-2">
      <CTypo tstyle="body" class="text-gray-500">
        <slot />
      </CTypo>
    </div>
    <hr class="flex flex-1" :class="{'border-gray-300': !light}">
  </div>
</template>

<script>
export default {
  props: {
    noSpacing: {
      type: Boolean,
      default: false,
    },
    light: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
