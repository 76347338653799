import { AxiosPromise } from 'axios';
import buildPaginationQuery from '../utils/buildPaginationQuery';
import type { TApiPaginationQuery, TApiPaginationResult } from '@contimo/types/src/Api';
import { TPlatformWaste } from './platformWastes';
import client from './client';

export type TMerchantWastePrice = {
  pricePerCubicMeter: number;
  minSize: number;
};

export type TMerchantWaste = {
  id: number;
  platformWasteId: number | null;
  customName: string | null;
  minSize: number | null;
  maxSize: number | null;
  minSizeRolloff: number | null;
  maxSizeRolloff: number | null;
  deleted: boolean;
  accepted: string[] | null;
  notAccepted: string[] | null;
  prices: TMerchantWastePrice[];
  platformWaste?: TPlatformWaste;
  image: string | null;
  basePrice: number;
  notes: Record<string, any>;
};

export type TMerchantWasteCreateBody = {
  platformWasteId: number | null;
  customName: string | null;
  prices: TMerchantWastePrice[];
  minSize: number | null;
  maxSize: number | null;
  minSizeRolloff: number | null;
  maxSizeRolloff: number | null;
  accepted: string[] | null;
  notAccepted: string[] | null;
  placeholder?: File | null;
  basePrice: number;
  notes: Record<string, any>;
};

export type TMerchantWasteUpdateBody = {
  platformWasteId?: number | null;
  customName?: string | null;
  prices?: TMerchantWastePrice[];
  minSize?: number | null;
  maxSize?: number | null;
  minSizeRolloff?: number | null;
  maxSizeRollff?: number | null;
  accepted?: string[] | null;
  notAccepted?: string[] | null;
  placeholder?: File | null;
  basePrice: number;
  notes: Record<string, any>;
};

const buildUrl = (path?: string | number) => {
  return path ? `/merchant-wastes/${path}` : '/merchant-wastes';
};

export default {
  index: (
    pagination: TApiPaginationQuery = {},
  ): AxiosPromise<TApiPaginationResult<TMerchantWaste[]>> => {
    return client.get(`${buildUrl()}${buildPaginationQuery(pagination)}`);
  },
  show: (id: number): AxiosPromise<TMerchantWaste> => client.get(buildUrl(id)),
  update: (id: number, updates: TMerchantWasteUpdateBody): AxiosPromise<TMerchantWaste> => {
    return client.patch(buildUrl(id), updates);
  },
  store: (body: TMerchantWasteCreateBody): AxiosPromise<TMerchantWaste> => {
    return client.post(buildUrl(), body);
  },
  destroy: (id: number): AxiosPromise => client.delete(buildUrl(id)),
  uploadPlaceholder: (id: number, file: File): AxiosPromise => {
    const formData = new FormData();
    formData.append('placeholder', file);
    return client.patch(`${buildUrl(id)}?uploadPlaceholder=1`, formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });
  },
};
