import type { Module } from 'vuex';
import type { TApiPaginationQuery, TPaginationMeta } from '@contimo/types/src/Api';
import { platformInvoices } from '@contimo/api/src/api';
import { IPlatformInvoice } from '@contimo/api/src/api/platformInvoices';
import type { TRootStore } from '@/store';
import { updateOrPushInArray } from '@/utils/store';
import { GET_PLATFORM_INVOICES } from '../actionTypes';
import { SET_PLATFORM_INVOICES } from '../mutationsTypes';

export interface IInvoicesStoreState {
  loading: boolean;
  invoices: IPlatformInvoice[];
  pagination: TPaginationMeta | null;
}

type TInvoicesStore = Module<IInvoicesStoreState, TRootStore>;

const invoicesStore: TInvoicesStore = {
  state: () => ({
    loading: false,
    invoices: [],
    pagination: null,
  }),

  getters: {},

  mutations: {
    [SET_PLATFORM_INVOICES](state, invoices: IPlatformInvoice[]) {
      invoices.forEach((invoice) => {
        updateOrPushInArray(state.invoices, invoice);
      });
    },
    setInvoicesPagination(state, meta: TPaginationMeta) {
      state.pagination = meta;
    },
    setInvoicesLoading(state, loading: boolean) {
      state.loading = loading;
    },
  },

  actions: {
    async [GET_PLATFORM_INVOICES]({ commit }, pagination: TApiPaginationQuery = {}) {
      commit('setInvoicesLoading', true);
      try {
        const { data } = await platformInvoices.index({
          sort: 'desc',
          ...pagination,
        });
        commit(SET_PLATFORM_INVOICES, data.data);
        commit('setInvoicesPagination', data.meta);
      } finally {
        commit('setInvoicesLoading', false);
      }
    },
  },
};

export default invoicesStore;
