export default (decimal: string | number, toGerman = false) => {
  if (toGerman && typeof decimal === 'number') {
    const string = decimal.toString().replace('.', ',');
    return string;
  }
  const string = decimal.toString().replace(/\./g, '').replace(',', '.');
  const number = parseFloat(string);
  return number;
};

export const germanDecimalToNumber = (decimal: string | number) => {
  const string =
    typeof decimal === 'string'
      ? decimal.toString().replace(/\./g, '').replace(',', '.')
      : decimal.toString();
  const number = parseFloat(string);
  return number;
};
