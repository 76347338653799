<template>
  <o-collapse
    :open="open"
    animation="none"
    root-class="border-t border-b py-3 my-4"
    @update:open="onToggle"
  >
    <div
      class="flex justify-between items-center"
      slot="trigger"
      role="button"
    >
      <CTypo tstyle="body2"><slot name="title" /></CTypo>
      <span
        class="inline-flex transform-gpu transition-transform duration-150"
        :class="{'rotate-180': open}"
      >
        <CIcon name="chevron-down" size="20" />
      </span>
    </div>
    <div class="pt-4 px-4">
      <div class="">
        <slot />
      </div>
    </div>
  </o-collapse>
</template>

<script>
export default {
  model: {
    prop: 'open',
    event: 'toggle',
  },

  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    onToggle(newVal) {
      this.$emit('toggle', newVal);
    },
  },
};
</script>
