<template>
  <div class="text-text flex-col flex justify-center items-center">
    <LoadingSpinner class="w-8 h-8" />
    <div class="mt-3">
      {{ $t('loading') }}...
    </div>
  </div>
</template>

<script>
import LoadingSpinner from './LoadingSpinner/index.vue';

export default {
  components: { LoadingSpinner },
};
</script>
