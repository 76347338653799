<template>
  <div>
    <div
      v-if="title || $slots.right"
      class="flex items-center px-4 sm:px-6 py-4 border-b"
      :class="{
        'justify-between': title,
        'justify-end': !title,
        'sm:py-5': !$slots.right,
      }"
    >
      <CTypo
        v-if="title"
        el="h3"
        tstyle="headline"
        class="mr-3"
      >
        {{ title }}
      </CTypo>
      <div>
        <slot name="right" />
      </div>
    </div>
    <dl class="px-4 sm:px-0 sm:divide-y sm:divide-gray-200">
      <slot />
    </dl>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null,
    },
  },
};
</script>
