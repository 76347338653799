<template>
  <div class="shadow-sm rounded-lg">
    <slot />
  </div>
</template>

<script>
export default {
  computed: {
    type: () => 'onboardingGroup',
  },
};
</script>
