<template>
  <component
    :is="el"
    class="bg-white shadow rounded-lg"
    :class="{
      'hover:shadow-lg cursor-pointer focus:ring-primary-2': clickable,
      'p-4 md:px-6': spacing,
    }"
  >
    <slot />
  </component>
</template>

<script>
export default {
  props: {
    clickable: {
      type: Boolean,
      default: false,
    },
    spacing: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    el() {
      if (this.clickable) return 'button';
      return 'div';
    },
  },
};
</script>
