import { AxiosPromise } from 'axios';
import client from './client';

export type TCountry = {
  id: number;
  name: string;
  short: string;
  translationSlug: string;
};

const buildUrl = (path?: string | number) => (path ? `/countries/${path}` : '/countries');

export default {
  index: (): AxiosPromise<TCountry[]> => client.get(buildUrl()),
  show: (id: number): AxiosPromise => client.get(buildUrl(id)),
  update: (id: number, updates: Record<string, any>) => client.patch(buildUrl(id), updates),
};
