import { AxiosPromise } from 'axios';
import buildPaginationQuery from '../utils/buildPaginationQuery';
import type { TApiPaginationQuery, TApiPaginationResult } from '@contimo/types/src/Api';
import client from './client';
import { TUser } from './users';

const buildUrl = (path?: string | number) => (path ? `/managers/${path}` : '/managers');

export type TManager = {
  id: number;
  merchantId: number;
  role: 'admin' | 'manager' | 'dispatcher' | 'driver';
  active: boolean;
  phone: string;
  alternativePhone: string | null;
  createdAt: string;
  updatedAt: string;
  user?: TUser;
};

export type TManagerUpdateBody = {
  role?: 'admin' | 'manager' | 'dispatcher' | 'driver';
  active?: boolean;
  phone?: string;
};

export default {
  index: (pagination: TApiPaginationQuery = {}): AxiosPromise<TApiPaginationResult<TManager[]>> => {
    return client.get(`${buildUrl()}${buildPaginationQuery(pagination)}`);
  },
  show: (id: number): AxiosPromise<TManager> => client.get(buildUrl(id.toString())),
  update: (id: number, body: TManagerUpdateBody): AxiosPromise<TManager> =>
    client.patch(buildUrl(id.toString()), body),
};
