<template>
  <OCheckbox v-model="checked" v-bind="{ ...$attrs }">
    <slot />
  </OCheckbox>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    checked: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>
