











import Vue, { PropType } from 'vue';
import textstyle, { TTextStyles } from './textstyle';

export default Vue.extend({
  name: 'CTypo',
  inheritAttrs: false,

  props: {
    el: {
      type: String as PropType<
        | 'div'
        | 'span'
        | 'h1'
        | 'h2'
        | 'h3'
        | 'h4'
        | 'h5'
        | 'h6'
        | 'a'
        | 'label'
        | 'router-link'
        | 'button'
      >,
      default: 'div',
    },
    tstyle: {
      type: String as PropType<TTextStyles>,
      default: 'body',
    },
    page: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    sizeClass(): string {
      if (this.page && textstyle.page[this.tstyle]) {
        return textstyle.page[this.tstyle];
      }
      if (this.page) {
        return textstyle.page.base;
      }
      if (textstyle.app[this.tstyle]) {
        return textstyle.app[this.tstyle];
      }
      return textstyle.app.base;
    },
  },
});
