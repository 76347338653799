<template>
  <div>
    <!-- <template v-if="searchField">
      <CTextField
        v-model="searchModel"
        :label="addressLabel"
        :placeholder="$t('address.searchAddress')"
      >
        <template v-slot:message>
          <button
            type="button"
            class="focus:outline-none text-primary underline focus:no-underline hover:text-gray-600"
            @click="searchField = false"
          >
            <CTypo tstyle="footnote2">{{ $t('address.useClassicInput') }}</CTypo>
          </button>
        </template>
      </CTextField>
    </template>
    <template v-else>
    <button
      type="button"
      class="focus:outline-none text-primary underline focus:no-underline hover:text-gray-600"
      @click="searchField = true"
    >
      <CTypo tstyle="footnote2">{{ $t('address.useSearch') }}</CTypo>
    </button> -->
    <div class="grid grid-cols-8 gap-x-4">
      <div class="col-span-8">
        <CSelect
          :value="address.countryShort"
          :label="$t('address.country')"
          disabled
          required
        >
          <option
            v-for="country in countries"
            :key="country.short"
            :value="country.short"
          >
            {{ $t(country.translationSlug) }}
          </option>
          <template v-slot:message>
            {{ $t('currentlyWeOfferServicesOnlyInGermany') }}
          </template>
        </CSelect>
      </div>
      <div class="col-span-6">
        <CTextField
          v-model="address.street"
          :label="$t('address.street')"
          required
        />
      </div>
      <div class="col-span-2">
        <CTextField
          v-model="address.number"
          :label="$t('address.number')"
          required
        />
      </div>
      <div class="col-span-4">
        <CTextField
          v-model="postalCodeSearchModel"
          :label="$t('address.postalCode')"
          required
        />
      </div>
      <div class="col-span-4">
        <CTextField
          v-model="address.city"
          :label="$t('address.city')"
          required
        />
      </div>
    </div>
    <!-- </template> -->
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true,
      validator: (value) => {
        if (value.street === undefined) return false;
        if (value.number === undefined) return false;
        if (value.postalCode === undefined) return false;
        if (value.city === undefined) return false;
        if (value.state === undefined) return false;
        if (value.countryShort === undefined) return false;
        return true;
      },
    },
    forBilling: {
      type: Boolean,
      default: false,
    },
    hideCompanyNameField: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    searchField: false,
    searchModel: null,
    postalCodeSearchModel: null,
    address: {
      street: null,
      number: null,
      postalCode: null,
      city: null,
      state: null,
      countryShort: 'DE',
      type: null,
    },
    optionalFields: {
      vat: null,
      companyName: null,
    },
  }),

  computed: {
    addressLabel() {
      if (this.forBilling) return this.$t('address.billingAddress');
      return this.$t('address.deliveryAddress');
    },
    countries() {
      return this.$store.state.ui.countries;
    },
  },

  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.address.street = val.street;
        this.address.number = val.number;
        this.address.postalCode = val.postalCode;
        this.address.city = val.city;
        this.address.state = val.state;
        this.address.type = 'delivery';
        if (this.forBilling) {
          this.address.type = 'billing';
        }
        this.optionalFields.vat = val.vat || null;
        this.optionalFields.companyName = val.companyName || null;
      },
    },
  },

  methods: {
    emit() {
      this.address.type = 'delivery';
      if (this.forBilling) {
        this.address.type = 'billing';
      }
      const { address } = this;
      if (this.type === 'billing') {
        address.vat = this.optionalFields.vat;
        address.companyName = this.optionalFields.companyName;
      }
      this.$emit('input', address);
    },
  },
};
</script>
