import type { Module } from 'vuex';
import type { TApiPaginationQuery, TPaginationMeta } from '@contimo/types/src/Api';
import { orders } from '@contimo/api/src/api';
import { IOrder } from '@contimo/api/src/api/orders';
import type { TRootStore } from '@/store';
import { updateOrPushInArray } from '@/utils/store';
import { SET_ORDERS, SET_ORDER, SET_ORDERS_LOADING } from '../mutationsTypes';
import { GET_ORDERS, GET_ORDER } from '../actionTypes';
import { ALL_ORDERS, CURRENT_ORDERS, CURRENT_ORDER, ORDERS_NEXT_PAGE } from '../gettersTypes';

export interface IOrdersStoreState {
  loading: boolean;
  orders: IOrder[];
  order: number | null;
  pagination: TPaginationMeta | null;
  singleLoading: boolean;
}

type TOrdersStore = Module<IOrdersStoreState, TRootStore>;

const ordersStore: TOrdersStore = {
  state: () => ({
    loading: false,
    singleLoading: false,
    orders: [],
    order: null,
    pagination: null,
  }),

  getters: {
    [CURRENT_ORDERS]: (state) =>
      state.orders
        ?.slice(0, 9)
        .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()),
    [CURRENT_ORDER]: (state) => state.orders.find((order) => order.id === state.order),
    [ALL_ORDERS]: (state) => state.orders,
    [ORDERS_NEXT_PAGE]: (state) => {
      return (state.pagination?.nextPageUrl && state.pagination.currentPage + 1) || 0;
    },
  },

  mutations: {
    [SET_ORDERS](state, data: IOrder[]) {
      data.forEach((value) => {
        updateOrPushInArray(state.orders, value);
      });
    },
    [SET_ORDER](state, data: IOrder | null) {
      if (data !== null) updateOrPushInArray(state.orders, data);
      state.order = data?.id || null;
    },
    [SET_ORDERS_LOADING](state, loading: boolean) {
      state.loading = loading;
    },
    setOrderSingleLoading(state, loading: boolean) {
      state.singleLoading = loading;
    },
    setOrdersPagination(state, meta: TPaginationMeta) {
      state.pagination = meta;
    },
  },

  actions: {
    async [GET_ORDERS]({ commit }, pagination: TApiPaginationQuery = {}) {
      commit(SET_ORDERS_LOADING, true);
      try {
        const { data } = await orders.index({
          sort: 'desc',
          ...pagination,
        });
        commit(SET_ORDERS, data.data);
        commit('setOrdersPagination', data.meta);
      } finally {
        commit(SET_ORDERS_LOADING, false);
      }
    },
    async [GET_ORDER]({ commit }, id: number) {
      commit(SET_ORDERS_LOADING, true);
      try {
        const { data } = await orders.show(id);
        commit(SET_ORDER, data);
      } finally {
        commit(SET_ORDERS_LOADING, false);
      }
    },
  },
};

export default ordersStore;
