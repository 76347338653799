<template>
  <div
    class="px-4 md:px-6 lg:px-8 border"
    :class="{
      'py-4 md:py-5': large,
      'py-3 md:py-4': !large,
      'bg-white': ['default', 'success'].includes(variant),
      'bg-gray-50': ['silent', 'warning'].includes(variant),
      'text-gray-900': variant !== 'disabled',
      'bg-gray-100 text-gray-400': variant === 'disabled',
      'first:mt-0 first:rounded-t-lg last:rounded-b-lg -mt-px': hasGroupParent,
      'rounded-lg mb-4 shadow-sm': !hasGroupParent,
    }"
  >
    <div
      class="justify-between items-center md:flex"
    >
      <div class="flex items-center mr-2 md:mr-3">
        <div
          v-if="icon"
          class="mr-2 md:mr-3 lg:mr-4"
          :class="{
            'text-green-600': variant === 'success',
            'text-yellow-600': variant === 'warning',
            'text-gray-300': ['default', 'disabled'].includes(variant),
          }"
        >
          <CIcon :name="icon" />
        </div>
        <CTypo v-if="large" el="h2" tstyle="title2">
          <slot />
        </CTypo>
        <CTypo
          v-else-if="small"
          el="div"
          tstyle="body"
          class="font-medium"
        >
          <slot />
        </CTypo>
        <CTypo v-else el="h3" tstyle="subheadline">
          <slot />
        </CTypo>
        <CLoadingDots v-if="loading" class="ml-4 h-4 text-gray-300" />
      </div>
      <div v-if="$slots.right" class="hidden md:inline-flex flex-shrink-0">
        <slot name="right" />
      </div>
    </div>
    <div v-if="$slots.description" class="mt-2 max-w-3xl">
      <CTypo :tstyle="small ? 'footnote2' : 'body'" class="text-gray-700">
        <slot name="description" />
      </CTypo>
    </div>
    <div v-if="$slots.right" class="flex md:hidden mt-2 justify-end">
      <slot name="right" />
    </div>
  </div>
</template>

<script>
const VARIANTS = ['default', 'warning', 'success', 'silent', 'disabled'];

export default {
  props: {
    variant: {
      type: String,
      default: 'default',
      validator: (value) => VARIANTS.includes(value),
    },
    large: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    hasGroupParent() {
      return this.$parent.type === 'onboardingGroup';
    },
  },
};
</script>
