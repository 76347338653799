var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex items-center flex-col md:flex-row-reverse w-full",class:{
    'md:w-auto': _vm.variant !== 'dialog',
  }},[_c('CButton',{staticClass:"w-full",class:{
      'md:w-auto': _vm.variant !== 'dialog',
      'flex-1': _vm.variant === 'dialog',
      'md:ml-2': (!_vm.noCancel || _vm.cancelText) && !_vm.hideCancel,
    },attrs:{"variant":_vm.submitVariant,"native-type":_vm.submitForm ? 'submit' : 'button',"form":_vm.submitForm,"loading":_vm.submitLoading,"disabled":_vm.submitIsDisabled},on:{"click":function($event){return _vm.$emit('submit')}}},[_vm._t("submit"),(!_vm.$slots.submit)?[_vm._v(" "+_vm._s(_vm.submitLabel)+" ")]:_vm._e(),(_vm.remainingCountdown)?_c('span',{staticClass:"ml-1 text-sm font-medium opacity-50 tabular-nums"},[_vm._v(" "+_vm._s(_vm.remainingCountdown)+" ")]):_vm._e()],2),((!_vm.noCancel || _vm.cancelText) && !_vm.hideCancel)?_c('CButton',{staticClass:"w-full mt-3 md:mt-0 md:mr-2",class:{
      'md:w-auto': _vm.variant !== 'dialog',
      'flex-1': _vm.variant === 'dialog',
    },attrs:{"variant":_vm.cancelVariant},on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._t("cancel"),(!_vm.$slots.cancel)?[_vm._v(" "+_vm._s(_vm.cancelLabel)+" ")]:_vm._e()],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }