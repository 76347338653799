var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('OModal',_vm._b({ref:"modal",attrs:{"active":_vm.show,"can-cancel":_vm.canCancel,"props":{
    variant: _vm.variant,
  },"id":_vm.id,"close-icon":null},on:{"close":_vm.onClose}},'OModal',Object.assign({}, _vm.$attrs),false),[(_vm.canCancel)?_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(_vm.$t('clickEscapeToClose')))]):_vm._e(),_c('div',{staticClass:"flex flex-col min-h-full"},[(_vm.$slots.header || _vm.$slots['header-right'])?_c('div',{staticClass:"px-4 md:px-6",class:{
        'sticky top-0 py-3 md:py-0 md:h-14 bg-white shadow-sm border-b lg:px-8 z-20':
          _vm.variant !== 'dialog',
        'py-4 md:py-6': _vm.variant === 'dialog'
      }},[_c('div',{staticClass:"flex flex-wrap items-center h-full justify-between ",class:_vm.headerClass},[_c('div',{staticClass:"flex items-center flex-1"},[(_vm.leftCloseButton)?_c('div',{staticClass:"inline-flex items-center mr-1 md:-ml-2 lg:-ml-3 pr-2"},[_c('button',{staticClass:"focus:outline-none rounded-full w-8 h-8 inline-flex\n              justify-center items-center hover:bg-gray-200 transition duration-150\n              text-gray-800 focus:ring-primary-2",on:{"click":_vm.close}},[_c('CIcon',{attrs:{"name":"x","size":"20"}}),_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(_vm.$t('close')))])],1),_c('div',{staticClass:"border-r w-px h-6 ml-1"})]):_vm._e(),(_vm.headerIcon)?_c('CIcon',{staticClass:"mr-2",class:{
              'text-gray-500': _vm.submitVariant !== 'danger',
              'text-red-600': _vm.submitVariant === 'danger',
            },attrs:{"name":_vm.headerIcon,"size":"32"}}):_vm._e(),_c('CTypo',{attrs:{"tstyle":"title3"}},[_vm._t("header")],2)],1),(_vm.$slots['header-center'])?_c('div',{staticClass:"order-3 w-full md:w-auto md:flex md:flex-shrink md:order-2\n          items-center mt-2 md:mt-0"},[_vm._t("header-center")],2):_vm._e(),(_vm.$slots['header-right'] || _vm.$slots['header-center'] || _vm.closeButton)?_c('div',{staticClass:"flex items-center justify-end order-2 md:order-3",class:{
            'md:flex-1': _vm.$slots['header-center'],
            'flex-shrink': !_vm.$slots['header-center'],
          }},[_vm._t("header-right"),(_vm.closeButton)?_c('div',{staticClass:"inline-flex ml-1 md:-mr-2 lg:-mr-3"},[_c('button',{staticClass:"focus:outline-none rounded-full w-8 h-8 inline-flex\n              justify-center items-center hover:bg-gray-200 transition duration-150\n              text-gray-800 focus:ring-primary-2",on:{"click":_vm.close}},[_c('CIcon',{attrs:{"name":"x","size":"20"}}),_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(_vm.$t('close')))])],1)]):_vm._e()],2):_vm._e()])]):_vm._e(),(_vm.$slots.default)?_c('div',{staticClass:"px-4 md:px-6",class:[{
        'py-4 md:py-6 lg:py-8 lg:px-8 flex-grow': _vm.variant !== 'dialog',
        'py-0 pb-3 max-h-300px': _vm.variant === 'dialog',
      }, _vm.contentClass]},[_vm._t("default")],2):(_vm.$scopedSlots.content)?_c('div',{ref:"content",staticClass:"flex-1 flex-grow",class:_vm.contentClass},[_c('CResizeObserver',{attrs:{"only-inline":""},on:{"resize-inline":_vm.getContentHeight}}),_vm._t("content",null,null,{ height: _vm.fullContentHeight })],2):_vm._e(),(_vm.$slots.footer || _vm.$slots['footer-right'] || !_vm.customFooter)?_c('div',{staticClass:"flex items-center px-4 md:px-6 py-3",class:[_vm.footerClass, {
        'sticky bottom-0 bg-white border-t lg:px-8 z-20': _vm.variant !== 'dialog',
        'py-4 md:py-6': _vm.variant === 'dialog',
        'justify-between': _vm.customFooter,
        'justify-start md:justify-end': !_vm.customFooter,
        'pb-safe-area-large': _vm.variant !== 'dialog' && _vm.variant !== 'default',
      }]},[(_vm.customFooter)?_c('div',[_vm._t("footer")],2):_vm._e(),(_vm.customFooter)?_c('div',[_vm._t("footer-right")],2):_vm._e(),(!_vm.customFooter || _vm.submitForm)?_c('Buttons',_vm._b({class:{
          'ml-2': _vm.customFooter,
        },attrs:{"variant":_vm.variant,"no-cancel":_vm.noCancel,"submit-form":_vm.submitForm,"submit-loading":_vm.submitLoading,"submit-variant":_vm.submitVariant,"submit-disabled":_vm.submitDisabled,"submit-countdown":_vm.submitCountdown},on:{"cancel":_vm.onCancel,"submit":function($event){return _vm.$emit('submit')}},scopedSlots:_vm._u([(_vm.$slots.submit)?{key:"submit",fn:function(){return [_vm._t("submit")]},proxy:true}:null,(_vm.$slots.cancel)?{key:"cancel",fn:function(){return [_vm._t("cancel")]},proxy:true}:null],null,true)},'Buttons',Object.assign({}, _vm.buttonProps),false)):_vm._e()],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }