<template>
  <div :id="id" class="stack-router">
    {{ activeChilds }}
    <slot
      v-bind="{
        push,
        pop,
      }"
    />
    <div
      class="stack-router-transparent-backdrop"
      :class="{
        'is-shown': activeChilds.length > 0,
      }"
      @click="pop"
    />
    <div
      class="stack-router-backdrop"
      :class="{
        'is-shown': activeChilds.length > 0,
        'is-hidden': hideBackdrop,
      }"
    />
  </div>
</template>

<script>
import objectid from '../../utils/objectid';

export default {
  props: {
    id: {
      type: String,
      default: () => objectid('sr'),
    },
    query: {
      type: String,
      default: null,
    },
    param: {
      type: String,
      default: null,
    },
    backdropClose: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    activeChilds: [],
    hideBackdrop: false,
  }),

  computed: {
    currentRouteInfo() {
      if (this.query) return this.$route.query[this.query];
      if (this.param) return this.$route.params[this.param];
      return null;
    },
  },

  watch: {
    activeChilds(newVal) {
      if (newVal.length > 0) {
        this.hideBackdrop = false;
      } else {
        setTimeout(() => {
          this.hideBackdrop = true;
        }, 300);
      }
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.toggleRouteChilds(this.currentRouteInfo);
    });
  },

  methods: {
    closeAll() {
      if (this.backdropClose) {
        this.activeChilds.forEach((id) => {
          this.toggleChild(id, 'pop');
        });
      }
    },
    buildQuery() {
      let query = '';
      this.activeChilds.forEach((childId, index) => {
        if (index === 0) {
          query += `${childId}`;
        } else {
          query += `,${childId}`;
        }
      });
      return query;
    },
    toggleRouteChilds(routeInfo) {
      if (this.query) {
        this.activeChilds = routeInfo?.split(',') || [];
        this.activeChilds.forEach((id) => {
          this.toggleChild(id, 'init');
        });
      } else if (this.param) {
        this.toggleChild(routeInfo, 'init');
        this.activeChilds = [routeInfo];
      }
    },
    toggleChild(id, mode) {
      this.$children.forEach((child, index) => {
        if (child.id === id) {
          if (this.query && mode === 'push') this.activeChilds.push(id);
          if (this.param && mode === 'push') this.activeChilds = [id];
          if (mode === 'init') {
            this.$children[index].open();
          } else {
            this.$children[index].toggle();
          }
        }
      });
      if (mode === 'pop') {
        this.activeChilds.pop();
      }
      if (this.query && this.activeChilds.length <= 0) {
        this.$router.replace({ query: { [this.query]: undefined } });
      } else if (this.query && this.buildQuery() !== this.currentRouteInfo) {
        this.$router.replace({ query: { [this.query]: this.buildQuery() } });
      }
    },
    push(id) {
      this.toggleChild(id, 'push');
    },
    pop() {
      this.toggleChild(this.activeChilds[this.activeChilds.length - 1], 'pop');
    },
    inForeground(id) {
      if (this.activeChilds.length > 0) {
        return this.activeChilds[this.activeChilds.length - 1] === id;
      }
      return false;
    },
    inBackground(id) {
      if (this.activeChilds.length > 1) {
        return this.activeChilds[this.activeChilds.length - 2] === id;
      }
      return false;
    },
    isHidden(id) {
      if (this.activeChilds.length > 2) {
        return !this.isFirst(id) && !this.isBackground(id);
      }
      return false;
    },
  },
};
</script>
