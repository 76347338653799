export default (decimal: string | number, toGerman = false) => {
  if (toGerman && typeof decimal === 'number') {
    const string = decimal.toString().replace('.', ',');
    return string;
  }
  if (decimal) {
    const string = decimal.toString().replace(/\./g, '').replace(',', '.');
    const number = parseFloat(string);
    return number;
  }
  return decimal;
};

const replaceRadix = (decimalStr: string) => {
  return decimalStr.toString().replace(/\./g, '').replace(',', '.');
};

export const germanDecimalToNumber = (decimal: string | number) => {
  // console.log('DECIMAL', decimal);
  const string = typeof decimal === 'string' ? replaceRadix(decimal) : decimal.toString();
  const number = parseFloat(string);
  // console.log('NUMBER', number);
  return number;
};
