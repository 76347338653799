var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"stack-router",attrs:{"id":_vm.id}},[_vm._v(" "+_vm._s(_vm.activeChilds)+" "),_vm._t("default",null,null,{
      push: _vm.push,
      pop: _vm.pop,
    }),_c('div',{staticClass:"stack-router-transparent-backdrop",class:{
      'is-shown': _vm.activeChilds.length > 0,
    },on:{"click":_vm.pop}}),_c('div',{staticClass:"stack-router-backdrop",class:{
      'is-shown': _vm.activeChilds.length > 0,
      'is-hidden': _vm.hideBackdrop,
    }})],2)}
var staticRenderFns = []

export { render, staticRenderFns }