import Vue from 'vue';

const modalEventBus = new Vue();

class ModalService {
  currentStack: string[] = [];

  events: Vue;

  constructor() {
    this.events = modalEventBus;
    this.addEventListeners();
  }

  // eslint-disable-next-line no-unused-vars
  $on(event: string, callback: (args: any) => void) {
    this.events.$on(event, (e: any) => callback(e));
  }

  // eslint-disable-next-line no-unused-vars
  $off(event: string, callback: (args: any) => void) {
    this.events.$off(event, (e: any) => callback(e));
  }

  $emit(event: string, payload: any) {
    this.events.$emit(event, payload);
  }

  onModalOpen(id: string) {
    this.currentStack.unshift(id);
  }

  onModalClose(id: string) {
    const index = this.currentStack.findIndex((modal) => modal === id);
    if (index > -1) {
      this.currentStack.splice(index, 1);
    }
  }

  addEventListeners() {
    document.addEventListener('keydown', (e) => {
      let isEscape = false;
      if (e.key) {
        isEscape = e.key === 'Escape' || e.key === 'esc';
      } else if (e.keyCode) {
        isEscape = e.keyCode === 27;
      }
      if (isEscape && this.currentStack.length > 0) {
        e.preventDefault();
        this.$emit('esc', this.currentStack[0]);
      }
    });
  }

  removeEventListeners() {}
}

export default new ModalService();
