import type { Module } from 'vuex';
import { pages } from '@contimo/api/src/api';
import { TPage, TPageUpdateBody } from '@contimo/api/src/api/pages';
import type { TRootStore } from '@/store';
import { updateOrPushInArray } from '@/utils/store';
import { CREATE_PAGE, GET_PAGE, GET_PAGES, UPDATE_PAGE } from '../actionTypes';
import {
  SET_PAGE,
  SET_PAGES,
  SET_PAGES_LOADING,
  SET_PAGE_LOADING,
  SET_PAGE_SUBMIT_LOADING,
} from '../mutationsTypes';
import { LEGAL_PAGES } from '../gettersTypes';

export interface IPagesStoreState {
  loading: boolean;
  singleLoading: boolean;
  submitLoading: boolean;
  pages: TPage[];
}

type TPagesStore = Module<IPagesStoreState, TRootStore>;

const legalSlugs: string[] = ['impressum', 'datenschutz', 'agb', 'widerruf'];

const pagesStore: TPagesStore = {
  state: () => ({
    loading: false,
    singleLoading: false,
    submitLoading: false,
    pages: [],
  }),

  getters: {
    [LEGAL_PAGES]: (state) => state.pages.filter((page) => legalSlugs.includes(page.slug)),
  },

  mutations: {
    [SET_PAGE](state, page: TPage) {
      updateOrPushInArray(state.pages, page);
    },
    [SET_PAGES](state, pages: TPage[]) {
      pages.forEach((page) => {
        updateOrPushInArray(state.pages, page);
      });
    },
    [SET_PAGE_LOADING](state, loading: boolean) {
      state.singleLoading = loading;
    },
    [SET_PAGES_LOADING](state, loading: boolean) {
      state.loading = loading;
    },
    [SET_PAGE_SUBMIT_LOADING](state, loading: boolean) {
      state.submitLoading = loading;
    },
  },

  actions: {
    async [GET_PAGES]({ commit }) {
      commit(SET_PAGES_LOADING, true);
      try {
        const { data } = await pages.index();
        commit(SET_PAGES, data);
      } finally {
        commit(SET_PAGES_LOADING, false);
      }
    },
    async [GET_PAGE]({ commit }, id: number) {
      commit(SET_PAGE_LOADING, true);
      try {
        const { data } = await pages.get(id);
        commit(SET_PAGE, data);
      } finally {
        commit(SET_PAGE_LOADING, false);
      }
    },
    async [CREATE_PAGE]() {},
    async [UPDATE_PAGE]({ commit }, payload: TPageUpdateBody & { id: number }) {
      commit(SET_PAGE_SUBMIT_LOADING, true);
      try {
        const { id, ...body } = payload;
        const { data } = await pages.update(id, body);
        commit(SET_PAGE, data);
      } finally {
        commit(SET_PAGE_SUBMIT_LOADING, false);
      }
    },
  },
};

export default pagesStore;
