<template>
  <OField
    :variant="currentVariant"
    :label-for="id"
    :label-size="size"
    :label="label ? `${label}${required ? '*' : ''}` : null"
    :class="{
      'has-rounded-field': rounded,
    }"
    :message="message"
  >
    <template v-if="$slots.label" v-slot:label>
      <slot name="label" />
    </template>
    <div
      v-if="$slots['leading-addon']"
      ref="leadingAddon"
      class="z-5 absolute left-px top-px bottom-px text-sm bg-gray-100 rounded-l-md border-r
      text-gray-600 flex items-center px-2 md:px-3 select-none pointer-events-none"
    >
      <span class="inline-flex">
        <slot name="leading-addon" />
      </span>
    </div>
    <OInput
      v-if="!autocompleteComponent"
      :id="id"
      v-bind="{ ...$attrs }"
      ref="input"
      :value="mask ? valueModel : value"
      :use-html5-validation="false"
      :required="required"
      :placeholder="placeholder ? `${placeholder}${required && !label ? '*' : ''}` : null"
      :size="size"
      :rounded="rounded"
      :disabled="disabled"
      :inputmode="currentInputmode"
      novalidate
      :input-class="inputClass"
      :type="type"
      :expanded="fieldExpanded"
      @input="onInput"
      @change="onChange"
      @blur="onBlur"
      @focus="onFocus"
      @keydown="onKeydown"
    />
    <OAutocomplete
      v-else
      v-bind="{ ...$attrs }"
      ref="input"
      :id="id"
      :value="value"
      :use-html5-validation="false"
      :required="required"
      :placeholder="placeholder ? `${placeholder}${required && !label ? '*' : ''}` : null"
      :size="size"
      :rounded="rounded"
      :disabled="disabled"
      :inputmode="currentInputmode"
      :menu-class="autocompleteMenuClass"
      novalidate
      :input-class="inputClass"
      @typing="onAutocompleteTyping"
      @select="onItemSelect"
      @input="onInput"
      @blur="onBlur"
      @focus="onFocus"
    >
      <template v-slot="props">
        <slot v-bind="props" />
      </template>
      <template v-if="$slots.empty" v-slot:empty>
        <slot name="empty" />
      </template>
    </OAutocomplete>
    <div
      v-if="$slots['trailing-addon']"
      ref="trailingAddon"
      class="absolute right-px top-px bottom-px text-sm bg-gray-100 rounded-r-md border-l
      text-gray-600 flex items-center px-2 md:px-3 select-none pointer-events-none z-2"
    >
      <span class="inline-flex">
        <slot name="trailing-addon" />
      </span>
    </div>
    <template v-slot:message>
      <template v-if="currentMessage">
        {{ currentMessage }}
      </template>
      <slot v-else name="message" />
    </template>
    <slot name="custom" />
  </OField>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import { FIELD_VARIANTS, FIELD_SIZES } from '../../../constants/sharedComponents';
import objectid from '../../../utils/objectid';
import germanDecimalParser from '../../../utils/germanDecimalParser';

export default defineComponent({
  name: 'CTextField',
  inheritAttrs: false,

  props: {
    variant: {
      type: String,
      default: 'default',
      validator: (value) => FIELD_VARIANTS.includes(value),
    },
    id: {
      type: String,
      default: () => objectid('field-'),
    },
    label: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    message: {
      type: String,
      default: null,
    },
    value: {
      type: [String, Number],
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dontValidate: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'medium',
      validator: (value) => FIELD_SIZES.includes(value),
    },
    customError: {
      type: [String, Boolean],
      default: null,
    },
    mask: {
      type: Object,
      default: null,
    },
    inputmode: {
      type: String,
      default: null,
    },
    autocompleteComponent: {
      type: Boolean,
      default: false,
    },
    autocompleteMenuClass: {
      type: String,
      default: 'max-h-[250px]',
    },
    inputClass: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'text',
    },
    selectAllOnFocus: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    validationError: false,
    newValidity: null,
    oldValidity: null,
    valueModel: null,
    Inputmask: null,
    isFocused: false,
  }),

  computed: {
    currentVariant() {
      if (this.customError) return 'danger';
      if (this.validationError) return 'danger';
      return this.variant;
    },
    currentMessage() {
      if (this.customError) return this.customError;
      if (this.validationError) return this.validationError;
      return this.message;
    },
    currentInputmode() {
      if (this.mask?.inputmode) return this.mask.inputmode;
      return this.inputmode;
    },
    inputEl() {
      return this.$el.querySelector(`#${this.id}`);
    },
    fieldExpanded() {
      if (this.$slots['leading-addon'] || this.$slots['trailing-addon']) {
        return true;
      }
      return false;
    },
    fieldPaddingRight() {
      if (this.$slots['trailing-addon'] && this.$refs.trailingAddon) {
        return this.$refs.trailingAddon.offsetWidth + 16;
      }
      return null;
    },
    fieldPaddingLeft() {
      if (this.$slots['leading-addon'] && this.$refs.leadingAddon) {
        return this.$refs.leadingAddon.offsetWidth + 16;
      }
      return null;
    },
  },

  watch: {
    value(newVal) {
      if (
        this.Inputmask &&
        (!this.valueModel || newVal !== this.Inputmask.unmask(this.valueModel, this.mask))
      ) {
        this.valueModel = newVal;
      }
      if (this.validationError) {
        this.$nextTick(() => {
          this.checkValidity();
        });
      }
    },
    mask(newVal) {
      if (this.Inputmask && !this.isFocused) {
        this.Inputmask(newVal).mask(this.$el.querySelector('input'));
        this.$nextTick(() => {
          this.onInput(this.inputEl.value);
        });
      }
    },
    customError(newVal) {
      if (newVal) {
        this.inputEl.setCustomValidity(newVal);
      } else {
        this.inputEl.setCustomValidity('');
      }
    },
  },

  mounted() {
    this.$nextTick(() => {
      if (this.mask) {
        import(/* webpackChunkName: "inputmask" */ 'inputmask').then((value) => {
          this.Inputmask = value.default;
          this.Inputmask(this.mask).mask(this.$el.querySelector('input'));
          if (this.mask.germanDecimal && this.value && typeof this.value === 'number') {
            this.valueModel = germanDecimalParser(this.value, true);
          } else {
            this.valueModel = this.value;
          }
          this.inputEl.addEventListener('paste', () => {
            this.$nextTick(() => {
              this.onInput(this.inputEl.value);
            });
          });
          this.$nextTick(() => {
            this.$emit('mask-ready');
          });
        });
      }
      this.inputEl.addEventListener('invalid', (e) => {
        e.preventDefault();
        this.setValidationMessage();
      });
      this.checkPaddingAddons();
    });
  },

  updated() {
    this.$nextTick(() => {
      this.checkPaddingAddons();
    });
  },

  beforeDestroy() {
    this.inputEl.removeEventListener('invalid', () => {});
    if (this.mask) this.inputEl.removeEventListener('paste', () => {});
  },

  methods: {
    onInput(value) {
      if (this.validationError) {
        this.checkValidity();
      }
      if (this.mask) {
        this.valueModel = value;
        this.$emit('input', this.Inputmask.unmask(value, this.mask));
      } else {
        this.$emit('input', value);
      }
    },
    onChange() {
      if (this.validationError) {
        this.checkValidity();
      }
    },
    onBlur(e) {
      this.checkValidity();
      this.isFocused = false;
      this.$emit('blur', e);
    },
    onFocus(e) {
      this.isFocused = true;
      this.$emit('focus', e);
      if (this.selectAllOnFocus) {
        this.inputEl.select();
      }
    },
    onKeydown(e) {
      this.$emit('keydown', new KeyboardEvent('keydown', e));
    },
    focus() {
      this.inputEl.focus();
    },
    blur() {
      this.inputEl.blur();
    },
    onItemSelect(data) {
      this.$emit('select', data);
    },
    onAutocompleteTyping(value) {
      this.$emit('typing', value);
    },
    checkValidity() {
      if (!this.dontValidate) {
        const fieldValidity = this.inputEl.checkValidity();
        if (!fieldValidity) {
          this.setValidationMessage();
        } else {
          this.validationError = false;
        }
      }
    },
    setValidationMessage() {
      const { validationMessage } = this.inputEl;
      this.validationError = validationMessage;
    },
    checkPaddingAddons() {
      let style = '';
      if (this.fieldPaddingRight) {
        style += `padding-right: ${this.fieldPaddingRight}px;`;
      }
      if (this.fieldPaddingLeft) {
        style += `padding-left: ${this.fieldPaddingLeft}px`;
      }
      this.inputEl.style = style;
    },
  },
});
</script>
