export type TTextStyles =
  | 'largeTitle'
  | 'title1'
  | 'title2'
  | 'title3'
  | 'headline'
  | 'subheadline'
  | 'body'
  | 'body2'
  | 'footnote'
  | 'footnote2'
  | 'caption'
  | 'caption2';

const textStyles: {
  app: Record<string, string>;
  page: Record<string, string>;
} = {
  app: {
    largeTitle: 'text-3xl font-bold',
    title1: 'text-2xl font-bold',
    title2: 'text-xl font-bold',
    title3: 'text-lg font-semibold',
    headline: 'text-base font-bold',
    subheadline: 'text-base font-semibold',
    body: 'text-sm',
    body2: 'text-sm font-medium',
    footnote1: 'text-xs font-medium',
    footnote2: 'text-xs',
    caption1: 'uppercase text-xs font-semibold tracking-wider',
    caption2: 'uppercase text-xs font-medium tracking-wide',
  },
  page: {
    largeTitle: 'text-4xl font-bold',
    title1: 'text-4xl font-bold',
    title2: 'text-2xl font-bold',
    title3: 'text-xl font-semibold',
    headline: 'text-lg font-bold',
    subheadline: 'text-lg font-semibold',
    body: 'text-base',
    body2: 'text-base font-medium',
    footnote1: 'text-sm font-medium',
    footnote2: 'text-xs',
    caption1: 'uppercase text-sm font-medium tracking-wide',
    caption2: 'uppercase text-xs font-semibold tracking-wide',
  },
};

export default textStyles;
