export const IS_ADMIN = 'isAdmin';
export const IS_CUSTOMER = 'isCustomer';
export const IS_MANAGER = 'isManager';
export const THIS_USER = 'thisUser';

export const IS_AUTH_LOADING = 'isAuthLoading';
export const IS_LOGIN_LOADING = 'isLoginLoading';
export const HAS_LOGIN_ERROR = 'hasLoginError';
export const AUTH_IS_READY = 'authIsReady';
export const ROUTE_AFTER_LOGIN = 'routeAfterLogin';
export const ACCESS_TOKEN = 'accessToken';

export const APP_IS_LOADING = 'appIsLoading';
export const WINDOW_SIZE = 'windowSize';
export const WINDOW_HEIGHT = 'windowHeight';
export const WINDOW_WIDTH = 'windowWidth';
export const WINDOW_SCROLL = 'windowScroll';
export const SUCESS_NOTIFICATION = 'successNotification';
export const ERROR_NOTIFICATION = 'errorNotification';
export const APP_VERSION = 'appVersion';

export const THIS_MERCHANT = 'thisMerchant';
export const NEEDS_ONBOARDING = 'needsOnboarding';

export const CURRENT_POSTAL_CODE_RESULTS = 'currentPostalCodeResults';

export const UNSAVED_DELIVERY_AREAS = 'unsavedDeliveryAreas';
export const ALL_DELIVERY_AREAS = 'allDeliveryAreas';

export const ALL_PLATFORM_WASTES = 'allPlatformWastes';
export const ALL_MERCHANT_WASTES = 'allMerchantWastes';

export const ALL_PRODUCTS = 'allProducts';
export const CONTAINER_PRODUCTS = 'containerProducts';
export const DELETED_CONTAINER_PRODUCTS = 'deletedContainerProducts';

export const WAIT_FOR_SHOP_ACTIVATION = 'waitForShopActivation';
export const THIS_SHOP = 'thisShop';
export const SHOP_URL = 'shopUrl';

export const ALL_MANAGER_INVITES = 'allManagerInvites';

export const ALL_USERS = 'allUsers';

export const ALL_MANAGERS = 'allManagers';
export const CURRENT_MANAGER = 'currentManager';

export const ALL_ORDERS = 'allOrders';
export const CURRENT_ORDERS = 'currentOrders';
export const CURRENT_ORDER = 'currentOrder';
export const ORDERS_NEXT_PAGE = 'ordersNextPage';

export const ALL_SHOP_CERTIFICATES = 'allShopCertificates';
export const CURRENT_SHOP_CERTIFICATE = 'currentShopCertificate';

export const LEGAL_PAGES = 'legalPages';
