<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :class="sizeClass"
    :fill="isOutlineVariant ? 'none' : 'currentColor'"
    :viewBox="isOutlineVariant ? '0 0 24 24' : '0 0 20 20'"
    :stroke="isOutlineVariant ? 'currentColor' : 'none'"
  >
    <div class="sr-only">{{ currentIconName }}</div>
    <template v-if="isOutlineVariant">
      <path
        v-for="(path, index) in paths"
        :key="`${name}-outline_${index}`"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        :d="path"
      />
    </template>
    <template v-else>
      <path
        v-for="(path, index) in paths"
        :key="`${name}_${index}`"
        fill-rule="evenodd"
        clip-rule="evenodd"
        :d="path"
      />
    </template>
  </svg>
</template>

<script>
import icons, { orugaIconMapper } from './icons';

export default {
  props: {
    name: {
      type: String,
      default: null,
    },
    // oruga icon
    icon: {
      type: Array,
      default: null,
    },
    outline: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: '24',
    },
  },

  computed: {
    currentIconName() {
      let { name } = this;
      if (this.icon) {
        name = orugaIconMapper(this.icon[1]);
      }
      return name || '';
    },
    isOutlineVariant() {
      return this.outline || this.currentIconName.includes('outline');
    },
    paths() {
      if (this.outline && this.currentIconName) {
        // @ts-ignore
        return icons[`${this.currentIconName}-outline`];
      }
      // @ts-ignore
      if (this.currentIconName) return icons[this.currentIconName];
      return [];
    },
    sizeClass() {
      return {
        'w-3 h-3': this.size === '12',
        'w-4 h-4': this.size === '16',
        'w-5 h-5': this.size === '20',
        'w-6 h-6': this.size === '24',
        'w-8 h-8': this.size === '32',
        'w-9 h-9': this.size === '36',
        'w-10 h-10': this.size === '40',
      };
    },
  },
};
</script>
