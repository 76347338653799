import { AxiosPromise } from 'axios';
import buildPaginationQuery from '../utils/buildPaginationQuery';
import type { TApiPaginationQuery, TApiPaginationResult } from '@contimo/types/src/Api';
import client from './client';

export type TManagerInvite = {
  id: number;
  email: string;
  role: 'admin' | 'manager' | 'dispatcher' | 'driver';
  phone: string;
  merchantId: number;
  createdAt: string;
  updatedAt: string;
};

export type TManagerInviteCreateBody = {
  email: string;
  role: 'admin' | 'manager' | 'dispatcher' | 'driver';
  phone: string;
};

const buildUrl = (path?: string) => (path ? `/manager-invites/${path}` : '/manager-invites');

export default {
  index: (
    pagination: TApiPaginationQuery = {},
  ): AxiosPromise<TApiPaginationResult<TManagerInvite[]>> => {
    return client.get(`${buildUrl()}${buildPaginationQuery(pagination)}`);
  },
  show: (id: number): AxiosPromise<TManagerInvite> => {
    return client.get(buildUrl(id.toString()));
  },
  store: (payload: TManagerInviteCreateBody): AxiosPromise<TManagerInvite> => {
    return client.post(buildUrl(), payload);
  },
  resendInvite: (id: number): AxiosPromise => {
    return client.put(`${buildUrl(id.toString())}/resend`);
  },
  destroy: (id: number): AxiosPromise => {
    return client.delete(`${buildUrl(id.toString())}`);
  },
};
