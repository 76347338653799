<template>
  <router-link
    v-slot="{ isExactActive, href, navigate }"
    :to="to"
    custom
  >
    <a
      :href="href"
      class="flex w-full px-3 py-2 rounded-lg font-medium transition duration-150 bg-primary"
      :class="{
        'bg-opacity-10 text-primary': isExactActive,
        'text-gray-700 bg-opacity-0 hover:bg-opacity-10 hover:text-primary': !isExactActive,
      }"
      @click="navigate"
    >
      <CIcon
        v-if="icon"
        :name="icon"
        size="16"
        class="mr-2"
      />
      <CTypo
        el="div"
        tstyle="body"
      >
        <slot />
      </CTypo>
    </a>
  </router-link>
</template>

<script>
export default {
  props: {
    to: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: null,
    },
  },
};
</script>
