<template>
  <div class="relative z-50">
    <CModal
      :show="value"
      variant="dialog"
      @close="$emit('input', false)"
      custom-footer
    >
      <template v-slot:header>
        <CTypo tstyle="title3">{{ $t('errorNotification.unexpcetedError') }}</CTypo>
      </template>

      <CMessage variant="danger">
        {{ $t('errorNotification.description') }}
      </CMessage>

      <template v-slot:footer>
        <CButton 
          variant="primary" 
          @click="$emit('input', false)"
        >
          {{ $t('ok') }}
        </CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
    },
  },
};
</script>
