import { AxiosPromise } from 'axios';
import buildPaginationQuery from '../utils/buildPaginationQuery';
import type { TApiPaginationQuery, TApiPaginationResult } from '@contimo/types/src/Api';
import client from './client';

export interface IPlatformInvoice {
  id: number;
  merchantId: number;
  number: string;
  total: number;
  filePath: string;
  createdAt: string;
  updatedAt: string;
}

const buildUrl = (path?: string | number) => {
  return path ? `/platform-invoices/${path}` : '/platform-invoices';
};

export default {
  index: (
    pagination: TApiPaginationQuery = {},
  ): AxiosPromise<TApiPaginationResult<IPlatformInvoice[]>> => {
    return client.get(`${buildUrl()}${buildPaginationQuery(pagination)}`);
  },
  show: (id: number): AxiosPromise<{ url: string }> => client.get(buildUrl(id)),
};
