<template>
  <div
    :id="id"
    class="fixed bottom-0 md:top-0 md:bottom-auto right-0 h-85vh md:min-h-stretch
    bg-white transform shadow-md transition-all duration-200 w-full
    md:w-90vw md:max-w-4xl rounded-t-lg md:rounded-l-lg md:rounded-tr-none"
    :class="{
      'translate-y-full md:translate-x-full md:translate-y-0 -mr-3': !show,
      'translate-y-0 md:translate-x-0': show,
      'z-40': !hideBackdrop,
    }"
  >
    <div class="h-full overflow-y-auto">
      <div v-if="$slots.header" class="sticky top-0 left-0 w-full">
        <slot name="header" />
      </div>
      <div>
        <slot />
      </div>
      <div v-if="$slots.footer" class="sticky bottom-0 w-full left-0">
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script>
import objectid from '../../utils/objectid';

export default {
  model: {
    prop: 'show',
    event: 'toggle',
  },

  props: {
    id: {
      type: String,
      default: () => objectid('so'),
    },
    show: {
      type: Boolean,
      default: false,
    },
    hideBackdrop: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    toggle() {
      if (this.show) this.$emit('close');
      else this.$emit('open');
      this.$emit('toggle', !this.show);
    },
    onClose() {
      this.$emit('close');
    },
  },
};
</script>
