var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"bg-white relative block rounded-lg border  shadow-sm px-5 py-4 focus-within:ring-2\n  focus-within:ring-offset-2 focus-within:ring-primary transition duration-150",class:{
    'hover:bg-gray-100 hover:border-gray-300': !_vm.checked && !_vm.disabled,
    'cursor-not-allowed': _vm.disabled,
    'cursor-pointer': !_vm.disabled,
    'bg-gray-50': _vm.disabled && !_vm.checked,
  }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.valueModel),expression:"valueModel"}],staticClass:"sr-only",attrs:{"id":_vm.id,"type":"radio","aria-labelledby":(_vm.id + "-label"),"required":_vm.required,"name":_vm.name,"disabled":_vm.disabled},domProps:{"value":_vm.nativeValue,"checked":_vm._q(_vm.valueModel,_vm.nativeValue)},on:{"change":[function($event){_vm.valueModel=_vm.nativeValue},_vm.onChange]}}),_c('div',{class:[_vm.labelClass, {
      'text-gray-900': _vm.checked,
    }],attrs:{"id":(_vm.id + "-label")}},[_vm._t("default",null,null,{ checked: _vm.checked })],2),_c('div',{staticClass:"border-transparent absolute -inset-px rounded-lg border-2 pointer-events-none",class:{
      'border-primary': _vm.checked,
    },attrs:{"aria-hidden":"true"}})])}
var staticRenderFns = []

export { render, staticRenderFns }