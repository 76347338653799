import { TApiPaginationQuery } from '@contimo/types/src/Api';

export default (paginator: TApiPaginationQuery, prefix: '?' | '&' = '?') => {
  const { page, limit, sort, sortBy } = paginator;
  let string = `page=${page || 1}`;
  if (limit) string += `&limit=${limit}`;
  if (sort) string += `&sort=${sort}`;
  if (sortBy) string += `&sortBy=${sortBy}`;
  if (string.length > 0) return `${prefix}${string}`;
  return string;
};
