<template>
  <CTypo
    v-if="to"
    el="router-link"
    :to="to"
    :tstyle="tstyleType"
    :class="htmlClass"
  >
    <slot />
  </CTypo>
  <CTypo
    v-else-if="href"
    el="a"
    :href="href"
    :tstyle="tstyleType"
    :class="htmlClass"
    :target="target"
  >
    <slot />
  </CTypo>
  <CTypo
    v-else
    el="button"
    :type="nativeType"
    :href="href"
    :tstyle="tstyleType"
    :class="htmlClass"
    @click="$emit('click')"
  >
    <slot />
  </CTypo>
</template>

<script>
export default {
  name: 'CLink',

  props: {
    href: {
      type: String,
      default: null,
    },
    to: {
      type: String,
      default: null,
    },
    small: {
      type: Boolean,
      default: false,
    },
    inverted: {
      type: Boolean,
      default: false,
    },
    nativeType: {
      type: String,
      default: 'button',
    },
    tstyle: {
      type: String,
      default: null,
    },
    target: {
      type: String,
      default: '_self',
    },
  },

  computed: {
    tstyleType() {
      if (this.tstyle) return this.tstyle;
      if (this.small) return 'footnote2';
      return 'body';
    },
    htmlClass() {
      return [
        'inline-flex items-center transition duration-150 border-b rounded-none shadow-none',
        {
          'border-current hover:border-transparent focus:outline-none focus:border-transparent':
            !this.inverted,
          'border-transparent hover:border-current focus:outline-none focus:border-current':
            this.inverted,
        },
      ];
    },
  },
};
</script>
