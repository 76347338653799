import { merchants } from '@contimo/api/src/api';
import address, { IUpdateAddressBody } from '@contimo/api/src/api/address';
import type { Module } from 'vuex';
import type { TRootStore } from '@/store';
import {
  GET_MERCHANT,
  INIT_MERCHANT,
  PLATFORM_ONBOARD_MERCHANT,
  UPDATE_MERCHANT_ADDRESS,
  UPDATE_MERCHANT_EMAILS,
  UPDATE_MERCHANT_PHONE,
} from '../actionTypes';
import { NEEDS_ONBOARDING, THIS_MERCHANT } from '../gettersTypes';
import { SET_MERCHANT } from '../mutationsTypes';

export type TAccountDisabledReason =
  | 'requirements.past_due'
  | 'requirements.pending_verification'
  | 'listed'
  | 'platform_paused'
  | 'rejected.fraud'
  | 'rejected.listed'
  | 'rejected.terms_of_service'
  | 'rejected.other'
  | 'under_review'
  | 'other';

export interface IMerchantStoreState {
  loading: boolean;
  updateLoading: boolean;
  merchant: {
    [key: string]: any;
  } | null;
}

type TMerchantStore = Module<IMerchantStoreState, TRootStore>;

const merchantStore: TMerchantStore = {
  state: () => ({
    loading: false,
    updateLoading: false,
    merchant: null,
  }),

  getters: {
    [THIS_MERCHANT]: (state) => {
      if (state.merchant) {
        return {
          ...state.merchant,
          countryShort: state.merchant?.address.location.countryShort || null,
        };
      }
      return null;
    },

    [NEEDS_ONBOARDING]: (state, getters) => {
      const { thisMerchant } = getters;
      return !thisMerchant.platformOnboarded;
    },
  },

  mutations: {
    [SET_MERCHANT](state, merchant: Record<string, any> | null) {
      state.merchant = merchant;
    },
  },

  actions: {
    async [GET_MERCHANT]({ state, commit }, id) {
      state.loading = true;
      try {
        const { data } = await merchants.show(id);
        commit(SET_MERCHANT, data);
      } finally {
        state.loading = false;
      }
    },
    async [INIT_MERCHANT]({ dispatch }, merchantId) {
      await dispatch(GET_MERCHANT, merchantId);
    },
    async [UPDATE_MERCHANT_ADDRESS]({ state, dispatch }, payload: IUpdateAddressBody) {
      if (state.merchant?.id) {
        state.updateLoading = true;
        try {
          const { data } = await address.update(state.merchant.addressId, payload);
          await dispatch(GET_MERCHANT, state.merchant.id);
          return data;
        } finally {
          state.updateLoading = false;
        }
      }
      return null;
    },
    async [UPDATE_MERCHANT_EMAILS](
      { state, dispatch },
      { invoiceEmail, notificationEmail }: { invoiceEmail: string; notificationEmail: string },
    ) {
      if (state.merchant?.id) {
        state.updateLoading = true;
        try {
          const { data } = await merchants.update(state.merchant.id, {
            invoiceEmail,
            notificationEmail,
          });
          await dispatch(GET_MERCHANT, state.merchant.id);
          return data;
        } finally {
          state.updateLoading = false;
        }
      }
      return null;
    },
    async [UPDATE_MERCHANT_PHONE]({ state, dispatch }, { phone }: { phone: string }) {
      if (state.merchant?.id) {
        state.updateLoading = true;
        try {
          const { data } = await merchants.update(state.merchant.id, {
            phone,
          });
          await dispatch(GET_MERCHANT, state.merchant.id);
          return data;
        } finally {
          state.updateLoading = false;
        }
      }
      return null;
    },
    async [PLATFORM_ONBOARD_MERCHANT]({ state, commit }) {
      if (state.merchant?.id) {
        state.loading = true;
        try {
          const { data } = await merchants.platformOnboard(state.merchant?.id);
          commit(SET_MERCHANT, data);
          return data;
        } finally {
          state.loading = false;
        }
      }
      return null;
    },
  },
};

export default merchantStore;
