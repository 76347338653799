export const getPlaceholderAssetUrl = (path: string) => {
  if (!process.env.VUE_APP_API_URL) {
    throw new Error('VUE_APP_API_URL is not defined');
  }
  if (process.env.VUE_APP_API_URL === '/') {
    return path;
  }
  const url = new URL(process.env.VUE_APP_API_URL);
  return `${url.origin}${path}`;
};
