<template>
  <div
    class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
  >
    <dt
      :class="{
        'text-sm font-medium text-gray-500': !highlight,
        'text-sm font-semibold text-gray-800': highlight,
      }"
    >
      {{ label }}
    </dt>
    <dd
      class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
      :class="{
        'font-semibold': highlight,
      }"
    >
      <slot />
      <template v-if="value">
        <span v-html="value" />
      </template>
      <template v-else-if="!$slots.default">
        <CTypo el="span" class="italic text-gray-500">{{ noValueLabel }}</CTypo>
      </template>
    </dd>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number],
      default: null,
    },
    noValueLabel: {
      type: String,
      default: 'Keine Angabe',
    },
    highlight: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
