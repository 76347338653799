<template>
  <OButton
    :tag="href ? 'a' : to ? 'router-link' : 'button'"
    :variant="variant"
    :size="size"
    :type="!href && !to ? $attrs.nativeType : null"
    :href="href || undefined"
    :to="to || undefined"
    :class="{
      'inline-flex justify-center': href || to,
      'pointer-events-none': loading,
    }"
    :icon-class="loading ? 'opacity-0' : ''"
    v-bind="{
      ...$attrs,
      nativeType: null,
    }"
    @click="!loading ? $emit('click') : () => {}"
  >
    <span :class="{'opacity-0': loading}">
      <slot v-if="!icon" />
      <CIcon
        v-else
        :name="icon"
        class="inline-flex"
        size="20"
      />
    </span>
    <div
      v-if="loading"
      class="absolute top-0 left-0 w-full h-full flex justify-center items-center"
    >
      <CLoadingSpinner class="w-4 h-4" />
    </div>
  </OButton>
</template>

<script>
import { BUTTON_VARIANTS, BUTTON_SIZES } from '../../constants/sharedComponents';

export default {
  name: 'CButton',

  inheritAttrs: false,

  props: {
    icon: {
      type: String,
      default: null,
    },
    href: {
      type: String,
      default: null,
    },
    to: {
      type: String,
      default: null,
    },
    variant: {
      type: String,
      default: 'tertiary',
      validator: (value) => BUTTON_VARIANTS.includes(value),
    },
    size: {
      type: String,
      default: 'medium',
      validator: (value) => BUTTON_SIZES.includes(value),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
