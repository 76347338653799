<template>
  <OTabs
    type="segment"
    :value="active"
    v-bind="{ ...$attrs }"
    @input="onChange"
  >
    <slot />
  </OTabs>
</template>

<script>
export default {
  inheritAttrs: false,

  model: {
    prop: 'active',
    event: 'change',
  },

  props: {
    active: {
      type: String,
      default: null,
    },
  },

  methods: {
    onChange(item) {
      this.$emit('change', item);
    },
  },
};
</script>
