import { RouteConfig } from 'vue-router';

const routes: RouteConfig[] = [
  {
    path: '',
    redirect: 'home',
  },
  {
    path: 'home',
    component: () => import('../ui/views/merchant/Home.vue'),
    redirect: '/m/home/overview',
    meta: {
      title: 'home',
    },
    children: [
      {
        path: 'overview',
        component: () => import('../ui/views/merchant/Home/Overview.vue'),
        meta: {
          title: 'overview',
        },
      },
      {
        path: 'orders',
        component: () => import('../ui/views/merchant/Home/Orders.vue'),
        meta: {
          title: 'order',
        },
      },
    ],
  },
  {
    path: 'settings',
    component: () => import('../ui/views/merchant/Settings.vue'),
    meta: {
      title: 'settings',
    },
    redirect: '/m/settings/company',
    children: [
      {
        path: 'company',
        component: () => import('../ui/views/merchant/Settings/Company.vue'),
        meta: {
          title: 'company',
        },
        redirect: '/m/settings/company/general',
        children: [
          {
            path: 'general',
            component: () => import('../ui/views/merchant/Settings/Company/General.vue'),
            meta: {
              title: 'general',
            },
          },
          {
            path: 'managers',
            component: () => import('../ui/views/merchant/Settings/Company/Managers.vue'),
            meta: {
              title: 'managers',
            },
          },
        ],
      },
      {
        path: 'shop',
        component: () => import('../ui/views/merchant/Settings/Shop.vue'),
        meta: {
          title: 'shop',
        },
        redirect: '/m/settings/shop/general',
        children: [
          {
            path: 'general',
            component: () => import('../ui/views/merchant/Settings/Shop/General.vue'),
            meta: {
              title: 'general',
            },
          },
          {
            path: 'delivery',
            component: () => import('../ui/views/merchant/Settings/Shop/Delivery.vue'),
            meta: {
              title: 'delivery',
            },
          },
          {
            path: 'certificates',
            component: () => import('@/ui/views/merchant/Settings/Shop/Certificates.vue'),
            meta: {
              title: 'certificates',
            },
          },
          {
            path: 'placement',
            component: () => import('@/ui/views/merchant/Settings/Shop/Placement.vue'),
            meta: {
              title: 'placementNotes',
            },
          },
          {
            path: 'page/:slug',
            component: () => import('../ui/views/merchant/Settings/Shop/PageEditor.vue'),
            meta: {
              title: 'page.edit',
            },
          },
        ],
      },
      {
        path: 'container',
        component: () => import('../ui/views/merchant/Settings/Container.vue'),
        meta: {
          title: 'container',
        },
      },
      {
        path: 'wastes',
        component: () => import('../ui/views/merchant/Settings/Wastes.vue'),
        meta: {
          title: 'wasteSorts',
        },
      },
      {
        path: 'delivery',
        component: () => import('../ui/views/merchant/Settings/DeliveryAreas.vue'),
        meta: {
          title: 'deliveryAreas',
        },
      },
    ],
  },
  {
    path: 'invoices',
    component: () => import('../ui/views/merchant/Invoices.vue'),
    meta: {
      title: 'invoices',
    },
  },
];

export default routes;
