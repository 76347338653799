<template>
  <div>
    <slot />
  </div>
</template>

<script>
const markerEvents = {
  drag: 'drag',
  dragstart: 'dragstart',
  dragend: 'dragend',
};

const markerDOMEvents = {
  click: 'click',
  mouseenter: 'mouseenter',
  mouseleave: 'mouseleave',
};

export default {
  name: 'MapMarker',

  provide: {
    get marker() {
      return this.marker;
    },
  },

  props: {
    // mapbox marker options
    offset: {
      type: [Object, Array],
      default: () => [0, 0],
    },
    coordinates: {
      type: [Array, Object],
      required: true,
    },
    color: {
      type: String,
    },
    anchor: {
      type: String,
      default: 'center',
    },
    draggable: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      initial: true,
      marker: undefined,
    };
  },

  computed: {
    mapbox() {
      return this.$parent.mapbox;
    },
    map() {
      return this.$parent.map;
    },
  },

  watch: {
    coordinates(lngLat) {
      if (this.initial) return;
      this.marker.setLngLat(lngLat);
    },
    draggable(next) {
      if (this.initial) return;
      this.marker.setDraggable(next);
    },
  },

  mounted() {
    const markerOptions = {
      ...this.$props,
    };
    if (this.$slots.default) {
      markerOptions.element = this.$slots.default[0].elm;
    }
    this.marker = new this.mapbox.Marker(markerOptions);

    const eventNames = Object.keys(markerEvents);
    this.bindSelfEvents(eventNames, this.marker);

    this.initial = false;
    this.addMarker();
  },

  beforeDestroy() {
    if (this.map !== undefined && this.marker !== undefined) {
      this.marker.remove();
    }
  },

  methods: {
    addMarker() {
      this.marker.setLngLat(this.coordinates).addTo(this.map);
      this.bindMarkerDOMEvents();
      this.$emit('added', { marker: this.marker });
    },

    emitMapEvent(event) {
      this.$parent.$emitMapEvent(event, { marker: this.marker });
    },

    emitSelfEvent(event, nativeEvent) {
      this.$emit(event, { marker: this.marker, nativeEvent });
    },

    bindSelfEvents(events, marker) {
      events.forEach((event) => {
        marker.on(event, (e) => {
          this.emitSelfEvent(event, {
            marker: this.marker,
            event: e,
          });
        });
      });
    },

    bindMarkerDOMEvents() {
      Object.keys(this.$listeners).forEach((key) => {
        if (Object.values(markerDOMEvents).includes(key)) {
          this.marker._element.addEventListener(key, (event) => {
            this.emitMapEvent(event);
            this.emitSelfEvent(event.type, event);
          });
        }
      });
    },

    remove() {
      this.marker.remove();
      this.$emit('removed');
    },

    togglePopup() {
      return this.marker.togglePopup();
    },
  },
};
</script>
