<template>
  <div
    class="c-table bg-white min-w-full"
    :class="{
      'boxed shadow overflow-hidden sm:rounded-lg': box,
    }"
  >
    <OTable
      ref="table"
      :data="data"
      :paginated="paginated"
      :per-page="perPage"
      :current-page.sync="currentPageModel"
      :default-sort-direction="defaultSortDirection"
      :sort-icon="sortIcon"
      :sort-icon-size="sortIconSize"
      :default-sort="defaultSort"
      :aria-next-label="nextLabel"
      :aria-previous-label="previousLabel"
      :aria-page-label="pageLabel"
      :aria-current-label="currentPageLabel"
      :sticky-header="stickyHeader"
      :striped="!notStriped"
      :style="{ maxHeight: `${maxHeight}px` }"
      :mobile-cards="mobileCards"
      :mobile-breakpoint="mobileBreakpoint"
      :mobile-sort-placeholder="mobileSortPlaceholder"
      :hoverable="hoverable"
      :detailed="detailed"
      :detail-key="detailKey"
      :custom-detail-row="customDetailRow"
      :show-detail-icon="showDetailIcon"
      :narrowed="narrowed"
      @click="onClick"
    >
      <slot v-bind="{ toggleDetails }" />
      <template v-slot:pagination>
        <div />
      </template>
      <template v-slot:empty>
        <slot name="empty" />
      </template>
      <template v-slot:detail="props">
        <slot name="detail" v-bind="props" />
      </template>
    </OTable>
    <OPagination
      v-if="paginated && (total > perPage || data.length > perPage)"
      :total="total ? total : data.length"
      :current.sync="currentPageModel"
      :per-page="perPage"
      :simple="paginationSimple"
      :aria-next-label="nextLabel"
      :aria-previous-label="previousLabel"
      :aria-page-label="pageLabel"
      :aria-current-label="currentPageLabel"
      :order="paginationSimple ? 'right' : 'centered'"
      size="large"
      @change="onPageChange"
    />
  </div>
</template>

<script>
const PAGINATION_POSITIONS = ['top', 'bottom'];
const DEFAULT_SORT_DIRECTIONS = ['asc', 'desc'];

export default {
  props: {
    box: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      required: true,
    },
    paginated: {
      type: Boolean,
      default: false,
    },
    perPage: {
      type: Number,
      default: 30,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    paginationSimple: {
      type: Boolean,
      default: false,
    },
    paginationPosition: {
      type: String,
      default: 'bottom',
      validator: (value) => PAGINATION_POSITIONS.includes(value),
    },
    sortIcon: {
      type: String,
      default: 'arrow-sm-up',
    },
    sortIconSize: {
      type: String,
      default: 'small',
    },
    defaultSortDirection: {
      type: String,
      default: 'asc',
      validator: (value) => DEFAULT_SORT_DIRECTIONS.includes(value),
    },
    defaultSort: {
      type: [String, Array],
      default: 'id',
    },
    nextLabel: {
      type: String,
      default: 'Nächste Seite',
    },
    previousLabel: {
      type: String,
      default: 'Vorherige Seite',
    },
    pageLabel: {
      type: String,
      default: 'Seite',
    },
    currentPageLabel: {
      type: String,
      default: 'Aktuelle Seite',
    },
    stickyHeader: {
      type: Boolean,
      default: false,
    },
    notStriped: {
      type: Boolean,
      default: false,
    },
    maxHeight: {
      type: Number,
      default: null,
    },
    mobileCards: {
      type: Boolean,
      default: false,
    },
    mobileBreakpoint: {
      type: String,
      default: '767px',
    },
    total: {
      type: Number,
      default: null,
    },
    mobileSortPlaceholder: {
      type: String,
      default: 'Sortieren nach',
    },
    hoverable: {
      type: Boolean,
      default: false,
    },
    detailed: {
      type: Boolean,
      default: false,
    },
    detailKey: {
      type: String,
      default: 'id',
    },
    showDetailIcon: {
      type: Boolean,
      default: false,
    },
    customDetailRow: {
      type: Boolean,
      default: false,
    },
    narrowed: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    currentPageModel: 1,
  }),

  watch: {
    currentPage: {
      immediate: true,
      handler(newVal) {
        this.currentPageModel = newVal;
      },
    },
  },

  methods: {
    onPageChange(page) {
      // this.currentPageModel = page;
      this.$emit('update:currentPage', page);
      this.$emit('page-change', page);
    },
    toggleDetails(row) {
      this.$refs.table.toggleDetails(row);
    },
    onClick(e) {
      this.$emit('row-click', e);
    },
  },
};
</script>
