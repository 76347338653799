import Vue from 'vue';

const findIndexByKey = (array: any[], keyValue: any, key = 'id') => {
  return array.findIndex((aitem) => aitem[key] === keyValue);
};

const updateOrPushInArray = (array: any[], item: any, findKey = 'id') => {
  const index = findIndexByKey(array, item[findKey], findKey);
  if (index > -1) {
    Vue.set(array, index, item);
    return 'updated';
  }
  array.push(item);
  return 'created';
};

const removeFromArrayByKey = (array: any[], keyValue: any, key = 'id') => {
  const index = findIndexByKey(array, keyValue, key);
  if (index === -1) {
    return 'notFound';
  }
  array.splice(index, 1);
  return 'removed';
};

const setKeyValueForItemByKey = (
  array: any[],
  updateObj: Record<string, any>,
  keyValue: any,
  key = 'id',
) => {
  const index = findIndexByKey(array, keyValue, key);
  if (index > -1) {
    Vue.set(array, index, { ...array[index], ...updateObj });
    return 'updated';
  }
  return 'notFound';
};

export { updateOrPushInArray, setKeyValueForItemByKey, removeFromArrayByKey };
