import Vue from 'vue';
import VueCompositionAPI from '@vue/composition-api';
import VueI18n from 'vue-i18n';
import PortalVue from 'portal-vue';
import uiComponents from '@contimo/ui/src/main';
import client from '@contimo/api/src/api/client';
import App from './App.vue';
// import './registerServiceWorker';
import router from './router';
import store from './store';
import directives from './ui/directives';
import { USER_LANG_STORE } from './constants/api';
import de from '../../locales/de.json';
import '@contimo/ui/src/styles.scss';
import { SET_ERROR_NOTIFICATION } from './store/mutationsTypes';

Vue.config.productionTip = false;

Vue.use(VueCompositionAPI);
Vue.use(uiComponents);
Vue.use(directives);
Vue.use(VueI18n);
Vue.use(PortalVue);

const i18n = new VueI18n({
  locale: localStorage.getItem(USER_LANG_STORE) || 'de', // set locale
  fallbackLocale: 'de', // set fallback locale
  messages: {
    de,
  },
  numberFormats: {
    de: {
      currency: {
        style: 'currency',
        currency: 'EUR',
      },
    },
  },
});

client.interceptors.response.use(
  (resp) => {
    return resp;
  },
  (error) => {
    if (error.response.status === 500) {
      store.commit(SET_ERROR_NOTIFICATION, { content: null, show: true });
    }
    return Promise.reject(error);
  },
);

new Vue({
  router,
  // @ts-ignore
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
