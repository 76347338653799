<template>
  <ODropdown
    aria-role="list"
    :position="position"
    :trigger-class="rootClass"
  >
    <template v-slot:trigger="{ active }">
      <div
        class="inline-flex items-center text-sm font-medium px-1 py-1"
      >
        <span class="mr-1">{{ label }}</span>
        <CIcon :name="active ? triggerIcons[1] : triggerIcons[0]" size="16" />
      </div>
    </template>
    <slot />
  </ODropdown>
</template>

<script>
const POSITIONS = ['bottom-left', 'top-left', 'top-right', 'bottom-right'];

export default {
  props: {
    position: {
      type: String,
      default: 'bottom-right',
      validator: (value) => POSITIONS.includes(value),
    },
    label: {
      type: String,
      required: true,
    },
    triggerIcons: {
      type: Array,
      default: () => ['chevron-down', 'chevron-up'],
    },
    light: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    rootClass() {
      if (this.light) return 'focus:ring-on-primary-2';
      return 'focus:ring-primary-2';
    },
  },
};
</script>
