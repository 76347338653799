<template>
  <div
    class="w-full c-editor border rounded-md focus-within:ring-primary-3 max-h-[600px]
    overflow-auto relative"
  >
    <div class="bg-white z-10 sticky top-0 flex justify-between items-center px-4 py-2 border-b">
      <div>
        <slot name="header" />
      </div>
      <div>
        <slot />
      </div>
    </div>
    <EditorContent
      class="w-full"
      :editor="editor"
    />
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';

export default {
  components: {
    EditorContent,
  },

  props: {
    value: {
      type: Object,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      editor: null,
    };
  },

  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(value) {
        if (!this.editor) {
          return;
        }
        if (value) {
          this.editor.commands.setContent(this.value);
        } else {
          this.editor.commands.setContent(null);
        }
      },
    },
  },

  mounted() {
    this.editor = new Editor({
      content: this.value,
      extensions: [
        StarterKit,
        Placeholder.configure({
          placeholder: this.placeholder,
        }),
      ],
      editorProps: {
        attributes: {
          class:
            'bg-white p-4 md:p-6 prose prose-sm sm:prose w-full max-w-unset focus:outline-none',
        },
      },
      onUpdate: () => {},
    });
  },

  beforeDestroy() {
    this.editor.destroy();
  },

  methods: {
    getJSON() {
      return this.editor.getJSON();
    },
  },
};
</script>
