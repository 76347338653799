<template>
  <div
    class="w-full mx-auto"
    :class="{
      'p-6 md:px-8 lg:py-8': !noSpacing,
      'max-w-8xl': !small,
      'max-w-5xl': small,
    }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    noSpacing: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
