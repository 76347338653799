var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('router-link',{attrs:{"to":_vm.to,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isExactActive = ref.isExactActive;
var isActive = ref.isActive;
return [_c('a',{staticClass:"group inline-flex text-sm mr-6 lg:mr-8 font-medium py-3 border-b-2\n    border-transparent transition duration-150 hover:text-primary relative items-center -mb-px\n    no-tap-highlight",class:{
      'text-primary border-primary': isExactActive || (isActive && _vm.withChildren),
      'text-gray-600 border-transparent': !isExactActive || (!isActive && _vm.withChildren),
    },attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"relative z-10"},[_vm._t("default")],2),_c('span',{staticClass:"absolute w-[calc(100%+16px)] h-7 left-0 -ml-2 bg-primary z-0 bg-opacity-0 rounded\n      group-hover:bg-opacity-10 transition duration-150"})])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }