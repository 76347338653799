<template>
  <transition
    enter-active-class="transform ease-out duration-300 transition"
    enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
    enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
    leave-active-class="transition ease-in duration-100"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <div
      v-if="value"
      class="
      max-w-sm 
      w-full 
      bg-white 
      shadow-lg 
      rounded-lg 
      pointer-events-auto 
      ring-1 
      ring-black 
      ring-opacity-5 
      overflow-hidden 
      m-4
      fixed
      right-0
    "
    >
      <div class="p-4">
        <div class="flex items-start">
          <div class="flex-shrink-0">
            <CIcon
              :name="icon"
              class="inline-flex"
              :class="iconClass"
              size="24"
            />
          </div>
          <div class="ml-3 w-0 flex-1 pt-0.5">
            <slot />
          </div>
          <div class="ml-4 flex-shrink-0 flex">
            <button
              @click="closeNotification"
              class="
              bg-white
              rounded-md
              inline-flex 
              text-gray-400
              hover:text-gray-500
              focus:outline-none
              focus:ring-2
              focus:ring-offset-2
              focus:ring-indigo-500
            "
            >
              <span class="sr-only">Close</span>
              <CIcon
                :name="closeIcon"
                class="inline-flex"
                size="20"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
    },
    icon: {
      type: String,
      default: null,
    },
    iconClass: {
      type: String,
    },
    closeIcon: {
      type: String,
      default: null,
    },
    duration: {
      type: Number,
      default: 1500,
    },
  },

  watch: {
    value: {
      immediate: true,
      handler(newValue, oldValue) {
        if (!oldValue && newValue && this.duration) {
          setTimeout(() => this.closeNotification(), this.duration);
        }
      },
    },
  },

  methods: {
    closeNotification() {
      this.$emit('input', false);
    },
  },
};
</script>