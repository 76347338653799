<template>
  <div class="rounded-md p-4 overflow-hidden relative" :class="[themeClass, marginClass]">
    <div class="absolute left-0 top-0 bottom-0" :class="highlightClass" />
    <div
      class="flex items-start justify-between"
    >
      <div class="flex items-start">
        <CIcon
          :name="iconName"
          :class="iconClass"
          size="20"
          class="flex-shrink-0"
        />
        <div class="ml-3">
          <CTypo
            class="font-medium"
            tstyle="body"
            :class="{
              'mb-2': $slots.content,
            }"
          >
            <slot />
          </CTypo>
          <CTypo v-if="$slots.content" el="p" tstyle="body">
            <slot name="content" />
          </CTypo>
          <div v-if="$slots.links" class="mt-2">
            <slot name="links" />
          </div>
        </div>
      </div>
      <CLink
        v-if="link && !$slots.links"
        :href="href"
        target="_blank"
        class="flex-shrink-0 ml-3"
        tabindex="-1"
        inverted
      >
        {{ link }} ->
      </CLink>
    </div>
  </div>
</template>

<script>
const VARIANTS = ['info', 'warning', 'danger', 'success'];

export default {
  props: {
    variant: {
      type: String,
      default: 'info',
      validator: (val) => VARIANTS.includes(val),
    },
    marginClass: {
      type: String,
      default: 'mb-4',
    },
    highlight: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: null,
    },
    link: {
      type: String,
      default: null,
    },
    href: {
      type: String,
      default: null,
    },
  },

  computed: {
    themeClass() {
      if (this.variant === 'warning') {
        return 'bg-yellow-50 text-yellow-700';
      }
      if (this.variant === 'danger') {
        return 'bg-red-50 text-red-800';
      }
      if (this.variant === 'success') {
        return 'bg-green-50 text-green-700';
      }
      return 'bg-blue-50 text-blue-700';
    },
    highlightClass() {
      if (this.highlight) {
        if (this.variant === 'warning') {
          return 'border-l-4 border-yellow-400';
        }
        if (this.variant === 'danger') {
          return 'border-l-4 border-red-400';
        }
        if (this.variant === 'success') {
          return 'border-l-4 border-green-400';
        }
        return 'border-l-4 border-blue-400';
      }
      return '';
    },
    iconClass() {
      if (this.variant === 'warning') {
        return 'text-yellow-400';
      }
      if (this.variant === 'danger') {
        return 'text-red-400';
      }
      if (this.variant === 'success') {
        return 'text-green-400';
      }
      return 'text-blue-400';
    },
    iconName() {
      if (this.icon) return this.icon;
      if (this.variant === 'warning') return 'exclamation';
      if (this.variant === 'danger') return 'x-circle';
      if (this.variant === 'success') return 'check-circle';
      return 'information-circle';
    },
  },
};
</script>
