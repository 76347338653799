<template>
  <div class="c-upload">
    <OField>
      <OUpload
        v-model="valueModel"
        drag-drop
        :multiple="multiple"
        :accept="accept"
        :required="required"
        v-bind="{ ...$attrs }"
        @input="onInput"
      >
        <section class="flex flex-col w-full items-center justify-center">
          <p class="text-primary">
            <CIcon name="upload" outline size="24" />
          </p>
          <CTypo tstyle="caption1" class="mt-2">
            {{ label }}<template v-if="required">*</template>
          </CTypo>
        </section>
      </OUpload>
    </OField>
    <div v-if="valueModel && !multiple" class="mt-3">
      <div :class="itemClass">
        <CTypo tstyle="footnote1" class="mr-1">{{ valueModel.name }}</CTypo>
        <button
          type="button"
          @click="deleteFile"
        >
          <CIcon name="x" size="20" />
        </button>
      </div>
    </div>
    <div v-else-if="valueModel && valueModel.length > 0" class="mt-3">
      <div
        v-for="(file, index) in valueModel"
        :key="file.name"
        :class="itemClass"
      >
        <CTypo tstyle="footnote1" class="mr-1">{{ file.name }}</CTypo>
        <button
          type="button"
          class="rounded-full focus:outline-none focus:ring-primary-2 p-px"
          @click="deleteFile(index)"
        >
          <CIcon name="x" size="16" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,

  model: {
    prop: 'value',
    event: 'change',
  },

  props: {
    value: {
      type: [Array, File],
      default: null,
    },
    label: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
      default: null,
    },
  },

  data: () => ({
    valueModel: null,
  }),

  computed: {
    itemClass() {
      return `inline-flex items-center bg-gray-50 border shadow-sm rounded-full
      px-2 py-1 mr-2 text-gray-700`;
    },
  },

  watch: {
    multiple: {
      immediate: true,
      handler(newVal) {
        if (newVal) this.valueModel = [];
        else this.valueModel = null;
      },
    },
    value: {
      immediate: true,
      handler(newVal) {
        this.valueModel = newVal;
      },
    },
  },

  methods: {
    reset() {
      if (this.multiple) {
        this.valueModel = [];
      } else {
        this.valueModel = null;
      }
      this.onInput();
    },
    deleteFile(index) {
      if (this.multiple) {
        this.valueModel.splice(index, 1);
      } else {
        this.valueModel = null;
      }
      this.onInput();
    },
    onInput() {
      this.$emit('change', this.valueModel);
    },
  },
};
</script>
