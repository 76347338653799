import Vue from 'vue';
import Vuex from 'vuex';
import type { IStoreOptions } from '@/types/VuexStore';
import modules from './modules';

Vue.use(Vuex);

const rootStore = new Vuex.Store<IStoreOptions>({
  modules,
});

export type TRootStore = Record<string, any>;

export default rootStore;
