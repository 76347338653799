<template>
  <router-link
    v-if="to"
    :to="to"
    ref="routerlink"
    v-slot="{ href, navigate }"
    custom
  >
    <a
      :href="href"
      class="px-4 transition duration-150 py-2 inline-flex rounded-full focus:ring-on-primary-2"
      :class="{
        'opacity-80 hover:opacity-100 hover:bg-white hover:bg-opacity-25': !isActive,
      }"
      @click="navigate"
    >
      <CTypo tstyle="body" el="span" class="font-medium tracking-wide inline-flex items-center">
        <slot />
      </CTypo>
    </a>
  </router-link>
  <a
    v-else
    :href="nativeHref"
    class="px-4 transition duration-150 py-2 inline-flex rounded-full focus:ring-on-primary-2
    opacity-80 hover:opacity-100 hover:bg-white hover:bg-opacity-25"
  >
    <CTypo tstyle="body" el="span" class="font-medium tracking-wide inline-flex items-center">
      <slot />
    </CTypo>
  </a>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  inject: ['navbar'],

  props: {
    to: {
      type: String,
      default: null,
    },
    nativeHref: {
      type: String,
      default: null,
    },
  },

  computed: {
    isActive() {
      return this.$route.path.indexOf(this.to) !== -1;
    },
    ...mapGetters(['WINDOW_WIDTH']),
  },

  watch: {
    isActive(newVal) {
      if (newVal) {
        this.navbar.setActiveIndicator(this.$el.offsetLeft, this.$el.offsetWidth);
      }
    },
    windowWidth() {
      if (this.isActive) {
        this.navbar.setActiveIndicator(this.$el.offsetLeft, this.$el.offsetWidth);
      }
    },
  },

  mounted() {
    if (this.isActive) {
      this.navbar.setActiveIndicator(this.$el.offsetLeft, this.$el.offsetWidth);
    }
  },
};
</script>
