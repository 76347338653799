/* eslint-disable @typescript-eslint/indent */
import { AxiosPromise } from 'axios';
import client from './client';

export type TShop = {
  id: number;
  merchantId: number;
  url: string | null;
  domain: string | null;
  active: boolean;
  logo: string;
  coverPicture: string;
  minDaysBetweenOrderAndDelivery: number;
  fromDeliveryTime: string;
  fromPickupTime: string;
  deliveryDays: Array<
    'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday'
  >;
  publicPlacementNotes: Record<string, any>;
  generalPlacementNotes: Record<string, any>;
};

export type TShopCreateBody = {
  domain: string | null;
  color: string;
  active: boolean;
  cardTitle: string;
  cardLine1: string;
  cardLine2: string;
  cardLine3: string;
};

export type TShopUpdateBody = {
  domain?: string | null;
  color?: string;
  active?: boolean;
  removeLogo?: boolean;
  removeCoverPicture?: boolean;
  cardTitle?: string;
  cardLine1?: string;
  cardLine2?: string;
  cardLine3?: string;
  publicPlacementNotes?: Record<string, any>;
  generalPlacementNotes?: Record<string, any>;
};

const buildUrl = (path?: string) => (path ? `/shops/${path}` : '/shops');

export default {
  index: (): AxiosPromise<TShop> => {
    return client.get(buildUrl());
  },
  store: (payload: TShopCreateBody): AxiosPromise<TShop> => {
    return client.post(buildUrl(), payload);
  },
  update: (id: number, payload: TShopUpdateBody): AxiosPromise<TShop> => {
    return client.patch(buildUrl(id.toString()), payload);
  },
  uploadLogo: (id: number, logo: File): AxiosPromise<TShop> => {
    const formData = new FormData();
    formData.append('logo', logo);
    return client.patch(`${buildUrl(id.toString())}?uploadLogo=true`, formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });
  },
  uploadCoverPicture: (id: number, coverPicture: File): AxiosPromise<TShop> => {
    const formData = new FormData();
    formData.append('coverPicture', coverPicture);
    return client.patch(`${buildUrl(id.toString())}?uploadCoverPicture=true`, formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });
  },
};
