<template>
  <div class="sticky top-0 z-10">
    <div class="bg-primary text-on-primary">
      <CContainer class="px-6 md:px-8" no-spacing>
        <div 
          class="relative flex w-full h-16 items-center justify-between" 
          :class="{ 'h-20': big }"
        >
          <div class="flex items-center flex-grow">
            <slot name="title" />

            <div class="hidden md:flex items-center">
              <slot />
            </div>
            <div
              ref="activeIndicator"
              class="hidden md:block w-3 absolute bottom-0 left-0 text-white
              transition duration-150"
              :class="{
                'opacity-0': indicatorActive === -1,
                'h-auto': indicatorActive > -1,
              }"
              :style="{
                transform: `translate(${indicatorActive}px, 1px)`,
              }"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 8 5"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.78086881,0.976086012 L8,5 L8,5 L0,5 L3.21913119,0.976086012 C3.56414074,
                  0.544824077 4.19343311,0.474902702 4.62469505,0.81991225 C4.68235975,
                  0.866044012 4.73473705,0.918421309 4.78086881,0.976086012 Z"
                />
              </svg>
            </div>
          </div>
          <div v-if="$slots.right" class="ml-4 flex-shrink flex items-center justify-end">
            <slot name="right" />
          </div>
        </div>
      </CContainer>
    </div>
  </div>
</template>

<script>
export default {
  provide() {
    return {
      navbar: {
        setActiveIndicator: this.setActiveIndicator,
      },
    };
  },
  props: {
    big: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    indicatorActive: -1,
  }),

  methods: {
    setActiveIndicator(elLeft, elWidth) {
      this.indicatorActive = elLeft + elWidth / 2 - 6;
    },
  },
};
</script>
