import { AxiosPromise } from 'axios';
import buildPaginationQuery from '../utils/buildPaginationQuery';
import type { TApiPaginationQuery, TApiPaginationResult } from '@contimo/types/src//Api';
import type { ISellingPrice, IContainerSellingPriceRaw } from '@contimo/types/src/ApiModels';
import client from './client';

export type TContainerSellingPrice = IContainerSellingPriceRaw;

export type TSellingPrice = ISellingPrice;

export type TSellingPriceCreateBody = {
  productId: number;
  name: string | null;
  basePrice: number;
  containerSellingPrice: {
    durationPriceAfter: number;
    durationPricePerDay: number;
    pricePerDeliveryKm: number;
  };
};

export type TSellingPriceUpdateBody = {
  name: string | null;
  basePrice: number;
  containerSellingPrice: {
    durationPriceAfter: number;
    durationPricePerDay: number;
    pricePerDeliveryKm: number;
  };
};

const buildUrl = (path?: string | number) => {
  return path ? `/selling-prices/${path}` : '/selling-prices';
};

export default {
  index: (
    pagination: TApiPaginationQuery = {},
  ): AxiosPromise<TApiPaginationResult<TSellingPrice[]>> => {
    return client.get(`${buildUrl()}${buildPaginationQuery(pagination)}`);
  },
  show: (id: number): AxiosPromise<TSellingPrice> => client.get(buildUrl(id)),
  update: (id: number, body: TSellingPriceUpdateBody): AxiosPromise<TSellingPrice> => {
    return client.patch(buildUrl(id), body);
  },
  store: (body: TSellingPriceCreateBody): AxiosPromise<TSellingPrice> => {
    return client.post(buildUrl(), body);
  },
};
