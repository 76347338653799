<template>
  <div
    class="flex items-center flex-col md:flex-row-reverse w-full"
    :class="{
      'md:w-auto': variant !== 'dialog',
    }"
  >
    <CButton
      :variant="submitVariant"
      class="w-full"
      :class="{
        'md:w-auto': variant !== 'dialog',
        'flex-1': variant === 'dialog',
        'md:ml-2': (!noCancel || cancelText) && !hideCancel,
      }"
      :native-type="submitForm ? 'submit' : 'button'"
      :form="submitForm"
      :loading="submitLoading"
      :disabled="submitIsDisabled"
      @click="$emit('submit')"
    >
      <slot name="submit" />
      <template v-if="!$slots.submit">
        {{ submitLabel }}
      </template>
      <span v-if="remainingCountdown" class="ml-1 text-sm font-medium opacity-50 tabular-nums">
        {{ remainingCountdown }}
      </span>
    </CButton>
    <CButton
      v-if="(!noCancel || cancelText) && !hideCancel"
      :variant="cancelVariant"
      class="w-full mt-3 md:mt-0 md:mr-2"
      :class="{
        'md:w-auto': variant !== 'dialog',
        'flex-1': variant === 'dialog',
      }"
      @click="$emit('cancel')"
    >
      <slot name="cancel" />
      <template v-if="!$slots.cancel">
        {{ cancelLabel }}
      </template>
    </CButton>
  </div>
</template>

<script>
export default {
  props: {
    noCancel: {
      type: Boolean,
      default: false,
    },
    cancelText: {
      type: String,
      default: null,
    },
    cancelVariant: {
      type: String,
      default: 'tertiary',
    },
    submitText: {
      type: String,
      default: null,
    },
    submitVariant: {
      type: String,
      default: 'primary',
    },
    submitForm: {
      type: String,
      default: null,
    },
    submitLoading: {
      type: Boolean,
      default: false,
    },
    submitDisabled: {
      type: Boolean,
      default: false,
    },
    hideCancel: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      required: true,
    },
    submitCountdown: {
      type: Number,
      default: null,
    },
  },

  data: () => ({
    remainingCountdown: null,
  }),

  computed: {
    submitIsDisabled() {
      if (this.submitCountdown !== null && this.remainingCountdown > 0) {
        return true;
      }
      return this.submitDisabled;
    },
    cancelLabel() {
      if (!this.cancelText) {
        return this.$t('cancel');
      }
      return this.cancelText;
    },
    submitLabel() {
      if (!this.submitText) {
        return this.$t('continue');
      }
      return this.submitText;
    },
  },

  mounted() {
    if (this.submitCountdown) {
      this.runCountdown();
    }
  },

  methods: {
    runCountdown() {
      this.remainingCountdown = this.submitCountdown;
      const interval = setInterval(() => {
        this.remainingCountdown -= 1;
        if (this.remainingCountdown === 0) {
          clearInterval(interval);
          this.remainingCountdown = null;
        }
      }, 1000);
    },
  },
};
</script>
