



























import { defineComponent } from '@vue/composition-api';
import type Mapbox from 'mapbox-gl';
import objectid from '../../utils/objectid';
import 'mapbox-gl/dist/mapbox-gl.css';

let map: Mapbox.Map;
let mapboxgl: typeof Mapbox;

const defaultMapboxConfig = {
  style: 'mapbox://styles/phil1996s/ckqc3b3ab02s318qiyo0aqfof',
  zoom: 9,
};

export default defineComponent({
  inheritAttrs: false,

  props: {
    mapId: {
      type: String,
      default: () => objectid('map'),
    },
    config: {
      type: Object,
      default: () => {},
    },
    center: {
      type: Object,
      default: () => ({
        lng: 8.482136457727393,
        lat: 53.49379485193191,
      }),
    },
    heightClass: {
      type: String,
      default: 'min-h-[300px] h-[300px]',
    },
    hideMap: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      mapboxLoaded: false,
      mapLoaded: false,
    };
  },

  computed: {
    containerId(): string {
      return `container-${this.mapId}`;
    },
    mapbox() {
      return mapboxgl;
    },
    map() {
      return map;
    },
  },

  watch: {
    center(newVal) {
      this.setCenter(newVal);
    },
  },

  created() {
    if (!this.mapboxLoaded) {
      this.mapboxLoaded = false;
      import(/* webpackChunkName: "mapbox" */ 'mapbox-gl').then((mapbox) => {
        mapboxgl = mapbox.default;
        mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_KEY || '';
        this.mapboxLoaded = true;
        this.$emit('mapbox-loaded', true);
      });
    }
  },

  mounted() {
    this.initMap();
  },

  methods: {
    $emitMapEvent(event: Mapbox.MapboxEvent, data = {}) {
      this.$emit(event.type, {
        map: this.map,
        component: this,
        mapboxEvent: event,
        ...data,
      });
    },
    initMap() {
      this.loadMap();
      this.$on('mapbox-loaded', () => {
        this.loadMap();
      });
    },
    loadMap() {
      if (this.mapboxLoaded) {
        this.mapLoaded = false;
        map = new mapboxgl.Map({
          container: this.mapId,
          ...defaultMapboxConfig,
          ...this.config,
          // @ts-ignore-next-line
          center: this.config.center || this.center,
        });
        map.on('load', () => {
          this.mapLoaded = true;
          this.$emit('map-loaded', map);
        });
      }
    },
    setMarker(lngLat: Mapbox.LngLatLike, options: Mapbox.MarkerOptions = {}) {
      return new mapboxgl.Marker(options).setLngLat(lngLat).addTo(map);
    },
    setCenter(center: Mapbox.LngLatLike) {
      map.setCenter(center);
    },
  },
});
