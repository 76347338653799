<template>
  <div 
    v-if="
      container.cover ||
        container.doors ||
        container.flap
    "
  >
    {{ getContainerOpeningType(container) }}
  </div>
</template>

<script>
import getContainerOpeningTypeString from '../../utils/getContainerOpeningTypeString';

export default {
  props: {
    container: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getContainerOpeningType(container) {
      return getContainerOpeningTypeString(container, this.$i18n);
    },
  },
};
</script>