<template>
  <form @submit.prevent="$emit('submit', $event)">
    <slot />
    <CValidationFailure :error-fields-response="errorFieldsResponse" />
    <div v-if="!customSaveButton" class="flex justify-end mt-3">
      <CButton
        variant="primary"
        :loading="loading"
        native-type="submit"
      >
        {{ $t('save') }}
      </CButton>
    </div>
    <slot v-else name="custom-save-button" />
  </form>
</template>

<script>
export default {
  props: {
    errorFieldsResponse: {
      type: Object,
      required: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    customSaveButton: {
      type: Boolean,
      default: false,
    },
  },
};
</script>