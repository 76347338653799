import type { Module } from 'vuex';
import type {
  IDeliveryArea,
  TDeliveryAreaCreateBody,
  TDeliveryAreaPatchBody,
} from '@contimo/api/src/api/deliveryAreas';
import Vue from 'vue';
import { deliveryAreas } from '@contimo/api/src/api';
import { TPaginationMeta } from '@contimo/types/src/Api';
import type { TRootStore } from '@/store';
import { removeFromArrayByKey, updateOrPushInArray } from '@/utils/store';
import { ALL_DELIVERY_AREAS, UNSAVED_DELIVERY_AREAS } from '../gettersTypes';
import {
  REMOVE_DELIVERY_AREA,
  SET_DELIVERY_AREA,
  SET_DELIVERY_AREAS,
  SET_DELIVERY_AREA_LOADING,
} from '../mutationsTypes';
import {
  CREATE_DELIVERY_AREA,
  DELETE_DELIVERY_AREA,
  GET_DELIVERY_AREA,
  GET_DELIVERY_AREAS,
  UPDATE_DELIVERY_AREA,
} from '../actionTypes';

type TDeliveryAreaLoading = 'delete' | 'update' | false | undefined;

interface IStoredDeliveryArea extends IDeliveryArea {
  $loading?: TDeliveryAreaLoading;
}

export interface IDeliveryAreasStoreState {
  loading: boolean;
  createLoading: boolean;
  deliveryAreas: IStoredDeliveryArea[];
  pagination: TPaginationMeta | null;
}

type TDeliveryAreasStore = Module<IDeliveryAreasStoreState, TRootStore>;

const deliveryAreasStore: TDeliveryAreasStore = {
  state: () => ({
    loading: false,
    createLoading: false,
    deliveryAreas: [],
    pagination: null,
  }),

  getters: {
    [UNSAVED_DELIVERY_AREAS]: (state) => state.deliveryAreas.filter((da) => da.id === -1),
    [ALL_DELIVERY_AREAS]: (state) => {
      return state.deliveryAreas.sort((a, b) => a.postalCodeId.localeCompare(b.postalCodeId));
    },
  },

  mutations: {
    [SET_DELIVERY_AREA](state, deliveryArea: IStoredDeliveryArea) {
      updateOrPushInArray(state.deliveryAreas, deliveryArea);
    },
    [SET_DELIVERY_AREAS](state, deliveryAreas: IStoredDeliveryArea[]) {
      deliveryAreas.forEach((deliveryArea) => {
        updateOrPushInArray(state.deliveryAreas, deliveryArea);
      });
    },
    [REMOVE_DELIVERY_AREA](state, id: number | string) {
      removeFromArrayByKey(state.deliveryAreas, id);
    },
    [SET_DELIVERY_AREA_LOADING](
      state,
      { id, loading }: { id: number; loading: TDeliveryAreaLoading },
    ) {
      const index = state.deliveryAreas.findIndex((da) => da.id === id);
      if (index !== -1) {
        Vue.set(state.deliveryAreas, index, {
          ...state.deliveryAreas[index],
          $loading: loading,
        });
      }
    },
    setDeliveryAreasPagination(state, pagination: TPaginationMeta) {
      state.pagination = pagination;
    },
  },

  actions: {
    async [CREATE_DELIVERY_AREA]({ commit, state }, body: TDeliveryAreaCreateBody) {
      state.createLoading = true;
      try {
        body.additionalPrice *= 100;
        const { data } = await deliveryAreas.store(body);
        data.additionalPrice /= 100;
        commit(SET_DELIVERY_AREA, data);
        state.createLoading = false;
        return data;
      } finally {
        state.createLoading = false;
      }
    },
    async [GET_DELIVERY_AREA]({ commit }, id: number) {
      const { data } = await deliveryAreas.show(id);
      data.additionalPrice /= 100;
      commit(SET_DELIVERY_AREA, data);
      return data;
    },
    async [GET_DELIVERY_AREAS]({ commit, state }, page = 1) {
      state.loading = true;
      try {
        const { data, meta } = (
          await deliveryAreas.index({
            page,
            limit: state.pagination?.perPage || 1000,
          })
        ).data;
        data.forEach((deliveryArea) => {
          deliveryArea.additionalPrice /= 100;
        });
        commit(SET_DELIVERY_AREAS, data);
        commit('setDeliveryAreasPagination', meta, {
          root: false,
        });
        return data;
      } finally {
        state.loading = false;
      }
    },
    async [UPDATE_DELIVERY_AREA](
      { commit },
      { id, body }: { id: number; body: TDeliveryAreaPatchBody },
    ) {
      commit(SET_DELIVERY_AREA_LOADING, { id, loading: 'update' });
      try {
        if (body.additionalPrice) {
          body.additionalPrice *= 100;
        }
        const { data } = await deliveryAreas.update(id, body);
        data.additionalPrice /= 100;
        commit(SET_DELIVERY_AREA, data);
        return data;
      } finally {
        commit(SET_DELIVERY_AREA_LOADING, { id, loading: false });
      }
    },
    async [DELETE_DELIVERY_AREA]({ commit }, id: number) {
      commit(SET_DELIVERY_AREA_LOADING, { id, loading: 'delete' });
      try {
        const { data } = await deliveryAreas.destroy(id);
        commit(REMOVE_DELIVERY_AREA, id);
        return data;
      } finally {
        commit(SET_DELIVERY_AREA_LOADING, { id, loading: false });
      }
    },
  },
};

export default deliveryAreasStore;
