<template>
  <component
    :is="el"
    v-bind="{
      id,
      hideBackdrop: true,
      ...$attrs,
    }"
    v-model="showModel"
    class="stack-router-child scale-95"
    :class="{
      'mb-10 md:mb-0 md:mr-12 z-30 bg-white bg-opacity-75 backdrop-filter backdrop-blur-md':
        inBackground,
      'z-40 scale-100': inForeground,
    }"
    @close="onClose"
    @open="$emit('open')"
  >
    <template v-slot:header>
      <slot name="header" />
    </template>
    <slot />
    <template v-slot:footer>
      <slot name="footer" />
    </template>
  </component>
</template>

<script>
import objectid from '../../utils/objectid';

export default {
  inheritAttrs: false,

  props: {
    id: {
      type: String,
      default: () => objectid('src'),
    },
    el: {
      type: String,
      default: 'CSlideOver',
    },
  },

  data: () => ({
    showModel: false,
    depth: 0,
  }),

  computed: {
    inForeground() {
      return this.$parent.inForeground(this.id);
    },
    inBackground() {
      return this.$parent.inBackground(this.id);
    },
    isHidden() {
      return this.$parent.isHidden(this.id);
    },
  },

  methods: {
    open() {
      this.showModel = true;
    },
    toggle() {
      this.showModel = !this.showModel;
    },
    onClose() {
      this.$emit('close');
      this.$parent.pop();
    },
  },
};
</script>
