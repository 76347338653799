<template>
  <CMessage v-if="errorFields.length" variant="danger">
    {{ $t('errorMessages.actionWasNotSuccessful') }}
    <template v-if="errorFields.length === 0" v-slot:copy>
      {{ $t('errorMessages.signupFailedPleaseControlAllFields') }}
    </template>
    <template v-else v-slot:copy>
      {{ $t('errorMessages.signupFailedPleaseControlFollowingFields') }}
      <ul class="list-disc list-inside">
        <CTypo
          v-for="field in errorFields"
          :key="field"
          el="li"
        >
          {{ $t(`validationErrors.${field}`) }}
        </CTypo>
      </ul>
    </template>
  </CMessage>
</template>

<script>
export default {
  props: {
    errorFieldsResponse: {
      type: Object,
      required: false,
    },
  },

  computed: {
    errorFields() {
      const errorFiels = [];
      if (
        this.errorFieldsResponse &&
        this.errorFieldsResponse.data.details &&
        this.errorFieldsResponse.data.details.errors
      ) {
        this.errorFieldsResponse.data.details.errors.forEach((error) => {
          errorFiels.push(error.field);
        });
      }

      return errorFiels;
    },
  },
};
</script>