<template>
  <label
    class="bg-white relative block rounded-lg border  shadow-sm px-5 py-4 focus-within:ring-2
    focus-within:ring-offset-2 focus-within:ring-primary transition duration-150"
    :class="{
      'hover:bg-gray-100 hover:border-gray-300': !checked && !disabled,
      'cursor-not-allowed': disabled,
      'cursor-pointer': !disabled,
      'bg-gray-50': disabled && !checked,
    }"
  >
    <input
      v-model="valueModel"
      :value="nativeValue"
      :id="id"
      type="radio"
      class="sr-only"
      :aria-labelledby="`${id}-label`"
      :required="required"
      :name="name"
      :disabled="disabled"
      @change="onChange"
    >
    <div
      :id="`${id}-label`"
      :class="[labelClass, {
        'text-gray-900': checked,
      }]"
    >
      <slot v-bind="{ checked }" />
    </div>
    <div
      class="border-transparent absolute -inset-px rounded-lg border-2 pointer-events-none"
      :class="{
        'border-primary': checked,
      }"
      aria-hidden="true"
    />
  </label>
</template>

<script>
import objectid from '../../../utils/objectid';

export default {
  model: {
    prop: 'value',
    event: 'change',
  },

  props: {
    id: {
      type: String,
      default: () => objectid('selectbox'),
    },
    value: {
      type: [String, Number],
      default: null,
    },
    nativeValue: {
      type: [String, Number],
      default: null,
    },
    labelClass: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    valueModel: null,
  }),

  computed: {
    checked() {
      return this.nativeValue === this.valueModel;
    },
  },

  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        this.valueModel = newVal;
      },
    },
  },

  methods: {
    onChange() {
      this.$emit('change', this.nativeValue);
    },
  },
};
</script>
