import { AxiosPromise } from 'axios';
import buildPaginationQuery from '../utils/buildPaginationQuery';
import type { TApiPaginationQuery, TApiPaginationResult } from '@contimo/types/src/Api';
import client from './client';

export type IShopCertificate = {
  id: number;
  shopId: number;
  file: string;
  title: string;
  badge: string | null;
};

export type IShopCertificateCreateBody = {
  shopId: number;
  file: File;
  title: string;
  badge?: File | null;
};

export type IShopCertificateUpdateBody = {
  file?: File | null;
  title?: string | null;
  badge?: File | null;
};

const buildUrl = (path?: string | number) => {
  return path ? `/shop-certificates/${path}` : '/shop-certificates';
};

export default {
  index: (
    pagination: TApiPaginationQuery = {},
  ): AxiosPromise<TApiPaginationResult<IShopCertificate[]>> => {
    return client.get(`${buildUrl()}${buildPaginationQuery(pagination)}`);
  },
  update: (id: number, body: IShopCertificateUpdateBody): AxiosPromise<IShopCertificate> => {
    const formData = new FormData();
    if (body.file) formData.append('file', body.file);
    if (body.badge) formData.append('badge', body.badge);
    if (body.title) formData.append('title', body.title);

    return client.patch(buildUrl(id), formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });
  },
  store: (body: IShopCertificateCreateBody): AxiosPromise<IShopCertificate> => {
    const formData = new FormData();
    formData.append('shopId', body.shopId.toString());
    formData.append('file', body.file);
    formData.append('title', body.title);
    if (body.badge) {
      formData.append('badge', body.badge);
    }

    return client.post(buildUrl(), formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });
  },
  destroy: (id: number): AxiosPromise => client.delete(buildUrl(id)),
};
