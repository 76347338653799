import type { Module } from 'vuex';
import type {
  TProduct,
  TProductCreateBody,
  TProductUpdateBody,
} from '@contimo/api/src/api/products';
import type { TPaginationMeta } from '@contimo/types/src/Api';
import { products, sellingPrices } from '@contimo/api/src/api';
import {
  TSellingPriceCreateBody,
  TSellingPriceUpdateBody,
} from '@contimo/api/src/api/sellingPrices';
import { toDecimalPrice, toIntegerPrice } from '@contimo/api/src/utils/integerPriceParser';
import { removeFromArrayByKey, setKeyValueForItemByKey, updateOrPushInArray } from '@/utils/store';
import type { TRootStore } from '@/store';
import {
  CREATE_PRODUCT_CONTAINER,
  CREATE_SELLING_PRICE,
  DELETE_PRODUCT,
  GET_PRODUCT,
  GET_PRODUCTS,
  UPDATE_PRODUCT_CONTAINER,
  UPDATE_SELLING_PRICE,
} from '../actionTypes';
import { REMOVE_PRODUCT, SET_PRODUCT, SET_PRODUCTS } from '../mutationsTypes';
import { CONTAINER_PRODUCTS, DELETED_CONTAINER_PRODUCTS } from '../gettersTypes';

export interface IProductsStoreState {
  loading: boolean;
  singleLoading: boolean;
  submitLoading: boolean;
  products: TProduct[];
  pagination: TPaginationMeta | null;
}

type SellingPricePricesConversion = {
  basePrice: number;
  containerSellingPrice: {
    durationPriceAfter: number;
    durationPricePerDay: number;
    pricePerDeliveryKm?: number;
  };
};

function parseSellingPricePrices(sellingPrice: SellingPricePricesConversion) {
  sellingPrice.basePrice = toDecimalPrice(sellingPrice.basePrice);
  sellingPrice.containerSellingPrice.durationPricePerDay = toDecimalPrice(
    sellingPrice.containerSellingPrice.durationPricePerDay,
  );
  if (sellingPrice.containerSellingPrice.pricePerDeliveryKm) {
    sellingPrice.containerSellingPrice.pricePerDeliveryKm = toDecimalPrice(
      sellingPrice.containerSellingPrice.pricePerDeliveryKm,
    );
  }
  return sellingPrices;
}

function parseProductCurrencyValues(product: TProduct) {
  product.sellingPrices?.forEach((sellingPrice) => {
    parseSellingPricePrices(sellingPrice);
  });
  if (product.currentSellingPrice) {
    parseSellingPricePrices(product.currentSellingPrice);
  }
}

function convertSellingPricePrices(sellingPrice: SellingPricePricesConversion) {
  sellingPrice.basePrice = toIntegerPrice(sellingPrice.basePrice);
  sellingPrice.containerSellingPrice.durationPricePerDay = toIntegerPrice(
    sellingPrice.containerSellingPrice.durationPricePerDay,
  );
  if (sellingPrice.containerSellingPrice.pricePerDeliveryKm) {
    sellingPrice.containerSellingPrice.pricePerDeliveryKm = toIntegerPrice(
      sellingPrice.containerSellingPrice.pricePerDeliveryKm,
    );
  }
}

type TProductsStore = Module<IProductsStoreState, TRootStore>;

const productsStore: TProductsStore = {
  state: () => ({
    loading: false,
    singleLoading: false,
    submitLoading: false,
    products: [],
    pagination: null,
  }),

  getters: {
    [CONTAINER_PRODUCTS]: (state) =>
      state.products.filter((p) => p.type === 'container' && !p.deleted),
    [DELETED_CONTAINER_PRODUCTS]: (state) => state.products.filter((p) => p.type === 'container'),
  },

  mutations: {
    [SET_PRODUCT](state, product: TProduct) {
      updateOrPushInArray(state.products, product);
    },
    [SET_PRODUCTS](state, products: TProduct[]) {
      products.forEach((product) => {
        updateOrPushInArray(state.products, product);
      });
    },
    [REMOVE_PRODUCT](state, id: number) {
      removeFromArrayByKey(state.products, id);
    },
    setProductsLoading(state, loading: boolean) {
      state.loading = loading;
    },
    setSubmitLoading(state, loading: boolean) {
      state.submitLoading = loading;
    },
    setProductsSingleLoading(state, loading: boolean) {
      state.singleLoading = loading;
    },
    setProductsPagination(state, meta: TPaginationMeta | null) {
      state.pagination = meta;
    },
    setDeleteLoading(state, id: number) {
      setKeyValueForItemByKey(state.products, { $deleteLoading: true }, id);
    },
  },

  actions: {
    async [GET_PRODUCT]({ commit }, id: number) {
      commit('setProductsSingleLoading', true);
      try {
        const { data } = await products.show(id);
        parseProductCurrencyValues(data);
        commit(SET_PRODUCT, data);

        return data;
      } finally {
        commit('setProductsSingleLoading', false);
      }
    },
    async [GET_PRODUCTS](
      { state, commit },
      { page, limit, showDeleted }: { page?: number; limit?: number; showDeleted?: boolean } = {},
    ) {
      if (!page || state.products.length === 0) commit('setProductsLoading', true);
      try {
        const { data } = await products.index(
          { page: page || 1, limit: limit || 20 },
          showDeleted || false,
        );
        data.data.forEach((product) => parseProductCurrencyValues(product));
        commit(SET_PRODUCTS, data.data);
        commit('setProductsPagination', data.meta, {
          root: false,
        });
        return data;
      } finally {
        commit('setProductsLoading', false);
      }
    },
    async [CREATE_PRODUCT_CONTAINER]({ state, commit }, body: TProductCreateBody) {
      commit('setSubmitLoading', true);
      try {
        convertSellingPricePrices(body.sellingPrice);
        const { data } = await products.store(body);
        parseProductCurrencyValues(data);
        commit(SET_PRODUCT, data);
        if (state.pagination) {
          state.pagination.total = state.products.length;
        }
        return data;
      } finally {
        commit('setSubmitLoading', false);
      }
    },
    async [UPDATE_PRODUCT_CONTAINER](
      { commit },
      { id, body }: { id: number; body: TProductUpdateBody },
    ) {
      commit('setSubmitLoading', true);
      try {
        const { data } = await products.update(id, body);
        parseProductCurrencyValues(data);
        commit(SET_PRODUCT, data);
        return data;
      } finally {
        commit('setSubmitLoading', false);
      }
    },
    async [DELETE_PRODUCT]({ commit }, ctx: { id: number }) {
      commit('setDeleteLoading', ctx.id);
      const { data } = await products.destroy(ctx.id);
      commit(REMOVE_PRODUCT, ctx.id);
      return data;
    },
    async [CREATE_SELLING_PRICE]({ commit, dispatch }, body: TSellingPriceCreateBody) {
      commit('setSubmitLoading', true);
      try {
        convertSellingPricePrices(body);
        const { data } = await sellingPrices.store(body);
        const { product } = data;
        await dispatch(GET_PRODUCT, product.id);
        return data;
      } finally {
        commit('setSubmitLoading', false);
      }
    },
    async [UPDATE_SELLING_PRICE](
      { commit, dispatch },
      { id, body }: { id: number; body: TSellingPriceUpdateBody },
    ) {
      commit('setSubmitLoading', true);
      try {
        convertSellingPricePrices(body);
        const { data } = await sellingPrices.update(id, body);
        const { product } = data;
        await dispatch(GET_PRODUCT, product.id);

        return data;
      } finally {
        commit('setSubmitLoading', false);
      }
    },
  },
};

export default productsStore;
