<template>
  <router-link
    :to="to"
    v-slot="{ href, navigate, isExactActive, isActive }"
    custom
  >
    <a
      :href="href"
      class="group inline-flex text-sm mr-6 lg:mr-8 font-medium py-3 border-b-2
      border-transparent transition duration-150 hover:text-primary relative items-center -mb-px
      no-tap-highlight"
      :class="{
        'text-primary border-primary': isExactActive || (isActive && withChildren),
        'text-gray-600 border-transparent': !isExactActive || (!isActive && withChildren),
      }"
      @click="navigate"
    >
      <span class="relative z-10"><slot /></span>
      <span
        class="absolute w-[calc(100%+16px)] h-7 left-0 -ml-2 bg-primary z-0 bg-opacity-0 rounded
        group-hover:bg-opacity-10 transition duration-150"
      />
    </a>
  </router-link>
</template>

<script>
export default {
  props: {
    to: {
      type: String,
      required: true,
    },
    withChildren: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
