<template>
  <fieldset class="mb-5">
    <legend class="text-lg font-medium text-gray-900">
      <slot />
    </legend>
    <div class="mt-4">
      <div
        v-for="(item) in items"
        :key="`checkbox-list-item-${item.value}`"
        class="relative flex items-start pb-1"
      >
        <Checkbox v-model="item.checked" :required="error">
          <template v-if="item.translation">{{ item.translation }}</template>
          <template v-else>{{ item.value }}</template>
        </Checkbox>
      </div>
    </div>
    <div v-if="error" class="text-xs text-red-600 mt-2">
      {{ $t('checkAtLeastOneCheckbox') }}
    </div>
  </fieldset>
</template>


<script>
import Checkbox from '../Checkbox/index.vue';

export default {
  components: {
    Checkbox,
  },

  props: {
    value: {
      type: Array,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      error: false,
    };
  },

  computed: {
    items: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },

  watch: {
    value: {
      handler() {
        if (this.required && !this.items.some((item) => item.checked === true)) {
          this.error = true;
        } else {
          this.error = false;
        }
      },
      deep: true,
    },
  },
};
</script>
