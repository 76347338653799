import type { AxiosPromise } from 'axios';
import type Stripe from 'stripe';
import client from '../client';

const buildUrl = (id: number, path?: string | number) =>
  path ? `/merchants/${id}/payments/${path}` : `/merchants/${id}/payments`;

export default {
  createStripeAccount: (id: number, businessType: 'company' | 'individual') => {
    return client.post(buildUrl(id, 'account'), { businessType });
  },
  getStripeAccount: (id: number): AxiosPromise<Stripe.Account> =>
    client.get(buildUrl(id, 'account')),
  getOnboardingLink: (
    id: number,
    type: 'account_onboarding' | 'account_update' = 'account_onboarding',
    refreshUrl?: string,
    returnUrl?: string,
  ): AxiosPromise<Stripe.AccountLink> => {
    let query = `type=${type}`;
    if (refreshUrl) query += `&refreshUrl=${refreshUrl}`;
    if (returnUrl) query += `&returnUrl=${returnUrl}`;
    return client.get(`${buildUrl(id, 'account/link')}?${query}`);
  },
  createPayoutAccount: (
    id: number,
    body: {
      accountHolderName: string;
      accountHolderType: 'company' | 'individual' | string;
      iban: string;
      currency: 'eur' | string;
      country: 'DE' | string;
    },
  ): AxiosPromise<Stripe.BankAccount> => {
    return client.post(buildUrl(id, 'payout-account'), body);
  },
};
