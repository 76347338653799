export default (container: any, $i18n: any) => {
  let openingTypeString = `${$i18n.t('with')} `;
  let openingTypesCount = 0;

  const addToString = (key: string) => {
    if (container[key]) {
      openingTypesCount += 1;
      if (openingTypesCount > 1) openingTypeString = `${openingTypeString} ${$i18n.t('and')}`;
      openingTypeString = `${openingTypeString} ${$i18n.t(`products.container.${key}`)}`;
    }
  };
  addToString('cover');
  addToString('flap');
  addToString('doors');

  return openingTypeString;
};
