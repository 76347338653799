<template>
  <ODatepicker
    ref="datepicker"
    v-model="date"
    v-bind="{ ...$attrs }"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: Date,
      default: false,
    },
  },

  computed: {
    date: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },

  methods: {
    toggle() {
      this.$refs.datepicker.toggle();
    },
  },
};
</script>
