var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('router-link',{attrs:{"to":_vm.to,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isExactActive = ref.isExactActive;
var href = ref.href;
var navigate = ref.navigate;
return [_c('a',{staticClass:"flex w-full px-3 py-2 rounded-lg font-medium transition duration-150 bg-primary",class:{
      'bg-opacity-10 text-primary': isExactActive,
      'text-gray-700 bg-opacity-0 hover:bg-opacity-10 hover:text-primary': !isExactActive,
    },attrs:{"href":href},on:{"click":navigate}},[(_vm.icon)?_c('CIcon',{staticClass:"mr-2",attrs:{"name":_vm.icon,"size":"16"}}):_vm._e(),_c('CTypo',{attrs:{"el":"div","tstyle":"body"}},[_vm._t("default")],2)],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }