<template>
  <div />
</template>

<script>
import turfCircle from '@turf/circle';
import objectid from '../../../utils/objectid';

// const polygonEvents = {
//   drag: 'drag',
//   dragstart: 'dragstart',
//   dragend: 'dragend',
// };

const polygonDOMEvents = {
  click: 'click',
  mouseenter: 'mouseenter',
  mouseleave: 'mouseleave',
};

export default {
  props: {
    id: {
      type: String,
      default: () => objectid('mapcircle'),
    },
    center: {
      // [lat, lng]
      type: Array,
      required: true,
    },
    units: {
      type: String,
      default: 'kilometers',
    },
    radius: {
      type: Number,
      default: 10,
    },
    steps: {
      type: Number,
      default: 20,
    },
    options: {
      type: Object,
      default: undefined,
    },
  },

  data: () => ({
    turfCircle: null,
    circle: null,
  }),

  computed: {
    map() {
      return this.$parent.map;
    },
    mapbox() {
      return this.$parent.mapbox;
    },
  },

  watch: {
    units() {
      this.updateCircle();
    },
    radius() {
      this.updateCircle();
    },
    steps() {
      this.updateCircle();
    },
  },

  mounted() {
    this.initCircle();
    const polygonOptions = this.options || {};
    if (this.$slots.default) {
      polygonOptions.element = this.$slots.default[0].elm;
    }
    this.addCircle();
  },

  beforeDestroy() {
    this.remove();
  },

  methods: {
    initCircle() {
      const { center, radius, units, steps } = this;
      const options = { steps, units };
      this.turfCircle = turfCircle([center[1], center[0]], radius, options);
      this.$emit('turf-circle-created', this.turfCircle);
    },
    addCircle() {
      this.map.addSource(this.id, {
        type: 'geojson',
        data: this.turfCircle,
      });
      this.map.addLayer({
        id: this.id,
        type: 'fill',
        source: this.id,
        ...(this.options?.layer || {}),
      });
    },
    updateCircle() {
      this.remove(false);
      this.initCircle();
      this.addCircle();
      this.$emit('udpated');
    },
    emitMapEvent(event) {
      this.$parent.$emitMapEvent(event, { circle: this.circle });
    },

    emitSelfEvent(event, nativeEvent) {
      this.$emit(event, { circle: this.circle, nativeEvent });
    },

    bindSelfEvents(events, circle) {
      events.forEach((event) => {
        circle.on(event, (e) => {
          this.emitSelfEvent(event, {
            circle: this.circle,
            event: e,
          });
        });
      });
    },

    bindPolygonDOMEvents() {
      Object.keys(this.$listeners).forEach((key) => {
        if (Object.values(polygonDOMEvents).includes(key)) {
          this.circle._element.addEventListener(key, (event) => {
            this.emitMapEvent(event);
            this.emitSelfEvent(event.type, event);
          });
        }
      });
    },

    remove(event = true) {
      this.map.removeLayer(this.id);
      this.map.removeSource(this.id);
      if (event) this.$emit('removed');
    },
  },
};
</script>
