import { AxiosPromise } from 'axios';
import client from './client';

export type TFeedbackCreateBody = {
  type: 'feedback' | 'improvement' | 'error';
  content: string;
};

export default {
  store: (body: TFeedbackCreateBody): AxiosPromise => {
    return client.post('/feedback', body);
  },
  getApiVersion: (): Promise<string> => {
    return new Promise((res) => {
      client.get('/ping?log=false').then(({ data }) => {
        res(data.version);
      });
    });
  },
};
