import type { TApiPaginationQuery, TApiPaginationResult } from '@contimo/types/src/Api';
import buildPaginationQuery from '../utils/buildPaginationQuery';
import { AxiosPromise } from 'axios';
import client from './client';

export type TPostalCode = {
  id: string;
  postalCode: string;
  countryShort: string;
  city: string;
};

const buildUrl = (path?: string) => (path ? `/postal-codes/${path}` : '/postal-codes');

export default {
  index: (pagination: TApiPaginationQuery): AxiosPromise<TApiPaginationResult<TPostalCode[]>> => {
    return client.get(`${buildUrl()}${buildPaginationQuery(pagination)}`);
  },
  show: (id: string): AxiosPromise<TPostalCode | null> => client.get(buildUrl(id)),
  search: (
    search: string,
    pagination: TApiPaginationQuery,
  ): AxiosPromise<TApiPaginationResult<TPostalCode[]>> => {
    return client.get(`${buildUrl()}?search=${search}&${buildPaginationQuery(pagination, '&')}`);
  },
};
