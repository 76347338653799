<template>
  <OField
    :label="label"
  >
    <OSlider
      v-bind="{ ...$attrs }"
      v-model="modelValue"
      :aria-label="label"
      :tooltip="indicator ? false : tooltip"
      :indicator="indicator"
      :root-class="label ? 'mt-3' : ''"
    />
  </OField>
</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    value: {
      type: [Number, Array],
      default: 0,
    },
    tooltip: {
      type: Boolean,
      default: true,
    },
    indicator: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
  },

  data: () => ({
    modelValue: 0,
  }),

  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        this.modelValue = newVal;
      },
    },
    modelValue(newVal) {
      this.$emit('input', newVal);
    },
  },
};
</script>
