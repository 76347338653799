<template>
  <div>
    <div ref="relative" class="relative w-full h-px -mt-px" aria-hidden />
    <div
      v-if="!onlyInline"
      ref="absolute"
      class="absolute sr-only w-screen"
      aria-hidden
    />
  </div>
</template>

<script>
export default {
  props: {
    onlyInline: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {
    this.relativeObserver = new ResizeObserver(this.getRelativeSize);
    this.relativeObserver.observe(this.$refs.relative);
    if (!this.onlyInline) {
      this.absoluteObserver = new ResizeObserver(this.getScreenSize);
      this.absoluteObserver.observe(this.$refs.absolute);
    }
  },

  beforeDestroy() {
    this.relativeObserver.disconnect();
    if (!this.onlyInline) {
      this.absoluteObserver.disconnect();
    }
  },

  methods: {
    getRelativeSize() {
      this.$emit('resize-inline', this.$refs.relative.offsetWidth);
    },
    getScreenSize() {
      this.$emit('resize-screen', this.$refs.absolute.offsetWidth);
    },
  },
};
</script>
