export const SET_NEXT_ROUTE = 'setNextRoute';
export const SET_TOKEN = 'setToken';
export const SET_AUTH_LOADING = 'setAuthLoading';
export const SET_AUTH_READY = 'setAuthReady';
export const SET_LOGIN_LOADING = 'setLoginLoading';

export const SET_USER = 'setUser';

export const SET_SIGNUP_STEP = 'setSignupStep';
export const SET_POSTAL_CODE_RESULTS = 'setPostalCodeResults';
export const RESET_POSTAL_CODE_SEARCH = 'resetPostalCodeSearch';

export const SET_COUNTRIES = 'setCountries';
export const SET_APP_LOADING = 'setAppLoading';
export const SET_WINDOW_SIZE = 'setWindowSize';
export const SET_WINDOW_TITLE = 'setWindowTitle';
export const SET_WINDOW_SCROLL = 'setWindowScroll';
export const SET_SUCCESS_NOTIFICATION = 'setSuccessNotification';
export const SET_ERROR_NOTIFICATION = 'setErrorNotification';
export const SET_VERSION = 'setVersion';

export const SET_MERCHANT = 'setMerchant';

export const SET_DELIVERY_AREAS = 'setDeliveryAreas';
export const SET_DELIVERY_AREA = 'setDeliveryArea';
export const REMOVE_DELIVERY_AREA = 'removeDeliveryArea';
export const SET_DELIVERY_AREA_LOADING = 'setDeliveryAreaLoading';

export const SET_PLATFORM_WASTES = 'setPlatformWastes';
export const SET_PLATFORM_WASTE = 'setPlatformWaste';
export const SET_MERCHANT_WASTES = 'setMerchantWastes';
export const SET_MERCHANT_WASTE = 'setMerchantWaste';
export const REMOVE_MERCHANT_WASTE = 'removeMerchantWaste';

export const SET_PRODUCTS = 'setProducts';
export const SET_PRODUCT = 'setProduct';
export const REMOVE_PRODUCT = 'removeProduct';

export const SET_SHOP = 'setShop';
export const SET_SHOP_ACTIVATION_LOADING = 'setShopActivationLoading';
export const SET_SHOP_LOADING = 'setShopLoading';
export const SET_SHOP_SUBMIT_LOADING = 'setShopSubmitLoading';

export const SET_PAGE = 'setPage';
export const SET_PAGES = 'setPages';
export const SET_PAGE_LOADING = 'setPageLoading';
export const SET_PAGES_LOADING = 'setPagesLoading';
export const SET_PAGE_SUBMIT_LOADING = 'setPageSubmitLoading';

export const SET_MANAGER_INVITE = 'setManagerInvite';
export const SET_MANAGER_INVITES = 'setManagerInvites';
export const REMOVE_MANAGER_INVITE = 'removeManagerInvite';
export const SET_MANAGER_INVITE_LOADING = 'setManagerInvitesLoading';
export const SET_MANAGER_INVITES_LOADING = 'setManagerInvitesLoading';
export const SET_MANAGER_INVITES_SUBMIT_LOADING = 'setManagerInvitesSubmitLoading';
export const SET_MANAGER_INVITES_PAGINATION = 'setManagerInvitesPagination';

export const SET_SINGLE_USER = 'setUser';
export const SET_USERS = 'setUsers';
export const SET_USER_LOADING = 'setUserLoading';
export const SET_USERS_LOADING = 'setUsersLoading';
export const SET_USERS_PAGINATION = 'setUsersPagination';

export const SET_SINGLE_MANAGER = 'setManager';
export const SET_MANAGERS = 'setManagers';
export const SET_MANAGER_LOADING = 'setManagerLoading';
export const SET_MANAGERS_LOADING = 'setManagersLoading';
export const SET_MANAGERS_PAGINATION = 'setManagersPagination';
export const OPEN_MANAGER = 'openManager';
export const SET_MANAGER_UPDATE_LOADING = 'setManagerUpdateLoading';

export const SET_ORDERS = 'setOrders';
export const SET_ORDER = 'setOrder';
export const SET_ORDERS_LOADING = 'setOrdersLoading';

export const SET_SHOP_CERTIFICATES = 'setShopCertificates';
export const SET_SHOP_CERTIFICATE = 'setShopCertificate';
export const OPEN_SHOP_CERTIFICATE = 'openShopCertificate';
export const REMOVE_SHOP_CERTIFICATE = 'removeShopCertificate';

export const SET_PLATFORM_INVOICES = 'setPlatformInvoices';
