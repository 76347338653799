import { AxiosPromise } from 'axios';
import buildPaginationQuery from '../utils/buildPaginationQuery';
import type { TApiPaginationQuery, TApiPaginationResult } from '@contimo/types/src/Api';
import type { IProduct } from '@contimo/types/src/ApiModels';
import client from './client';

export type TProductContainer = {
  id: number;
  size: number;
  normalizedSize: number;
  type: 'rolloff' | 'dropoff' | 'bigbag';
  flap: boolean;
  cover: boolean;
  doors: boolean;
  length: number;
  width: number;
  vehiclePassageWidth: number;
  vehicleHeadroom: number;
  vehicleManeuveringAreaLength: number;
  vehicleManeuveringAreaWidth: number;
  vehicleLength: number;
  vehicleOverallLength: number;
  vehicleTotalWeight: number;
};

export type TProduct = IProduct;

export type TProductCreateBody = {
  container: {
    size: number;
    type: string;
    flap: boolean;
    cover: boolean;
    doors: boolean;
    length?: number | null;
    width?: number | null;
    vehiclePassageWidth?: number | null;
    vehicleHeadroom?: number | null;
    vehicleManeuveringAreaLength?: number | null;
    vehicleManeuveringAreaWidth?: number | null;
    vehicleLength?: number | null;
    vehicleOverallLength?: number | null;
    vehicleTotalWeight?: number | null;
  };
  number: number;
  sellingPrice: {
    basePrice: number;
    name?: string;
    containerSellingPrice: {
      durationPriceAfter: number;
      durationPricePerDay: number;
      pricePerDeliveryKm?: number;
    };
  };
};

export type TProductUpdateBody = {
  container?: {
    size?: number;
    type?: string;
    flap?: boolean;
    cover?: boolean;
    doors?: boolean;
    length?: number | null;
    width?: number | null;
    vehiclePassageWidth?: number | null;
    vehicleHeadroom?: number | null;
    vehicleManeuveringAreaLength?: number | null;
    vehicleManeuveringAreaWidth?: number | null;
    vehicleLength?: number | null;
    vehicleOverallLength?: number | null;
    vehicleTotalWeight?: number | null;
  };
  number?: number;
};

const buildUrl = (path?: string | number) => {
  return path ? `/products/${path}` : '/products';
};

export default {
  index: (
    pagination: TApiPaginationQuery = {},
    showDeleted = false,
  ): AxiosPromise<TApiPaginationResult<TProduct[]>> => {
    return client.get(
      `${buildUrl()}${buildPaginationQuery(pagination)}&showDeleted=${showDeleted}`,
    );
  },
  show: (id: number): AxiosPromise<TProduct> => client.get(buildUrl(id)),
  update: (id: number, updates: TProductUpdateBody): AxiosPromise<TProduct> => {
    return client.patch(buildUrl(id), updates);
  },
  store: (body: TProductCreateBody): AxiosPromise<TProduct> => {
    return client.post(buildUrl(), body);
  },
  destroy: (id: number): AxiosPromise => {
    return client.delete(buildUrl(id));
  },
};
